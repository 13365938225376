<template>
  <div class="main">
    <div class="headline">
      <h2>
        <b>{{ $t("profile_h2_profile_information") }}</b>
      </h2>
      <p>{{ $t("profile_headline_p_welcome") }}</p>
    </div>
    <div class="user-info">
      <div class="image-div">
        <img src="@/assets/icons/profile_pic.png" />
      </div>
      <div class="main-user-info">
        <h4 class="user-name-surn">
          <strong>{{
            this.$store.state.userData.first_name +
            " " +
            this.$store.state.userData.last_name
          }}</strong>
        </h4>
        <p>
          {{ $t("profile_email_address") }}
          {{ this.$store.state.userData.email }}
        </p>
        <p>
          {{ $t("profile_username") }} {{ this.$store.state.userData.username }}
        </p>
        <a :href="`https://auth2.dev.genelec.com/auth/realms/${realm}/account/#/personal-info`" target="_blank"><button
            class="btn btn-def">{{ $t("profile_button_edit_login_info") }}</button></a>

      </div>
    </div>
    <div class="user-info-accordion">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="custom-accordion-button collapse" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne"
              @click="toggleUserInformation" ref="collapseButton">
              <h4 class="text-body">
                {{ $t("profile_accordion_user_information") }}
              </h4>
              <font-awesome-icon class="chevron-icon-center" :icon="['fas', 'chevron-down']" :style="{
                transform: `rotate(${userInformationIconRotation}deg)`,
                transition: 'transform 0.3s ease-in-out',
                height: '15px',
              }" />
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne">
            <div class="accordion-body">
              <div class="accordion-body-item">
                <p>{{ $t("profile_p_country") }}</p>
                <div class="custom-select-wrapper">
                  <select class="custom-select" v-model="selectedCountry">
                    <option v-for="country in countries" :value="country.Key" :key="country.Key">
                      {{ country.en }}
                    </option>
                  </select>
                  <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                </div>
              </div>
              <div class="accordion-body-item">
                <p>{{ $t("profile_p_city") }}</p>
                <div class="custom-select-wrapper">
                  <input type="text" class="custom-select" style="border: 1px solid" v-model="selectedCity" />
                </div>
              </div>
              <div class="accordion-body-item">
                <p>{{ $t("profile_p_working_environment") }}</p>
                <div class="custom-select-wrapper">
                  <select class="custom-select" v-model="selectedWorkEnvironment">
                    <option value="broadcast-obvan-key" selected>
                      {{ $t("workEnvironment_broadcast-obvan-key") }}
                    </option>
                    <option value="post-production-key">
                      {{ $t("workEnvironment_post-production-key") }}
                    </option>
                    <option value="game-audio-key">
                      {{ $t("workEnvironment_game-audio-key") }}
                    </option>
                    <option value="audiovisual-art-key">
                      {{ $t("workEnvironment_audiovisual-art-key") }}
                    </option>
                    <option value="music-studio-key">
                      {{ $t("workEnvironment_music-studio-key") }}
                    </option>
                    <option value="mastering-key">
                      {{ $t("workEnvironment_mastering-key") }}
                    </option>
                    <option value="home-studio-key">
                      {{ $t("workEnvironment_home-studio-key") }}
                    </option>
                    <option value="home-listening-key">
                      {{ $t("workEnvironment_home-listening-key") }}
                    </option>
                    <option value="high-end-listeniing-key">
                      {{ $t("workEnvironment_high-end-listeniing-key") }}
                    </option>
                    <option value="home-theaters-key">
                      {{ $t("workEnvironment_home-theaters-key") }}
                    </option>
                    <option value="tv-gaming-key">
                      {{ $t("workEnvironment_tv-gaming-key") }}
                    </option>
                    <option value="av-design-consultancy-key">
                      {{ $t("workEnvironment_av-design-consultancy-key") }}
                    </option>
                    <option value="av-integration-installation-key">
                      {{
                        $t("workEnvironment_av-integration-installation-key")
                      }}
                    </option>
                    <option value="av-system-user-key">
                      {{ $t("workEnvironment_av-system-user-key") }}
                    </option>
                    <option value="audio-music-education-key">
                      {{ $t("workEnvironment_audio-music-education-key") }}
                    </option>
                    <option value="research-key">
                      {{ $t("workEnvironment_research-key") }}
                    </option>
                    <option value="audio-dealer-key">
                      {{ $t("workEnvironment_audio-dealer-key") }}
                    </option>
                    <option value="audio-distributor-key">
                      {{ $t("workEnvironment_audio-distributor-key") }}
                    </option>
                  </select>
                  <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                </div>
              </div>
              <div class="accordion-body-item">
                <p>{{ $t("profile_p_relationship_with_genelec") }}</p>
                <div class="custom-select-wrapper">
                  <select class="custom-select" v-model="selectedRelationship">
                    <option value="genelec-owner-key" selected>
                      {{ $t("relationship_genelec-owner-key") }}
                    </option>
                    <option value="genelec-user-key">
                      {{ $t("relationship_genelec-user-key") }}
                    </option>
                    <option value="genelec-dreamer-key">
                      {{ $t("relationship_genelec-dreamer-key") }}
                    </option>
                  </select>
                  <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                </div>
              </div>
              <div class="save-cancel-buttons-div">
                <button class="btn-def delete-account-button" @click="deleteAccountModal">
                  {{ $t("profile_button_delete_account") }}</button>
                <button class="btn-def save-info-button" @click="saveInformation">
                  {{ $t("profile_button_save_information") }}
                </button>
                <!-- <button class="btn-def cancel-info-button">
                  {{ $t("profile_button_cancel") }}
                </button> -->
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button class="custom-accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"
              @click="toggleMarketingPreferences" ref="collapseButton2">
              <h4 class="text-body">
                {{ $t("profile_accordion_marketing_preferences") }}
              </h4>
              <font-awesome-icon class="chevron-icon-center" :icon="['fas', 'chevron-down']" :style="{
                transform: `rotate(${marketingPreferencesIconRotation}deg)`,
                transition: 'transform 0.3s ease-in-out',
                height: '15px',
              }" />
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo">
            <div class="accordion-body">
              <div class="accordion-body-item" v-for="field in fields" :key="field.id">
                <div v-if="field.id !== 66" class="row-with-checkbox-input">
                  <input class="input-for-checkbox" type="checkbox" v-model="field.value" />
                  <p>{{ field.name }}</p>
                </div>

                <p v-if="field.id === 66">{{ field.name }}</p>
                <div v-if="field.id === 66" class="custom-select-wrapper prefered-language">
                  <select class="custom-select" v-model="field.value">
                    <option value="ENGLISH">English</option>
                    <option value="FINNISH">Suomi</option>
                    <option value="SWEDISH">Svenska</option>
                    <option value="JAPAN">日本語</option>
                    <!-- <option value="日本語">日本語</option>
                    <option value="DEUTSCH">Deutsch</option>
                    <option value="PORTUGUESE">Portuguêse</option> -->
                    <!-- <option value="GERMAN">German</option>
        <option value="SPANISH">Spanish</option> -->
                  </select>
                  <font-awesome-icon class="custom-select-icon custom-select-wrapper-mob"
                    :icon="['fas', 'chevron-down']" />
                </div>
              </div>
              <div class="save-cancel-buttons-div-single">
                <button class="btn-def save-info-button" @click="saveFields">
                  {{ $t("profile_button_save_information") }}
                </button>
                <!-- <button class="btn-def cancel-info-button">
                  {{ $t("profile_button_cancel") }}
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="deletemodal" v-if="showDeleteModal" @click="closeDeleteModal">
    <div class="content" @click.stop>
      <div class="text-container">
        <h2>{{ $t("deleteAccountModal_h1") }}</h2>
        <p>{{ $t("deleteAccountModal_p1_beforeBR") }}<br>
          {{ $t("deleteAccountModal_p1_afterBR") }}
        </p>
        <p>{{ $t("deleteAccountModal_p2") }}</p>
        <p>{{ $t("deleteAccountModal_p3") }}</p>
      </div>
      <div class="username-div"><input placeholder="Username" v-model="userNameInput"></div>
      <div class="buttons-div">
        <button type="button" class="save-info-button" @click="confirmDelete"
          :disabled="$store.state.errorStatus || (this.userNameInput !== this.$store.state.userData.username)">
          {{ $t("button_delete") }}
        </button>
        <button type="button" class="cancel-info-button" @click="closeDeleteModal">
          {{ $t("button_cancel") }}
        </button>
      </div>
      <div class="modal-error-block" v-if="$store.state.errorStatus">
        <div class="error">
          <p>
            {{ $t("errorpage_text") }}
          </p>
          <div class="error-context">
            <p>{{ $store.state.errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import putUpdateUserInfo from "@/api/putUpdateUserInfo";
import { getFields, putFields } from "@/api/activeCampaign";
import deleteUserAccount from "@/api/accountManagement";

export default {
  name: "profilePage",
  data() {
    return {
      userInfo: true,
      isUserInformationExpanded: false,
      isMarketingPreferencesExpanded: false,
      countries: [],
      selectedCountry: "",
      selectedCity: "",
      selectedWorkEnvironment: "",
      selectedRelationship: "",
      realm: process.env.VUE_APP_REALM,
      activeCampaignCityFieldId: 7,
      activeCampaignCountryFieldId: 13,
      activeCampaignWorkEnvFieldId: 67,
      activeCampaignRelationshipFieldId: 68,
      countryNameCodeMap: {},
      selectedLanguage: "",
      showDeleteModal: false, //false
      userNameInput: "",

      fields: [
        {
          id: 9,
          name: this.$t("profile_data_fields_newsletterPermit"),
          text: "I wish to receive newsletters from Genelec.",
          value: false,
        },
        {
          id: 48,
          name: this.$t("profile_data_fields_segmentProMonitoring"),
          text: "Pro Monitoring",
          value: false,
        },
        {
          id: 49,
          name: this.$t("profile_data_fields_segmentHomeAudio"),
          text: "Home Audio",
          value: false,
        },
        {
          id: 50,
          name: this.$t("profile_data_fields_segmentAVInstallation"),
          text: "AV Installation",
          value: false,
        },
        {
          id: 66,
          name: this.$t("profile_data_fields_preferredLanguage"),
          value: this.selectedLanguage,
        },
      ],
    };
  },

  methods: {
    toggleUserInformation() {
      this.isUserInformationExpanded = !this.isUserInformationExpanded;
    },
    toggleMarketingPreferences() {
      this.isMarketingPreferencesExpanded =
        !this.isMarketingPreferencesExpanded;
    },
    fetchCountries() {
      axios
        .get(
          "https://cdn.contentful.com/spaces/4zjnzn055a4v/environments/master/entries/5AqAHkPDE86gnYyP20u1Vb?access_token=IohwVkm75OGdKdtyQewXdOkMrspUUrZ0OfDEpj_Xqas"
        )
        .then((response) => {
          this.countries = response.data.fields.countryNames.rows;
          this.countries.forEach((country) => {
            this.countryNameCodeMap[country.en.toUpperCase()] = country.Key;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveInformation() {
      const currentData = this.$store.state.userData;
      let updatedData = { ...currentData };
      // Only update the fields if they are not empty
      if (this.selectedCountry) updatedData.country_code = this.selectedCountry;
      if (this.selectedCity) updatedData.city = this.selectedCity;
      if (this.selectedWorkEnvironment) {
        updatedData.working_environment = this.selectedWorkEnvironment;
      }
      if (this.selectedRelationship) {
        updatedData.genelec_user_status = this.selectedRelationship;
      }

      try {
        const updatedUserData = await putUpdateUserInfo(updatedData);
        this.$store.dispatch("updateUserData", updatedUserData);

        // Save city and country to ActiveCampaign if they are updated
        const updatedFields = [];
        if (this.selectedCity) {
          updatedFields.push({
            fieldId: this.activeCampaignCityFieldId,
            fieldValue: this.selectedCity,
          });
        }
        if (this.selectedCountry) {
          const selectedCountryObject = this.countries.find(
            (country) => country.Key === this.selectedCountry
          );
          const selectedCountryName = selectedCountryObject
            ? selectedCountryObject.en.toUpperCase()
            : "";
          updatedFields.push({
            fieldId: this.activeCampaignCountryFieldId,
            fieldValue: selectedCountryName,
          });
        }
        if (this.selectedWorkEnvironment) {
          updatedFields.push({
            fieldId: this.activeCampaignWorkEnvFieldId,
            fieldValue: this.selectedWorkEnvironment,
          });
        }
        if (this.selectedRelationship) {
          updatedFields.push({
            fieldId: this.activeCampaignRelationshipFieldId,
            fieldValue: this.selectedRelationship,
          });
        }
        if (updatedFields.length > 0) {
          try {
            await putFields(updatedFields);
          } catch (error) {
            console.log(error);
          }
        }

        this.$refs.collapseButton.click();
      } catch (error) {
        console.error("Failed to update user information: ", error);
      }
    },
    async getNewData() {
      try {
        const response = await getFields();
        const fieldValues = response.fieldValues;

        fieldValues.forEach((fv) => {
          if (parseInt(fv.field, 10) === this.activeCampaignCityFieldId) {
            this.selectedCity = fv.value;
          } else if (
            parseInt(fv.field, 10) === this.activeCampaignCountryFieldId
          ) {
            this.selectedCountry = this.countryNameCodeMap[fv.value];
          } else {
            const foundField = this.fields.find(
              (field) => field.id === parseInt(fv.field, 10)
            );
            if (foundField) {
              if (foundField.id == "66") {
                this.selectedLanguage = fv.value.toUpperCase();
                this.$store.commit("setActiveLocale", fv.value);
                // console.log("Value from AC " + fv.value);
                // console.log(this.selectedLanguage);
              } else {
                foundField.value = fv.value === "||" + foundField.text + "||";
              }
            }
          }
        });
        const languageField = this.fields.find((field) => field.id === 66);
        if (languageField) {
          languageField.value = this.selectedLanguage;
        }
      } catch (error) {
        console.error("Failed to get fields: ", error);
      }
    },

    async saveFields() {
      const fieldsData = this.fields.map((field) => {
        if (field.id == "66") {
          this.$store.commit("setActiveLocale", this.selectedLanguage);
          return {
            fieldId: field.id,
            fieldValue: this.selectedLanguage.toUpperCase(),
          };
        } else {
          return {
            fieldId: field.id,
            fieldValue: field.value ? "||" + field.text + "||" : "",
          };
        }
      });

      try {
        await putFields(fieldsData);
        this.$refs.collapseButton2.click();
      } catch (error) {
        console.error("Failed to update fields: ", error);
      }
      this.fields = [
        {
          id: 9,
          name: this.$t("profile_data_fields_newsletterPermit"),
          text: "I wish to receive newsletters from Genelec.",
          value: false,
        },
        {
          id: 48,
          name: this.$t("profile_data_fields_segmentProMonitoring"),
          text: "Pro Monitoring",
          value: false,
        },
        {
          id: 49,
          name: this.$t("profile_data_fields_segmentHomeAudio"),
          text: "Home Audio",
          value: false,
        },
        {
          id: 50,
          name: this.$t("profile_data_fields_segmentAVInstallation"),
          text: "AV Installation",
          value: false,
        },
        {
          id: 66,
          name: this.$t("profile_data_fields_preferredLanguage"),
          value: this.selectedLanguage,
        },
      ];
      this.getNewData();
    },
    deleteAccountModal() {
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    confirmDelete() {
      deleteUserAccount(),
        location.reload();
    }
  },

  computed: {
    userInformationIconRotation() {
      return this.isUserInformationExpanded ? -90 : 0;
    },
    marketingPreferencesIconRotation() {
      return this.isMarketingPreferencesExpanded ? -90 : 0;
    },
  },

  async created() {
    await this.fetchCountries();
    this.getNewData();
    this.selectedCountry = this.$store.state.userData.country_code;
    this.selectedCity = this.$store.state.userData.city;
    this.selectedWorkEnvironment =
      this.$store.state.userData.working_environment;
    this.selectedRelationship = this.$store.state.userData.genelec_user_status;
  },
  watch: {
    fields: {
      handler(fields) {
        const field = fields.find((field) => field.id === 66);
        if (field) {
          this.selectedLanguage = field.value;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.deletemodal {
  .content {
    margin: 18% auto;


    .username-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      margin-bottom: 37px;

      label {
        margin-bottom: 10px;
        margin-left: 13px;
        // align-self: flex-start;
      }

      input {
        width: 100%;
        padding: 10px;
        padding-left: 32px;
        border: 1px solid var(--Grey);
        border-radius: 30px;
      }
    }

    .buttons-div {
      justify-content: space-between;
      margin: 0;

      .save-info-button,
      .cancel-info-button {
        margin: 0;
      }
    }
  }
}

.main {
  padding: 58px 108px 58px 72px;
  background-color: var(--bg-grey);
  height: calc(100vh - var(--UpperMenuHeight));
  overflow-y: scroll;
  overflow-x: hidden;
}

.headline {
  h2 {
    border-left: 6px solid var(--Green);
    font-weight: 700;
    padding: 15px;
    margin-bottom: 25px;
  }

  p {
    max-width: 800px;
  }
}

.user-info {
  display: flex;
  margin-top: 53px;
  margin-bottom: 43px;

  .image-div {
    width: 138px;
    height: 138px;
    margin-right: 68px;

    img {
      //height: auto;
      width: 100%;
      border-radius: 69px;
      background-color: white;
    }
  }

  .main-user-info {
    .btn-def {
      border: 2px solid var(--Green);
      border-radius: 71px;
      color: var(--Green);
      width: 100%;
      font-weight: 700;
      //margin-bottom: 20px;
    }

    .dell-acc {
      border-color: red;
      color: red;
      margin-bottom: 0px;
    }
  }
}

.save-info-button {
  margin-right: 0px;
  margin-top: 0px;
}

.save-info-button:disabled {
  opacity: 0.5;
}

.user-info-accordion {
  border-top: 1px solid var(--Grey);

  .accordion-item {
    border-bottom: 1px solid var(--Grey);

    .accordion-header {
      button {
        display: flex;
        align-items: center;
      }

      h4 {
        margin: 0px;
        margin-right: 25px;
      }
    }
  }

  .custom-accordion-button {
    width: 100%;
    border: 0px;
    background-color: var(--Pale-grey);
    text-align: left;
    padding: 15px 40px;
    //border-bottom: 1px solid var(--Grey);

    p {
      width: 40%;
      margin-bottom: 0px;
    }
  }

  .accordion-body {
    display: flex;
    flex-direction: column;
    background-color: var(--Pale-grey);
    padding: 30px 40px;
    //height: 100%;
    p {
      margin: 0px;
      align-self: center;
      //margin-right: 80px;
    }

    .accordion-body-item {
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;
      // p {
      //   width: 18%;
      // }

      .input-for-checkbox {
        margin-left: 40px; //161px;
        margin-right: 40px;
      }

      .custom-select-wrapper {
        width: 50%;
      }
    }

    .save-cancel-buttons-div {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .save-cancel-buttons-div-single {
      @extend .save-cancel-buttons-div;
      justify-content: flex-end;
    }

    .delete-account-button {
      width: 203px;
      margin-top: 0px;
      height: 30px;
      border: 2px solid var(--Grey);
      border-radius: 71px;
      color: var(--Grey);
      font-size: large;
    }
  }
}

.row-with-checkbox-input {
  display: flex;
  flex-flow: row nowrap;
}

@media (max-width: 1024px) {
  .custom-select-wrapper {
    width: 50%;
  }

  .prefered-language {
    width: 100%;
  }

  .user-info-accordion .accordion-body .accordion-body-item p {
    width: 50%;
    margin-right: 10px;
  }

  .deletemodal {
    .content {
      .buttons-div {
        button {
          width: 141px;
          height: 48px;
        }
      }
    }
  }
}

@media (max-width: 768px) {


  .user-info {
    flex-flow: column wrap;
    justify-content: center;

    .image-div {
      width: 50%;
      height: auto;
      margin: auto;

      img {
        border-radius: 50%;
      }
    }
  }

  .main {
    padding: 20px 30px 100px 30px;
  }

  .user-name-surn {
    margin-top: 15px;
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid black;
  }

  .user-info-accordion {
    .accordion-body {
      padding: 0;

      .accordion-body-item {
        flex-flow: column wrap;
        margin: 0;

        .custom-select-wrapper,
        p {
          width: 100%;
        }

        p {
          margin: 10px auto;
          padding-left: 42px;
        }

        .input-for-checkbox {
          margin-left: 15px;
        }
      }

      .save-info-button {
        margin: 0px auto;
        width: 100%;
      }

      .reverse-row-with-checkbox-input {
        flex-flow: row-reverse nowrap;
        align-items: center;

        p {
          padding-left: 27px;
        }
      }

      .prefered-language {
        flex-flow: column wrap;
        // width: auto;
      }
      .save-cancel-buttons-div {
      margin-top: 34px;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: space-between;

      .delete-account-button {
        width: 100%;
        height: 48px;
      }
      .btn-def{
        
        margin-bottom: 15px;
      }
    }
    }


  }

  // .custom-select-wrapper-mob {
  //   top: 70px;
  // }

  .deletemodal {
    padding-top: 25px;

    .content {
      .buttons-div {
        button {
          width: 100%;
          height: 32px;
          margin-bottom: 17px !important;
        }
      }
    }
  }


}
</style>
