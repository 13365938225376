<template>
  <div class="main">
    <div class="back-page">
      <font-awesome-icon :icon="['fasl', 'arrow-left']" style="color: var(--Green)" />&nbsp;
      <b><a href="#" @click="$router.push('/rooms')">{{
        $t("roomInformation_backPage_a")
      }}</a></b>
    </div>
    <div class="info-div">
      <div class="img-block">
        <img src="@/assets/roomPic.svg" alt="Cube Image" />
      </div>
      <div class="text-and-buttons">
        <div class="info-elements text-break">
          <h1 class="info-1">{{ roomName }}</h1>
          <div class="edit-delete-buttons info-2">
            <button @click="openEditModal" class="room-edit-delete-buttons">
              <img src="@/assets/icons/edit.png" /></button><button class="room-edit-delete-buttons"
              @click="openDeleteRoomModal">
              <img src="@/assets/icons/delete.png" />
            </button>
          </div>
          <p class="text-break info-3">
            <b>{{ $t("roomInformation_textAndButtons_p_b1") }} </b>
            {{ locationName }}
          </p>
          <p class="text-break w-80 info-4">
            <b>{{ $t("roomInformation_textAndButtons_p_b2") }}</b>
            {{ roomDescription }}
          </p>
        </div>

        <button type="button" class="add-or-save-button display-none-on-mobile" @click="addProducts">
          {{ $t("roomInformation_textAndButtons_button") }}
        </button>
      </div>
    </div>
    <div class="main-content">
      <div class="search-and-buttons">
        <div class="headline-and-input">
          <h4 class="display-none-on-mobile">
            {{ $t("roomInformation_mainContent_headlineAndInput_h1") }}
          </h4>
          <button type="button" class="add-or-save-button display-none-on-desktop" @click="addProducts">
            {{ $t("roomInformation_textAndButtons_button") }}
          </button>
          <input type="text" v-model="searchQuery"
            :placeholder="$t('roomInformation_mainContent_input_placeholder_search')" data-toggle="tooltip"
            :title="'Search for products in this room by name, serial number, model etc'" />
        </div>
        <div class="toggle-buttons">
          <button v-on:click="gridView()" :class="{ 'selected-view': grid_view }">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Group 83">
                <rect id="Rectangle 66" width="10" height="10" fill="currentColor" />
                <rect id="Rectangle 67" x="13" width="10" height="10" fill="currentColor" />
                <rect id="Rectangle 68" x="13" y="13" width="10" height="10" fill="currentColor" />
                <rect id="Rectangle 69" y="13" width="10" height="10" fill="currentColor" />
              </g>
            </svg>
          </button>
          <button v-on:click="listView()" :class="{ 'selected-view': list_view }">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Group 82">
                <rect id="Rectangle 70" width="21" height="3" fill="currentColor" />
                <rect id="Rectangle 71" y="7" width="23" height="3" fill="currentColor" />
                <rect id="Rectangle 72" y="13" width="20" height="3" fill="currentColor" />
                <rect id="Rectangle 73" y="20" width="22" height="3" fill="currentColor" />
              </g>
            </svg>
          </button>
        </div>
        <h4 class="display-none-on-desktop products-h4-mobile">
          {{ $t("roomInformation_mainContent_headlineAndInput_h1") }}
        </h4>
      </div>

      <div class="product-list">
        <grid-view v-if="grid_view" :products="filteredProducts" :isNewRoomButton="false">
          <template #default="{ product }">
            <div>
              <div class="img-block-2">
                <img v-if="speakerPhotos[product.model]" :src="speakerPhotos[product.model]" />
                <img v-else src="@/assets/SpeakerImg.png" />
              </div>
              <div class="product-info-block">
                <div>
                  <h3>{{ product.model }}</h3>
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_name") }}&nbsp;</strong>{{ product.model }}
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_sn") }}&nbsp; </strong>{{ product.serial_number }}
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_warranty") }}&nbsp;</strong>{{ $t("products_grid_view_warranty_until")
                  }}
                  {{ convertDate(product.warranty_end_date)
                  }}<!-- {{ convertDate(product.warranty_end_date) }} !-->
                </div>
              </div>
              <div class="edit-buttons">
                <!-- <button><img src="@/assets/icons/edit.png" /></button> -->
                <button>
                  <img src="@/assets/icons/delete-cropped.png" @click="openDeleteModal(product)" />
                </button>
              </div>
            </div>
          </template>
        </grid-view>

        <list-view v-else :products="filteredProducts" :tableFields="fieldsToShow">
          <tr v-for="item in filteredProducts" :key="item">
            <td v-for="{ value } in table_fields" :key="value" class="display-none-on-mobile">
              <div class="td-content">
                <!-- Conditionally render image before the model -->
                <img v-if="value === 'image' && speakerPhotos[item.model]" :src="speakerPhotos[item.model]"
                  alt="Speaker Image" style="width: 50px" />
                <img v-if="value === 'image' && !speakerPhotos[item.model]" src="@/assets/SpeakerImg.png"
                  alt="Speaker Image" style="width: 50px" />
                <!-- Conditionally render actions -->
                <div v-else-if="value === 'actions'">
                  <button class="delete-list-button">
                    <img src="@/assets/icons/delete.png" @click="openDeleteModal(item)" />
                  </button>
                  <!-- Add more buttons as needed -->
                </div>
                <!-- Render other fields -->
                <div v-else>
                  {{ item[value] }}
                </div>
              </div>
            </td>

            <!-- MOBILE VERSION -->

            <td class="display-none-on-desktop text-center">
              <!-- <div v-for="{ value } in table_fields" :key="value"> -->
              <div class="td-content" v-for="{ value } in table_fields" :key="value">
                <!-- Image -->

                <img v-if="value === 'image' && speakerPhotos[item.model]" :src="speakerPhotos[item.model]"
                  alt="Speaker Image" style="width: 50px" />
                <img v-if="value === 'image' && !speakerPhotos[item.model]" src="@/assets/SpeakerImg.png"
                  alt="Speaker Image" style="width: 50px" />
                <!-- Conditionally render select button -->
                <div v-if="value === 'select'" class="custom-checkbox"></div>

                <!-- Conditionally render actions -->
                <div v-else-if="value === 'actions'" class="btns-for-list-view">
                  <button @click.stop="openDeleteModal(item)">
                    <img src="@/assets/icons/delete-cropped.png" style="width: 50px" />
                  </button>

                  <!-- Add more buttons as needed -->
                </div>
                <div v-else-if="value === 'warranty_end_date'">
                  {{ $t("products_grid_view_warranty_until") }}:
                  {{ convertDate(item[value]) }}
                </div>
                <!-- Render other fields -->
                <div v-else>
                  {{ item[value] }}
                </div>
              </div>
              <!-- </div> -->
            </td>
          </tr>
        </list-view>
      </div>
    </div>

    <div class="modal" v-if="showModal">
      <div class="content">
        <VForm @submit="assignNewProduct">
          <div class="header-and-close">
            <h1>{{ $t("roomInformation_modal_headerAndClose_h1") }}</h1>
            <span class="close" @click="closeModal(event)">x</span>
          </div>

          <CustomSelect></CustomSelect>

          <div class="buttons-div">
            <button type="submit" class="save-info-button">
              {{ $t("roomInformation_modal_buttonDiv_button") }}
            </button>
            <button type="button" class="cancel-info-button" @click="closeModal">
              {{ $t("button_cancel") }}
            </button>
          </div>
          <div class="modal-error-block" v-if="$store.state.errorStatus">
            <div class="error">
              <p>
                {{ $t("errorpage_text") }}
              </p>
              <div class="error-context">
                <p>{{ $store.state.errorMessage }}</p>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>

    <div class="deletemodal" v-if="showDeleteModal" @click="closeModal(event)">
      <div class="content" @click.stop>
        <h1 v-if="deleteRoomBoolean">
          {{ $t("roomInformation_deletemodal_h2") }}
        </h1>
        <h1 v-else>{{ $t("roomInformation_deletemodal_h1") }}</h1>
        <p>
          {{ $t("roomInformation_deletemodal_p1") }}
          <span v-if="deleteRoomBoolean">{{
            $t("roomInformation_deletemodal_p1_2")
          }}</span><span v-else>"{{ productToDelete.model }}"</span>?<br />{{
  $t("roomInformation_deletemodal_p2")
}}
        </p>
        <div class="buttons-div">
          <button type="button" class="save-info-button" @click="confirmDelete">
            {{ $t("button_delete") }}
          </button>
          <button type="button" class="cancel-info-button" @click="closeModal(event)">
            {{ $t("button_cancel") }}
          </button>
        </div>
        <div class="modal-error-block" v-if="$store.state.errorStatus">
          <div class="error">
            <p>
              {{ $t("errorpage_text") }}
            </p>
            <div class="error-context">
              <p>{{ $store.state.errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showEditRoom" @click="closeModal">
      <div class="content">
        <VForm @submit="editRoom">
          <div class="header-and-close">
            <h1>
              {{ $t("roomsPage_modal_createRoom_edit") }}
              {{ formEditRoom.name }}
            </h1>
            <span class="close" @click="closeModal">x</span>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Room name" :rules="'required|noLeadingSpace|lengthBetween:1,30'" v-slot="{ field, errors }"
              v-model="formEditRoom.name">
              <label>{{ $t("roomsPage_modal_createRoom_roomName") }}</label>
              <div class="input-and-error">
                <input type="text" v-model="formEditRoom.name" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <div class="custom-select-wrapper">
            <VField name="Room type" rules="required" v-slot="{ field, errors }" v-model="formEditRoom.type">
              <label>{{ $t("roomsPage_modal_createRoom_roomType") }}</label>

              <div class="select-and-error">
                <div class="w-100">
                  <select class="custom-select" v-model="formEditRoom.type" v-bind="field">
                    <option value="room-type-not-selected-key" selected>
                      {{ $t("roomsPage_modal_createRoom_roomType_not") }}
                    </option>
                    <option value="room-studio-key">
                      {{ $t("roomsPage_modal_createRoom_roomType_studio") }}
                    </option>
                    <option value="room-home-studio-key">
                      {{ $t("roomsPage_modal_createRoom_roomType_home") }}
                    </option>
                    <option value="room-mixed-key">
                      {{ $t("roomsPage_modal_createRoom_roomType_mixed") }}
                    </option>
                    <option value="room-recreational-key">
                      {{
                        $t("roomsPage_modal_createRoom_roomType_recreational")
                      }}
                    </option>
                    <option value="room-public-space-key">
                      {{ $t("roomsPage_modal_createRoom_roomType_public") }}
                    </option>
                    <option value="room-research-key">
                      {{ $t("roomsPage_modal_createRoom_roomType_research") }}
                    </option>
                  </select>
                  <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" style="top: 28" />
                </div>

                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <div class="custom-input-wrapper">
            <VField name="Notes" v-slot="{ field, errors }" v-model="formEditRoom.description">
              <label>{{ $t("roomsPage_modal_createRoom_notes") }}</label>
              <div class="input-and-error">
                <input type="text" v-model="formEditRoom.description" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <div class="buttons-div">
            <button type="submit" class="save-info-button">
              <span>
                {{ $t("roomInformation_modal_buttonDiv_button") }}
              </span>
            </button>
            <button type="button" class="cancel-info-button" @click="closeModal">
              {{ $t("button_cancel") }}
            </button>
          </div>
          <div class="modal-error-block" v-if="$store.state.errorStatus">
            <div class="error">
              <p>
                {{ $t("errorpage_text") }}
              </p>
              <div class="error-context">
                <p>{{ $store.state.errorMessage }}</p>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<script>
import ListView from "@/components/UI/DataListView.vue";
import GridView from "@/components/UI/DataGridView.vue";
import CustomSelect from "@/components/UI/CustomSelect.vue";
import fetchSpeakerPhotos from "@/api/getProductPhoto";
import {
  addProductToTheRoom,
  getRoomInfo,
  getLocationInfo,
  unassignProduct,
  updateRoom,
  deleteRoom,
} from "@/api/addingRooms.js";
import getUserProducts from "@/api/getUserProducts";
import clearRoomsAndLocations from "@/api/checkRoomsAndLocations.js";

export default {
  name: "RoomInformation",
  components: {
    ListView,
    GridView,
    CustomSelect,
  },
  data() {
    return {
      searchQuery: "",
      grid_view: true,
      list_view: false,
      assignedProducts: [],
      unassignedProducts: [],
      speakerPhotos: {},
      showModal: false,
      roomId: "",
      locationId: "",
      roomName: "",
      lcoationId: "",
      locationName: "",
      locationInfo: "",
      roomInfo: "",
      roomDescription: "",
      roomType: "",
      showDeleteModal: false,
      showEditRoom: false,
      productToDelete: null,
      deleteRoomBoolean: false,
      table_fields: [
        { label: "", value: "image" },
        { label: "Model", value: "model" }, // Updated this line
        { label: "Serial number", value: "serial_number" },
        { label: "Warranty", value: "warranty_end_date" }, // Updated this line
        { label: "Unassign", value: "actions" },
      ],

      table_fields_phone: [{ label: "", value: "select" }],
      isDesktop: window.innerWidth > 767,
      formEditRoom: {
        name: this.roomName,
        type: this.roomType,
        description: this.roomDescription,
      },
    };
  },
  methods: {
    listView() {
      this.grid_view = false;
      this.list_view = true;
    },
    gridView() {
      this.list_view = false;
      this.grid_view = true;
    },
    convertDate(receivedDate) {
      if (!receivedDate) {
        // If receivedDate is null, undefined, or empty string, return a default value
        return "N/A";
      }
      const date = new Date(receivedDate);
      if (isNaN(date.getTime())) {
        // If receivedDate cannot be parsed into a Date object, return a default value
        return "N/A";
      }
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    addProducts() {
      this.$store.commit("setErrorStatus", false);
      this.showModal = true;
    },
    getAssignedUserProducts() {
      this.assignedProducts = [];
      const products = this.$store.getters.getUserProducts;
      // Filter out the products that are assigned to this room
      this.assignedProducts = products.filter(
        (product) => product.room === this.roomId
      );
    },
    getUnassignedUserProducts() {
      this.unassignedProducts = [];
      const products = this.$store.getters.getUserProducts;
      // Filter out the products that are not assigned to any room
      this.unassignedProducts = products.filter(
        (product) => product.room === null
      );
    },
    openDeleteModal(product) {
      this.$store.commit("setErrorStatus", false);
      this.showDeleteModal = true;
      this.productToDelete = product;
    },

    openDeleteRoomModal() {
      this.$store.commit("setErrorStatus", false);
      this.deleteRoomBoolean = true;
      this.showDeleteModal = true;
    },
    openEditModal() {
      this.$store.commit("setErrorStatus", false);
      this.showEditRoom = true;
      this.formEditRoom = {
        ...this.roomInfo,
        name: this.roomName,
        type: this.roomType,
        description: this.roomDescription,
      };
    },

    async editRoom() {
      try {
        await updateRoom(this.formEditRoom);
        // console.log(this.formEditRoom);
        this.roomName = this.formEditRoom.name;
        this.roomDescription = this.formEditRoom.description;
        this.closeModal();
        // await this.fetchLocationsAndRooms();
      } catch (error) {
        console.error(error);
        this.$store.commit("setErrorStatus", true);
      }
    },

    async confirmDelete() {
      try {
        if (this.deleteRoomBoolean) {
          await deleteRoom(this.roomId);
          this.closeModal();
          clearRoomsAndLocations();
          this.$router.push("/rooms");
        } else {
          await unassignProduct(this.productToDelete.serial_number).then(() => {
            this.$store.commit(
              "clearAssignedToTheRoomProduct",
              this.productToDelete.serial_number
            );
          });

          this.showDeleteModal = false;
        }
        this.getAssignedUserProducts();
      } catch (error) {
        this.$store.commit("setErrorStatus", true);
      }
    },

    closeModal(event) {
      if (
        !event ||
        event.target.classList.contains("modal") ||
        event.target.classList.contains("close") ||
        event.target.classList.contains("cancel-info-button")
      ) {
        this.showModal = false;
        this.showEditRoom = false;
        this.showDeleteModal = false;
        this.deleteRoomBoolean = false;
        this.$store.commit("clearTemporaryAssignedProducts");
        this.$store.commit("setErrorStatus", false);
      }
    },
    async assignNewProduct() {
      try {
        await addProductToTheRoom(this.roomId);
        this.showModal = false;
        await getUserProducts();
        this.getAssignedUserProducts();
      } catch (error) {
        this.$store.commit("setErrorStatus", true);
      }
    },

    refreshLocationData() {
      getLocationInfo(this.locationId).then((locationInfo) => {
        this.locationInfo = locationInfo;
        this.locationName = locationInfo.name;
      });
    },
    updateWindowSize() {
      this.isDesktop = window.innerWidth > 768;
    },
    activeLocaleState() {
      return this.$store.state.activeLocale;
    },
  },
  computed: {
    filteredProducts() {
      return this.assignedProducts.filter(
        (product) =>
          product.model
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          product.serial_number
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
    },
    fieldsToShow() {
      return this.isDesktop ? this.table_fields : this.table_fields_phone;
    },
  },

  async mounted() {
    this.roomId = this.$route.params.roomId;
    this.locationId = this.$route.params.locationId;
    this.roomInfo = await getRoomInfo(this.roomId);
    this.roomName = this.roomInfo.name;
    this.roomDescription = this.roomInfo.description;
    this.roomType = this.roomInfo.type;
    this.refreshLocationData();
    this.getAssignedUserProducts();
    this.getUnassignedUserProducts();
    window.addEventListener("resize", this.updateWindowSize);
  },
  async created() {
    this.speakerPhotos = await fetchSpeakerPhotos();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  },
  watch: {
    activeLocaleState: {
      handler(newLocale) {
        if (newLocale) {
          this.table_fields = [
            { label: "", value: "image" },
            { label: this.$t("products_listView_table_heading_model"), value: "model" },
            { label: this.$t("products_listView_table_heading_serialNumber"), value: "serial_number" },
            { label: this.$t("products_listView_table_heading_warranty"), value: "warranty_end_date" },
            { label: this.$t("products_listView_table_heading_actions"), value: "actions" },
          ]
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  padding-bottom: 40px;
  height: calc(100vh - var(--UpperMenuHeight));
  overflow-y: scroll;
}

.info-div {
  padding: 20px 30px 10px 30px;
  width: 100%;
  display: flex;
  height: 342px;
  background-color: var(--White);

  .img-block {
    padding: 30px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 50%;
      height: auto;
    }
  }

  .text-and-buttons {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .info-elements {
      .info-1 {
        order: 1;
      }

      .info-2 {
        order: 1;
      }

      .info-3 {
        order: 3;
      }

      .info-4 {
        order: 4;
      }
    }

    .room-edit-delete-buttons {
      border: none;
      background-color: var(--White);

      img {
        width: 53px;
        height: auto;
      }
    }

    .add-or-save-button {
      align-self: flex-end;
      width: 293px;
      height: 55px;
      margin-right: 160px;
      border: none;
    }
  }
}

.main-content {
  .search-and-buttons {
    margin-left: 57px;
    margin-right: 60px;
    margin-top: 39px;
    margin-bottom: 15px;

    .headline-and-input {
      width: 100%;

      input {
        height: 54px;
        width: 100%;
        max-width: 718px;
        border-radius: 71px;
        padding-left: 42px;
        padding-right: 42px;
        border: 1px solid var(--Grey);
      }
    }

    .toggle-buttons {
      display: flex;
      justify-content: end;

      button {
        border: none;
        font-size: 2.4rem;
        background-color: var(--bg-grey);
        color: var(--Grey);
      }
    }
  }

  .img-block-2 {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 230px;
      height: 230px;
      object-fit: cover;
    }
  }
}

.product-list {
  //padding: 20px;
  margin-left: 40px;
  margin-right: 40px;

  .product-info-block {
    padding: 20px;
  }
}

.back-page {
  margin-top: 27px;
  margin-left: 27px;
  margin-bottom: 27px;

  a {
    color: var(--Green);
  }
}

.delete-list-button {
  border: none;
  background-color: none;
  padding: 0;

  img {
    background-color: var(--bg-grey);
    height: 55px;
    width: auto;
  }
}

@media (max-width: 1024px) {
  .main-content {
    .search-and-buttons {
      margin-right: 55px;
    }
  }

  // .modal {
  //   min-width: auto;
  //   .content {
  //     width: 90%;
  //     padding: 20px 40px 45px 40px;
  //     .custom-input-wrapper,
  //     .custom-select-wrapper {
  //       flex-flow: column wrap;
  //       align-items: center;
  //       label {
  //         margin-top: 0px;
  //         margin-right: 0px;
  //         margin-bottom: 8px;
  //         text-align: center;
  //       }

  //       .input-and-error {
  //         margin-right: 0px;
  //       }

  //       .custom-select-required {
  //         margin-right: 0px;
  //       }

  //       .select-and-error {
  //         margin-right: 0px;
  //         .custom-select-icon {
  //           top: 52% !important;
  //           right: 23%;
  //         }
  //       }
  //     }

  //     .custom-select-icon {
  //       right: 20%;
  //       top: 52%;
  //     }

  //     .custom-select {
  //       border: 1px solid var(--Grey);
  //     }
  //   }
  // }
}

@media (max-width: 768px) {
  .modal .content {
    padding: 47px 15px 45px 15px;

    .header-and-close {
      margin-bottom: 20px;
    }
  }

  .info-div {
    flex-flow: column;
    //justify-content: center;
    align-items: center;
    height: fit-content;

    .img-block {
      height: auto;
      width: auto;

      img {
        height: auto;
        width: 50%;
      }
    }

    .text-and-buttons {
      .info-elements {
        display: flex;
        flex-flow: column wrap;

        .info-1 {
          font-size: xx-large;
          text-align: center;
          margin-bottom: 25px;
        }

        .info-2 {
          order: 5;
        }
      }
    }
  }

  .main-content {
    .search-and-buttons {
      margin: 22px 20px 30px 20px;

      .headline-and-input {
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;

        .add-or-save-button {
          border: none;
          margin-bottom: 25px;
          margin-right: 0px;
          width: auto;
          padding: 15px;
          align-self: auto;
        }
      }

      .toggle-buttons {
        margin: 25px auto;
        justify-content: space-evenly;
      }

      .products-h4-mobile {
        border-bottom: 1px solid black;
        padding-left: 15px;
        padding-bottom: 5px;
      }
    }
  }

  .edit-delete-buttons {
    display: flex;
    justify-content: space-evenly;
  }

  .product-list {
    margin: 0;
  }

  td.display-none-on-desktop.text-center {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-template-areas:
      ". col2_2 ."
      "col1 col2_3 col3_2"
      ". col3_1 col3_2";
  }

  td.display-none-on-desktop.text-center>div:nth-child(1) {
    grid-area: col1;
  }

  td.display-none-on-desktop.text-center>div:nth-child(2) {
    grid-area: col2_1;
    display: none;
  }

  td.display-none-on-desktop.text-center>div:nth-child(3) {
    grid-area: col2_2;
  }

  td.display-none-on-desktop.text-center>div:nth-child(4) {
    grid-area: col2_3;
  }

  td.display-none-on-desktop.text-center>div:nth-child(5) {
    grid-area: col3_1;
  }

  td.display-none-on-desktop.text-center>div:nth-child(6) {
    grid-area: col3_2;
  }
}
</style>
