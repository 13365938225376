/* global Paddle */
import { createApp } from "vue";
import App from "./App.vue";
import KeyCloakService from "@/security/KeyCloakService";
import router from "@/router";
import "@/assets/sass/customBootstrap.scss";
import "bootstrap";
import "@/assets/sass/main.scss";
import "@/assets/sass/fonts.scss";
import "@/assets/sass/uploadReceiptModal.scss";
// import { createI18n } from "vue-i18n";
// import fetchLocalizationData from "@/locales/i18-data";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faArrowLeft,
  faChevronLeft,
  faXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import store from "@/store";
import getUserInfo from "@/api/getUserInfo.js";
import getUserProducts from "@/api/getUserProducts.js";
import { Field, Form, ErrorMessage } from "vee-validate";
import sendLoginTimestamp from "@/api/timeStamp.js";
import { fetchPreRegisteredProducts } from "@/api/registerProducts";
import { i18n } from "@/locales/i18n";
import { jwtDecode } from "jwt-decode";
// import tooltips from "@/customDirectives/tooltips.js";
import getAccounts from "@/api/accountSelector";
import shadow from "vue-shadow-dom";

library.add(
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faArrowLeft,
  faXmark,
  faMagnifyingGlass
);

const app = createApp(App);

store.commit("setLoading", true);

export const initFullApp = async () => {
  // Paddle.Environment.set(process.env.VUE_APP_PADDLE_ENV);
  Paddle.Setup({
    vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR),
  });

  try {
    await getUserInfo((userData) => {
      store.dispatch("updateUserData", userData);
      // console.log(userData);
    });
    let accounts = await getAccounts();
    if (accounts.data.content.length == 1) {
      sessionStorage.setItem("selectedAccountId", accounts.data.content[0].id);
      sessionStorage.setItem(
        "selectedAccountPrivateOrg",
        accounts.data.content[0].privat_org
      );
    }
    if (!sessionStorage.getItem("selectedAccountId")) {
      store.commit("showAccountSelector", true);
    } else {
      store.commit("showAccountSelector", false);
      let selectedId = sessionStorage.getItem("selectedAccountId");
      store.commit("updatePrivateOrganizationId", selectedId);
    }
    await getUserProducts();
    // timestamp function
    const timestamp = new Date().toISOString();
    await sendLoginTimestamp(timestamp);
    // Check if there are pre-registered products
    const data = await fetchPreRegisteredProducts();
    if (data.number_of_elements > 0) {
      // router.push("/product-registration"); // Uncomment for production!!!
    }
    store.commit("setError", false); // put to false!
    // }
  } catch (error) {
    store.commit("setError", true); // put to true!
    // In your Vuex store or mutation where you handle the error
    if (error.response && error.response.data) {
      const formattedError = {
        url: error.config.url,
        message: error.response.data.message,
        details: JSON.stringify(error.response.data, null, 2), // Pretty-print JSON
      };
      store.commit("setErrorMessage", formattedError);
    } else {
      store.commit("setErrorMessage", {
        message: "An error occurred",
        url: error.config.url,
      });
    }

    console.error("Error during launching the application: ", error);
    // KeyCloakService.CallLogOut();
    // throw error;
  }

  app
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("VForm", Form)
    .component("VField", Field)
    .component("ErrorMessage", ErrorMessage)
    .use(shadow)
    .use(i18n)
    .use(store)
    .use(router)
    .mount("#app");
};

const initializeVueApp = async () => {
  store.commit("setLandingPage", true);

  app
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("VForm", Form)
    .component("VField", Field)
    .component("ErrorMessage", ErrorMessage)
    .use(i18n)
    .use(router)
    .use(store)
    .mount("#app");
};

KeyCloakService.initialize().then(() => {
  // Now initialize Vue app and router
  if (KeyCloakService.isAuthenticated()) {
    initFullApp();
    store.commit("setLoading", false);
    const accessToken = KeyCloakService.GetAccesToken().trim();
    const decodedToken = jwtDecode(accessToken);
    store.commit("setAccessTokenInfo", decodedToken);
    store.commit("setActiveLocale", decodedToken.locale);
    // console.log(decodedToken);
  } else {
    initializeVueApp();
    store.commit("setLoading", false);
  }
});
