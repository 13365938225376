<template>
  <div class="component-block">
    <div class="service-container d-none d-md-block" v-for="plans in services_list" :key="plans.id">
      <div class="header-service">
        <div class="service-img">
          <!-- <img v-if="!$t(plans.image_url_key)" src="@/assets/SpeakerImg.png" /> -->
          <img :src="$t(plans.image_url_key)" @error="onImageError" />
        </div>
        <div class="service-description">
          <h2 class="fw-bold">{{ $t(plans.name_key) }}</h2>
          <p>
            <span>{{ $t(plans.description_key) }} <a :href="$t(plans.website_url_key)">{{
              $t("service_moreDesription_url")
            }}</a></span>
          </p>
          <div class="service-status-div">
            <p v-if="plans.serviceStatus == 'service_status_inactive'" class="service-status">
              {{
                $t(plans.serviceStatus, { nameOfService: $t(plans.name_key) })
              }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_limitted_until'" class="service-status">
              {{
                $t(plans.serviceStatus, {
                  amount: plans.productQuantity,
                  until: plans.validUntil,
                })
              }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_unlimitted_until'" class="service-status">
              {{
                $t(plans.serviceStatus, {
                  nameOfService: $t(plans.name_key),
                  until: plans.validUntil,
                })
              }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_perpetual_limitted'" class="service-status">
              {{ $t(plans.serviceStatus, { amount: plans.productQuantity }) }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_perpetual'" class="service-status">
              {{
                $t(plans.serviceStatus, { nameOfService: $t(plans.name_key) })
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="products">
        <!-- <h4 class="h4-before-body-service fw-bold">
          {{ $t("services_products_h4_chooseplan") }}
        </h4> -->
        <div class="body-service">
          <button class="scrolling-button-left" @click="navigate(plans, 'prev')" v-if="plans.services.length > 4">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </button>
          <div class="service-product" v-for="service in visibleServices(plans)" :key="service.id">
            <div class="photo-and-text">
              <div class="service-product-info text-center service-product-child">
                <p class="fw-bold">{{ $t(service.name_key) }}</p>
                <p v-if="service.payment_app_service?.price?.EUR">{{ parseInt(service.payment_app_service.price.EUR) }}€
                </p>
              </div>
            </div>
            <div class="service-product-buy service-product-child">
              <button v-if="service.payment_app_service?.price?.EUR" :disabled="!accountInformation"
                class="service-product-buy-btn" @click="openPaddle(service)">
                <span>{{ $t("services_serviceProduct_button_buy") }}</span>
              </button>
              <button class="service-product-buy-btn" :disabled="!accountInformation" @click="activateService(service)"
                v-else><span>{{
                  $t("services_serviceProduct_button_activate")
                }}</span></button>
            </div>
            <!-- Question Button -->
            <!-- <button @click="openModalTooltip(service)" class="question-btn">
              <span>?</span>
            </button> -->
            <button data-bs-toggle="tooltip" data-bs-placement="top" :title="$t(service.description_key)"
              class="question-btn" :class="{ 'active': this.activeTooltipButton === service.id }"
              @click="handleButtonClick(service.id)">
              <span>?</span>
            </button>
          </div>
          <button class="scrolling-button-right" v-if="plans.services.length > 4" @click="navigate(plans, 'next')">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </button>
        </div>
      </div>
      <div class="terms-of-use">
        <p>{{ $t("services_serviceProduct_termsOfUse_p1") }} <a :href="plans.terms_of_service_url_key"
            class="text-decoration-underline">{{
              $t("services_serviceProduct_termsOfUse_p2") }}</a></p>
      </div>
      <!-- BLOCK FOR A MOBILE LAYOUT -->
      <!-- BLOCK FOR A MOBILE LAYOUT -->
      <!-- BLOCK FOR A MOBILE LAYOUT -->
      <!-- BLOCK FOR A MOBILE LAYOUT -->
      <!-- BLOCK FOR A MOBILE LAYOUT -->
      <!-- BLOCK FOR A MOBILE LAYOUT -->
      <!-- BLOCK FOR A MOBILE LAYOUT -->
    </div>
    <div class="products-mobile d-md-none">
      <div v-for="plans in services_list" :key="plans.id" class="service-container-mobile">
        <div class="image-header">
          <!-- <img v-if="!$t(plans.image_url_key)" src="@/assets/SpeakerImg.png" /> -->
          <img :src="$t(plans.image_url_key)" @error="onImageError" />
        </div>
        <div class="text-section">
          <h2>{{ $t(plans.name_key) }}</h2>
          <p>{{ $t(plans.description_key) }} <a :href="plans.website_url_key">{{ $t("service_moreDesription_url") }}</a>
          </p>
        </div>

        <div class="status-block">
          <div class="service-status-div">
            <p v-if="plans.serviceStatus == 'service_status_inactive'" class="service-status">
              {{
                $t(plans.serviceStatus, { nameOfService: $t(plans.name_key) })
              }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_limitted_until'" class="service-status">
              {{
                $t(plans.serviceStatus, {
                  amount: plans.productQuantity,
                  until: plans.validUntil,
                })
              }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_unlimitted_until'" class="service-status">
              {{
                $t(plans.serviceStatus, {
                  nameOfService: $t(plans.name_key),
                  until: plans.validUntil,
                })
              }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_perpetual_limitted'" class="service-status">
              {{ $t(plans.serviceStatus, { amount: plans.productQuantity }) }}
            </p>
            <p v-if="plans.serviceStatus == 'service_status_perpetual'" class="service-status">
              {{
                $t(plans.serviceStatus, { nameOfService: $t(plans.name_key) })
              }}
            </p>
          </div>
          <p>{{ $t("services_products_h4_chooseplan") }}</p>
        </div>
        <div class="choose-and-buy">

          <div class="drop-down-div">
            <!-- `selectedOption[plans.id]` to display the name in the dropdown -->
            <CustomDropdown :selectedOption="selectedOptions[plans.id]?.name">
              <li v-for="service in plans.services" :key="service.id" @click="selectService(plans.id, service)">
                <div class="inside-li-div">
                  <p>{{ $t(service.name_key) }}</p>
                  <p v-if="service.payment_app_service?.price?.EUR">{{
                    parseInt(service.payment_app_service.price.EUR)
                  }} €</p>
                </div>
              </li>
            </CustomDropdown>
            <div class="more-info-div" v-if="selectedOptions[plans.id]">
              <!-- <p>
                More information about
                {{ selectedOptions[plans.id].name }}
              </p> -->
              <!-- <button @click="openModalTooltip(selectedOptions[plans.id].serviceData)">
                More details about the chosen product
              </button> -->
              <div class="div-with-img">
                <img src="@/assets/iconsFromFigma/g_info.svg" />
              </div>
              <p>{{ $t(selectedOptions[plans.id].serviceData.description_key) }}</p>
            </div>
          </div>
          <button
            v-if="selectedOptions[plans.id] && selectedOptions[plans.id].serviceData.payment_app_service?.price?.EUR"
            :disabled="!accountInformation" @click="openPaddleMob(service)">
            {{ $t("services_serviceProduct_button_buy") }}
          </button>
          <button v-else-if="selectedOptions[plans.id]" :disabled="!accountInformation"
            @click="activateService(selectedOptions[plans.id].serviceData)">
            {{
              $t("services_serviceProduct_button_activate")
            }}
          </button>
          <div class="terms-of-use">
            <p>{{ $t("services_serviceProduct_termsOfUse_p1") }} <a :href="plans.terms_of_service_url_key"
                class="text-decoration-underline">{{
                  $t("services_serviceProduct_termsOfUse_p2") }}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL BLOCK -->
  <!-- MODAL BLOCK -->
  <!-- MODAL BLOCK -->
  <!-- MODAL BLOCK -->
  <!-- MODAL BLOCK -->
  <!-- MODAL BLOCK -->
  <!-- MODAL BLOCK -->

  <!-- Modal Check Sign-->
  <div v-if="showModalCheck" class="modal-overlay-check-mark" @click="closeModalCheck">
    <div class="modal-content" @click.stop>
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
      <p class="text-center text-white">{{ this.modalMessage }}</p>
      <div class="close-info">
        <p>Auto close in <span>{{ countdown }}</span> seconds</p>
        <button @click="closeModalCheck">Close</button>
      </div>
      <!-- <button @click="closeModal">Close</button> -->
    </div>
  </div>
  <!-- Modal -->
  <div v-if="showModalTooltip" class="modal-overlay-tooltip" @click.self="closeModalTooltip">
    <div class="modal-content">
      <div class="close-button-div">
        <img @click="closeModalTooltip" src="@/assets/iconsFromFigma/cross.svg" />
      </div>
      <h3 class="modal-title">{{ $t(modalData.name) }}</h3>
      <p class="modal-body">{{ $t(modalData.description) }}</p>
      <!-- <p>{{ modalData.plan }}</p> -->
      <!-- <button class="modal-close-btn" @click="closeModalTooltip">Close</button> -->
    </div>
  </div>

</template>

<script>
/* global Paddle */
import {
  getAllServices,
  getServiceList,
  checkStatusOfService,
  activateServiceById
} from "@/api/serviceMethods";

import CustomDropdown from "@/components/UI/CustomDropdown.vue";



export default {
  name: "TemplateForServices",
  components: {
    CustomDropdown,
  },
  data() {
    return {
      services_list: [],
      itemsPerSlide: 4,
      serviceProductElements: [],
      chosenValue: null,
      showModalCheck: false,
      showModalTooltip: false,
      modalMessage: '',
      modalData: {},
      countdown: 3, // Set initial countdown value
      countdownInterval: null, // For clearing the interval
      selectedOption: "",
      selectedOptions: {},
      activeTooltipButton: null,
      accountInformation: Boolean,
    };
  },

  methods: {

    handleButtonClick(serviceId) {
      this.activeTooltipButton = serviceId; // set the active button
    },
    handleGlobalClick(event) {
      const button = document.querySelector('.question-btn.active');
      if (button && !button.contains(event.target)) {
        this.activeTooltipButton = null;
      }
    },
    selectService(planId, service) {
      this.selectedOptions[planId] = {
        name: this.$t(service.name_key),
        serviceData: service
      };
    },

    async getServices() {
      try {
        const response = await getAllServices();
        const services = response.content;
        // console.log(services);

        const formatDate = (timestamp) => {
          const validDate = new Date(timestamp);
          const day = validDate.getDate().toString().padStart(2, "0");
          const month = (validDate.getMonth() + 1).toString().padStart(2, "0");
          const year = validDate.getFullYear();
          return `${day}.${month}.${year}`;
        };

        await Promise.all(
          services.map(async (service) => {
            const [additionalData, serviceStatus] = await Promise.all([
              getServiceList(service.id),
              checkStatusOfService(service.id),
            ]);
            // console.log(serviceStatus);
            service.services = additionalData.content;
            service.visibleServicesIndex = 0;

            if (!serviceStatus.is_valid) {
              service.serviceStatus = "service_status_inactive";
              return;
              //check valid_to and quantity
              //check purchase_type to define if it is one-time or sub - text
              //
            }


            // console.log(serviceStatus);
            let statusKey;

            //purchase_type="one-time"
            //

            if (serviceStatus.valid_to) {
              if (serviceStatus.total_quantity) {
                statusKey = "limitted_until"; // Service has a validity date and a total quantity
                // console.log(serviceStatus.order_id)
              } else {
                statusKey = "unlimitted_until"; // Service has a validity date but no total quantity
              }
            } else {
              if (serviceStatus.total_quantity) {
                statusKey = "perpetual_limitted"; // Service does not have a validity date but has a total quantity
              } else {
                statusKey = "perpetual"; // Service neither has a validity date nor a total quantity
              }
            }


            service.serviceStatus = `service_status_${statusKey}`;
            if (serviceStatus.valid_to) {
              service.validUntil = formatDate(serviceStatus.valid_to);
            }
            if (serviceStatus.total_quantity) {
              service.productQuantity = serviceStatus.total_quantity;
            }
          })
        );

        this.services_list = services;
        // console.log(this.services_list);
      } catch (error) {
        console.error("An error occurred while fetching the services:", error);
      }
    },

    onImageError(event) {
      // Set the fallback image source if the original image fails to load
      event.target.src = require("@/assets/SpeakerImg.png");
    },
    visibleServices(plans) {
      const start = plans.visibleServicesIndex * this.itemsPerSlide;
      const end = start + this.itemsPerSlide;
      return plans.services.slice(start, end);
    },

    navigate(plans, direction) {
      const maxIndex =
        Math.ceil(plans.services.length / this.itemsPerSlide) - 1;
      if (direction === "prev" && plans.visibleServicesIndex > 0) {
        plans.visibleServicesIndex--;
      } else if (
        direction === "next" &&
        plans.visibleServicesIndex < maxIndex
      ) {
        plans.visibleServicesIndex++;
      }
    },
    openPaddle(service) {
      const paddleID = service.payment_app_id;
      if (paddleID) {
        // console.log(paddleID);
        const modifiedPaddleID = paddleID.substring(2);
        Paddle.Checkout.open({
          passthrough: this.$store.state.userData.private_organization_id,
          product: modifiedPaddleID,
          locale: this.$i18n.locale,
          allowQuantity: false,
          email: this.$store.state.userData.email,
        });
      } else {
        alert("This product is not available right now");
        console.log(service);
      }
    },
    openPaddleMob(paddleID, service) {
      if (paddleID) {
        // console.log(paddleID);
        const modifiedPaddleID = paddleID.substring(2);
        Paddle.Checkout.open({
          passthrough: this.$store.state.userData.private_organization_id,
          product: modifiedPaddleID,
          locale: this.$i18n.locale,
          allowQuantity: false,
          email: this.$store.state.userData.email,
        });
      } else {
        alert("This product is not available right now");
        console.log(service);
      }
    },
    activateService(service) {
      try {
        activateServiceById(service.id).then(() => {
          this.modalMessage = 'Service has been successfully activated!';
          this.showModalCheck = true;
          this.startCountdown();
        });
      } catch (error) {
        alert("Failed to activate service. Please check console for more details.")
        this.showModalCheck = false;
      }
    },
    startCountdown() {
      this.countdown = 3; // Reset countdown value
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          this.closeModalCheck();
        }
      }, 1000); // Update every second
    },
    closeModalCheck() {
      this.showModalCheck = false;
      clearInterval(this.countdownInterval); // Clear the interval to stop countdown
      this.getServices(); // Fetch updated services if needed
    },
  },
  async created() {
    const accountInfo = sessionStorage.getItem("selectedAccountPrivateOrg");
    this.accountInformation = accountInfo === 'true'; // Convert to boolean
    console.log(this.accountInformation);
    this.getServices();
  },
  mounted() {
    this.getServices();
    document.addEventListener('click', this.handleGlobalClick);

  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleGlobalClick);
  }

};
</script>

<style lang="scss" scoped>
.component-block {
  width: 100%;
  max-width: 1440px;
}

.service-container {
  height: auto;
  width: 100%;
  background-color: var(--White);
  border-radius: 41px;
  overflow: hidden;
  margin-top: 30px;
  // padding: 20px;


  .header-service {
    display: flex;
    margin-bottom: 14px;

    .service-img {
      height: 100%;

      img {
        max-height: 200px;
        width: auto;
        // width: -moz
        border-radius: 21px;
        margin-left: 25px;

      }
    }

    .service-description {
      max-width: 800px;
      padding: 28px;
      padding-right: 58px;
      padding-top: 0px;
    }
  }

  .body-service {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    //align-items: stretch;
    overflow-x: auto;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    .service-product {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: calc(25% - 30px);
      min-width: calc(25% - 30px);
      margin: 15px;
      padding: 24px;
      border: 1px solid var(--Black);
      border-radius: 21px;
      position: relative;
      padding-top: 30px;

      .question-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // background-color: var(--Green);
        // color: var(--White);
        background-color: var(--White);
        color: var(--Green);
        border: 1px solid var(--Green);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        z-index: 2; // Ensure it's above other content

        &:hover {
          background-color: var(--Green);
          color: var(--White);
        }

        &.active {
          background-color: var(--Green) !important;
          color: var(--White) !important;
        }

      }


    }

    .service-product-img {
      padding: 22%;

      img {
        height: auto;
        width: 100%;
      }
    }

    .service-product-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 5px;

      p {
        margin-bottom: 10px;
      }
    }

    .service-product-buy {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;

      .service-product-buy-btn {
        width: 100%;
        height: 32px;
        width: 137px;
        font-size: 16px;
        color: var(--White);
        background-color: var(--Green);
        border: 0px;
        border-radius: 28px;

        &:disabled {
          background-color: Grey;
          cursor: not-allowed;
        }
      }
    }
  }

  // .dropdown {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   margin-bottom: 10px;

  //   p {
  //     margin: 0;
  //   }

  //   select {
  //     border-radius: 5px;
  //     //height: 30px;
  //     padding: 5px;
  //   }
  // }

  // .h4-before-body-service {
  //   margin-top: 20px;
  //   margin-left: 17px;
  // }
  .terms-of-use {
    margin-top: 14px;
    margin-left: 25px;

    p {
      margin-bottom: 25px;
    }

    a {
      color: var(--Green);
    }
  }
}

.service-container-mobile {
  position: relative;
  /* Ensure relative positioning */
  overflow: visible;
  /* Allow overflowing content to be visible */
  /* Ensure a lower z-index to not interfere with dropdown */
  padding: 19px;
  height: auto;
  width: 100%;
  background-color: var(--White);
  border-radius: 41px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  .image-header {
    margin-bottom: 20px;

    img {
      //max-width: 200px;
      height: auto;
      width: 100%;
      border-radius: 41px;
    }
  }

  .choose-and-buy {
    display: flex;
    flex-flow: column nowrap;

    .drop-down-div {
      margin-bottom: 20px;

      li {
        .inside-li-div {
          width: 100%;
          padding-right: 20px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;

          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      .more-info-div {
        margin-top: 20px;

        border: 2px solid var(--Green);
        border-radius: 20px;
        padding: 10px;

        .div-with-img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 28px;

          img {
            width: auto;
            height: auto;
            margin-bottom: 10px;
          }
        }


        p {
          margin: 0;
        }

        button {
          height: 40px;
          width: 100%;
          background-color: grey;
          // color: black;
        }
      }

    }

    // .custom-select-wrapper {
    //   width: auto;
    //   height: 37px;
    //   margin-bottom: 21px;

    //   .custom-select {
    //     height: 37px;
    //   }
    // }

    button {
      background-color: var(--Green);
      border-radius: 28px;
      color: var(--White);
      border: 0px;
      height: 37px;

      &:disabled {
        cursor: not-allowed;
        background-color: Grey;
      }
    }


  }

  .terms-of-use {

    margin-top: 10px;
  }
}

.photo-and-text {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.scrolling-button-left,
.scrolling-button-right {
  position: absolute; // Position the buttons absolutely
  top: 50%; // Center vertically
  height: 100%;
  transform: translateY(-50%); // Center correction
  z-index: 10; // Ensure it's above the scrollable content
  background-color: transparent;
  border: none;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.scrolling-button-left {
  left: 0; // Stick to the left side

  border-radius: 5px;
}

.scrolling-button-right {
  right: 0; // Stick to the right side

  border-radius: 5px;
}

//Modal Check Mark

.modal-overlay-check-mark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background: transparent;

    padding: 20px;
    max-width: 200px;
    border-radius: 5px;
    position: relative;
    animation: zoomIn 0.3s ease-in-out;

    .close-info {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      text-align: center;
      color: white;

      p {
        margin: 0;
      }

      button {
        color: white;
        display: none;
      }
    }
  }

  .checkmark {
    width: 70px;
    height: 70px;
    display: block;
    margin: 0 auto;
  }

  .checkmark-circle {
    stroke: #4CAF50;
    stroke-width: 3;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark-check {
    stroke: #4CAF50;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes zoomIn {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  button {
    height: 30px;
    margin-top: 10px;
    border: none;
    background-color: transparent;
  }
}


@media (min-width: 768px) {
  .service-container {
    .header-service {
      .service-img {
        margin-top: 32px;
      }

      .service-description {
        margin-top: 32px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .service-container {
    // .header-service {
    //   .service-img {
    //     img {
    //       border-radius: 0px;
    //     }
    //   }
    // }

    .body-service {
      .service-product {
        // flex: 1;
        min-width: 10px;
      }
    }
  }

  .service-product-child {
    flex: 1;
    flex-basis: 40%;
    // background-color: red;
    // margin: 2px;
  }
}

@media (min-width: 1440px) {
  .photo-and-text {
    display: flex;
    flex-flow: row nowrap;
  }

  .service-container {

    .body-service {
      .service-product-img {
        padding: 20px;
        padding-top: 10px;

        img {
          max-height: 100px;
        }
      }
    }
  }
}
</style>
