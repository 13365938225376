<template>
  <div class="custom-select-new">
    <div class="select-header" :class="{ dropdownOpenStyle: dropdownOpen }" @click="toggleDropdown">
      <input :placeholder="$t('customSelect_input_placeholder')" @click="this.dropdownOpen = false"
        v-model="searchQuery" />
      <font-awesome-icon class="dropdown-icon" :icon="['fas', dropdownOpen ? 'chevron-up' : 'chevron-down']" />
    </div>
    <div v-if="dropdownOpen" class="select-body">
      <div class="buttons-assign-deselect" v-if="selectedProducts.length > 0">
        <button class="deselect-btn" @click="deselectAll">
          {{ $t("customSelect_deselectBtn") }}
        </button>
        <button class="deselect-btn" style="background-color: var(--Green); color: white" @click="assignSelected">
          {{ $t("customSelect_assignSelected") }}
        </button>
      </div>
      <div v-if="filteredProducts.length > 0">
        <div v-for="product in filteredProducts" :key="product.serial_number" class="select-option"
          @click="selectProduct(product)" :class="{ selected: isSelected(product) }">
          <div class="custom-checkbox" style="margin-left: 30px">
            <input type="checkbox" :id="`checkbox-${product.serial_number}`"
              v-model="selectedCheckboxes[product.serial_number]" :class="{ 'selected-checkbox': isSelected(product) }"
              disabled hidden />
            <label :for="`checkbox-${product.serial_number}`"></label>
          </div>
          <!-- <img :src="product.image" :alt="product.model"> -->
          <img v-if="speakerPhotos[product.model]" class="display-none-on-mobile" :src="speakerPhotos[product.model]" />
          <img v-else src="@/assets/SpeakerImg.png" />
          <p class="flex-grow-1 display-none-on-mobile">{{ product.model }}</p>
          <p class="flex-grow-2" style="margin-right: 30px">
            {{ product.serial_number }}
          </p>
        </div>
      </div>
      <div v-else class="border-top">
        <p class="p-4 text-center">{{ $t("customSelect_borderTop_p") }}</p>
      </div>
    </div>
  </div>
  <div class="assigned-products-table" v-if="getTemporaryAssignedUserProducts &&
    getTemporaryAssignedUserProducts.length > 0
    ">
    <ListView :products="getTemporaryAssignedUserProducts" :tableFields="table_fields">
      <tr v-for="item in getTemporaryAssignedUserProducts" :key="item">
        <td v-for="{ value } in table_fields" :key="value">
          <div class="td-content">
            <!-- Conditionally render image before the model -->
            <img v-if="value === 'image'" src="@/assets/SpeakerImg.png" alt="Speaker Image" style="width: 50px"
              class="display-none-on-mobile" />
            <!-- Conditionally render actions -->
            <div v-else-if="value === 'actions'">
              <button class="btn-delete" type="button" style="
                  border: none;
                  margin-left: 50px;
                  background-color: transparent;
                " @click="deleteProduct(item.serial_number)">
                <font-awesome-icon :icon="['fass', 'xmark']" style="color: black" />
              </button>
              <!-- Add more buttons as needed -->
            </div>
            <!-- Render other fields -->
            <div v-else class="d-flex justify-content-center">
              {{ item[value] }}
            </div>
          </div>
        </td>
      </tr>
    </ListView>
  </div>
</template>

<script>
import ListView from "@/components/UI/DataListView.vue";
import fetchSpeakerPhotos from "@/api/getProductPhoto";

export default {
  name: "CustomSelect",
  components: {
    ListView,
  },
  data() {
    return {
      options: [],
      options_reserve: [],
      dropdownOpen: false,
      selectedProducts: [],
      selectedCheckboxes: {},
      assignedProducts: [],
      searchQuery: "",
      table_fields: [
        { label: "Model", value: "model" },
        { label: "Serial number", value: "serial_number" },
        { label: "Unassign", value: "actions" },
      ],
      speakerPhotos: {},
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectProduct(product) {
      const index = this.selectedProducts.findIndex(
        (selectedProduct) =>
          selectedProduct.serial_number === product.serial_number
      );

      if (index >= 0) {
        this.selectedProducts.splice(index, 1);
        this.selectedCheckboxes[product.serial_number] = false;
      } else {
        this.selectedProducts.push(product);
        this.selectedCheckboxes[product.serial_number] = true;
      }
    },
    isSelected(product) {
      return this.selectedProducts.some(
        (selectedProduct) =>
          selectedProduct.serial_number === product.serial_number
      );
    },
    assignSelected() {
      this.assignedProducts = [...this.selectedProducts];
      // Remove the selected products from the dropdown list
      this.options = this.options.filter(
        (option) =>
          !this.selectedProducts.some(
            (selectedProduct) =>
              selectedProduct.serial_number === option.serial_number
          )
      );
      // Adding assigned products to the vuex store
      this.$store.dispatch(
        "updateTemporaryAssignProducts",
        this.assignedProducts
      );
      this.dropdownOpen = false;
      this.selectedProducts = [];

      // Reset the selectedCheckboxes object
      for (let serial_number in this.selectedCheckboxes) {
        this.selectedCheckboxes[serial_number] = false;
      }
    },

    deselectAll() {
      // Add the selected products back to the dropdown list
      this.selectedProducts = [];
      for (let serial_number in this.selectedCheckboxes) {
        this.selectedCheckboxes[serial_number] = false;
      }
    },
    deleteProduct(serialNumber) {
      // Find the product in the assignedProducts array and add it back to the dropdown list
      const product = this.options_reserve.find(
        (product) => product.serial_number === serialNumber
      );
      if (product) {
        this.options.push(product);
      }
      this.$store.commit("deleteTemporaryAssignedProduct", serialNumber);
    },
    getUnassignedUserProducts() {
      this.options = [];
      this.options_reserve = [];
      const products = this.$store.getters.getUserProducts;
      for (let product of products) {
        if (product.room === null) {
          this.options.push(product);
        }
      }
      this.options_reserve = this.options;
    },
  },
  computed: {
    getTemporaryAssignedUserProducts() {
      return this.$store.state.temporaryAssignedProducts;
    },
    filteredProducts() {
      if (this.searchQuery) {
        return this.options.filter(
          (product) =>
            product.model
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            product.serial_number
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
        );
      }
      return this.options;
    },
  },
  mounted() {
    this.getUnassignedUserProducts();
  },
  async created() {
    this.speakerPhotos = await fetchSpeakerPhotos();
  },
};
</script>

<style scoped lang="scss">
.custom-select-new {
  width: 100%;

  display: flex;
  //align-items: center;
  flex-flow: column nowrap;
  //padding-right: 42px;
}

.checkbox-round {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #4caf50;
}

.select-header {
  border: 1px solid var(--Black);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  border-radius: 71px;
  padding-left: 42px;
  padding-right: 42px;

  p {
    margin-bottom: 0px;
  }

  input {
    width: 90%;
    height: 70%;
    border: none;
  }
}

.dropdownOpenStyle {
  border-bottom: none;
  border-radius: 32px 32px 0px 0px;
}

.select-body {
  border: 1px solid var(--Black);
  border-top: none;
  border-radius: 0px 0px 32px 32px;
  //   z-index: 1000; think about this! in case there is something under

  .deselect-btn {
    height: 27px;
    margin: 20px 20px 15px 20px;
  }
}

.select-option {
  display: flex;
  //justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid var(--Black);

  img {
    height: 52px;
    width: auto;
  }

  p {
    margin-bottom: 0px;
  }
}

.buttons-assign-deselect {
  display: flex;
  justify-content: space-between;
  //border-bottom: 1px solid var(--Black);
}

@media (max-width: 768px) {
  .buttons-assign-deselect {
    flex-flow: column wrap;
  }

  .select-option {
    p {
      margin: 13px;
      margin-left: 5px;
    }
  }
}
</style>
