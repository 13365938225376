<template>
  <div class="main-block">

    <div class="pre-register-page">
      <div class="headline">
        <h1>
          <b>{{ $t("QrRegistrationPage_headline_h1") }}</b>
        </h1>
        <p>
          <!-- {{ $t("productRegistration_headline_p_welcome") }} -->
          {{ $t("QrRegistrationPage_headline_welcomeText") }}

        </p>

        <!-- <span style="color: green">{{
          $t("QrRegistrationPage_headline_hint")
        }}</span> -->
      </div>
      <div class="products-input">
        <div class="input-and-warning-box">
          <input type="text" :placeholder="$t('QrRegistrationPage_headline_input_placeholder')" v-model="serial_number" />
          <h3 v-if="errorMessage" style="color: red">{{ errorMessage }}</h3>
        </div>
        <div class="button-box">
          <button class="btn" @click="addSerialNumber">
            {{ $t("QrRegistrationPage_headline_button_addSerial") }}
          </button>
        </div>
      </div>

      <div class="products" v-if="preRegisteredProducts.length > 0">
        <p>
          <strong>{{
            $t("QrRegistrationPage_products_p_followingProducts")
          }}</strong>
        </p>

        <table class="table table-borderless">
          <thead class="table-thead">
            <tr>
              <!-- <th width="50%" class="ps-0">
                {{ $t("productRegistration_products_table_thead_th1") }}
              </th> -->
              <th class="ps-0">
                {{ $t("productRegistration_products_table_thead_th2") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in preRegisteredProducts" :key="product.serial_number">
              <!-- <td>{{ product.model }}</td> -->
              <td>{{ product.serial_number }}</td>
              <td class="td-btn-delete">
                <button class="btn-delete" @click="deleteProduct(product.serial_number)">
                  <img src="@/assets/icons/delete-cropped.png" style="width: 24px" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="add-email" v-if="preRegisteredProducts.length > 0">
        <VForm @submit="addPregisteredProducts">
          <VField name="Email" v-slot="{ field, errors }" v-model="email" :rules="'required|email'">
            <!-- <label>Email address</label> -->
            <div class="input-and-error">
              <div class="input-and-span">
                <input type="text" v-model="user_email" v-bind="field" :placeholder="$t('QrRegistrationPage_products_input_placeholder_email')
                  " />
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </VField>
          <div class="buttons-div">
            <button type="submit" class="btn">
              <span>{{
                $t("QrRegistrationPage_products_addEmail_button_preRegister")
              }}</span>
            </button>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<script>
import { postPreRegisteredProducts } from "@/api/registerProducts";

export default {
  name: "QRregPage",
  data() {
    return {
      serialNumber_QR: null,
      serial_number: "",
      serialNumbers: [],
      errorMessage: null,
      user_email: "",
      user_product: [],
      user_serialNumber: [],
    };
  },
  methods: {
    addSerialNumber() {
      if (this.serial_number) {
        let storedSerialNumbers =
          JSON.parse(localStorage.getItem("serialNumbers")) || [];

        if (!storedSerialNumbers.includes(this.serial_number)) {
          storedSerialNumbers.push(this.serial_number);
          localStorage.setItem(
            "serialNumbers",
            JSON.stringify(storedSerialNumbers)
          );
          this.serialNumbers = storedSerialNumbers;
          this.errorMessage = null;
        } else {
          this.errorMessage = this.$t('QrRegistrationPage_methods_alert_alreadyExists');
        }
      }
    },
    deleteProduct(serialNumber) {
      this.serialNumbers = this.serialNumbers.filter(
        (sn) => sn !== serialNumber
      );
      localStorage.setItem("serialNumbers", JSON.stringify(this.serialNumbers));
    },
    addPregisteredProducts() {
      this.user_product = {
        email: this.user_email.toLowerCase(),
        serial_numbers: JSON.parse(JSON.stringify(this.serialNumbers)),
      };

      console.log(this.user_product);

      postPreRegisteredProducts(this.user_product)
        .then((response) => {
          // Clear the serial numbers from local storage upon successful API call
          if (response.customer_exists === true) {
            localStorage.removeItem("serialNumbers");
            this.serialNumbers = []; // Also clear the serialNumbers from the component's data
          } else {
            alert(this.$t('QrRegistrationPage_methods_alert_doesNotExistInMyGenelec'));
          }

        })
        .catch((error) => {
          console.error("Error while posting pre-registered products:", error);

          // Check for specific error codes and show alerts
          if (error.response && error.response.status === 422) {
            let failedSerialNumbers = [];

            for (const product of error.response.data.products) {
              if (product.status !== 'OK') {
                // Collect serial numbers that failed to be registered
                failedSerialNumbers.push(product.serial_number);
                console.log(product.error + " " + product.serial_number);
              }
            }

            console.log(this.serialNumbers)
            // Filter out the successful serial numbers, keeping only failed ones
            this.serialNumbers = this.serialNumbers.filter(serial => failedSerialNumbers.includes(serial));

            // Update localStorage with the new serialNumbers array
            localStorage.setItem("serialNumbers", JSON.stringify(this.serialNumbers));

            // Optionally, show a message if some products failed to be registered
            if (failedSerialNumbers.length > 0) {
              alert(this.$tc('QrRegistrationPage_methods_alert_someProductsFailed', failedSerialNumbers.length) + "" + failedSerialNumbers);
            }

          } else {
            // Handle other errors or show a generic error message
            alert("An error occurred while processing your request.");
          }
        });

    },
  },
  computed: {
    preRegisteredProducts() {
      return this.serialNumbers.map((sn) => ({
        serial_number: sn,
        // model: "4020C", // You can modify this as per your requirements
      }));
    },
  },
  created() {
    // Retrieve the serial number from the URL's query parameters
    this.serialNumber_QR = this.$route.query.sn;

    // Load serial numbers from local storage
    this.serialNumbers =
      JSON.parse(localStorage.getItem("serialNumbers")) || [];

    // If there are serial numbers in the URL path, split them and add to the list
    if (this.$route.params.serialNumbers) {
      const serialsFromPath = this.$route.params.serialNumbers.split(":");
      this.serialNumbers = [
        ...new Set([...this.serialNumbers, ...serialsFromPath]),
      ];
    }

    // If the scanned serial number is not in the list, add it
    if (
      this.serialNumber_QR &&
      !this.serialNumbers.includes(this.serialNumber_QR)
    ) {
      this.serialNumbers.push(this.serialNumber_QR);
    }

    // Store the updated serial numbers in local storage
    localStorage.setItem("serialNumbers", JSON.stringify(this.serialNumbers));
    // console.log("Latest version");
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  padding-bottom: 80px;
  height: calc(100vh - var(--UpperMenuHeight)) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pre-register-page {
  max-width: 800px;
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "headline headline headline headline"
    "products-input products-input products-input products-input"
    "products products products products"
    "form-additional-info form-additional-info form-additional-info form-additional-info"
    ". complete-registrations-buttons complete-registrations-buttons complete-registrations-buttons";

  .headline {
    grid-area: headline;

    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .btn {
    border-radius: 30px;
    background-color: var(--Green);
    color: var(--White);
    width: 30%;
    height: 58px;

    &:hover {
      background-color: var(--Green);
    }
  }

  .complete-registrations-buttons {
    grid-area: complete-registrations-buttons;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 50px;
    margin-bottom: 50px;

    button {
      width: 100%;
      margin-left: 30px;
    }

    .cancel-button {
      color: var(--Grey);
      background: none;
      border: 1px solid var(--Grey);
    }
  }

  .back-page {
    margin-bottom: 44px;
    grid-area: back-page;

    a {
      color: var(--Green);
    }
  }

  .products-input {
    grid-area: products-input;
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;

    .input-and-warning-box {
      width: 70%;

      input {
        border: none;
        border-radius: 30px;
        padding: 17px;
        width: 70%;
        margin-bottom: 19px;
        margin-right: 67px;
      }

      p {
        margin-left: 10px;
      }

      a {
        color: var(--Green);
      }
    }

    .button-box {
      width: 30%;

      button {
        width: 100%;
      }
    }
  }

  .products {
    grid-area: products;

    p {
      font-size: 21px;
      margin: 30px 0 30px 0px;
    }

    .table {
      th {
        background-color: var(--bg-grey);
      }

      tr,
      thead {
        border-bottom: 1px solid;

        background-color: var(--bg-grey);
      }

      td {
        padding: 15px 0 15px 0;
      }

      th {
        padding: 15px 0 15px 0;
      }

      .td-btn-delete {
        display: flex;
        justify-content: flex-end;

        button {

          border: none;
          margin-left: 50px;
          margin-right: 15px;
          background-color: var(--bg-grey);
        }
      }
    }
  }

  .add-email {
    margin-top: 30px;
    width: 100%;
    grid-area: form-additional-info;

    form {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    .input-and-error {
      display: flex;
      flex-flow: column nowrap;
      // align-items: center;
      width: 70%;

      //margin-right: 11px;
      .input-and-span {
        width: 70%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;

        input {
          width: 100%;
        }

        span {
          min-height: 25px;
          display: block;
          color: red;
          margin-left: 97px;
        }
      }




    }

    input {
      border: none;
      border-radius: 30px;
      padding: 17px;
      width: inherit;
      //margin-bottom: 19px;
      // margin-right: 67px;
    }

    .buttons-div {
      width: 30%;

      .btn {
        width: 100%;
        height: 58px;
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .pre-register-page {

    .products-input {
      justify-content: space-between;

      .input-and-warning-box {
        input {
          width: 331px;
        }

        h3 {
          text-align: center;
          margin-bottom: 19px;
        }
      }
    }

    .add-email {
      form {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .input-and-error {
          input {
            width: 331px;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .main-block {
    padding: 20px 30px 100px 30px;

  }

  .pre-register-page {
    margin: 0;

    .products-input {
      flex-flow: column wrap;
      // justify-content: space-between;

      .input-and-warning-box {
        width: 100%;

        input {
          width: 100%;
        }
      }

      .button-box {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }

    .add-email {
      form {
        display: flex;
        flex-flow: column wrap;

        // justify-content: space-between;
        div {
          width: 100%;
        }

        .input-and-error {
          width: 100%;

          .input-and-span {
            width: 100%;

            input {
              width: 100%;
              // padding: 0;
              margin: 0;
            }
          }


        }

        .buttons-div {
          width: 100%;

          .btn {
            width: 100%;
          }
        }

      }
    }
  }

}
</style>
