<template>
  <div class="main-block">
    <div class="software-page">
      <div class="headline">
        <h1>
          <b>{{ $t("software_headline_h2") }}</b>
        </h1>
        <p>{{ $t("software_headline_p_welcome") }}</p>
      </div>

      <div class="rec-products">
        <RecommendedSoftware :products="recProd"></RecommendedSoftware>
      </div>
      <!-- <div class="content-block">
        <DynamicContentBlock></DynamicContentBlock>
      </div> -->
      <div class="content-block">
        <DynamicShadowDomBlock :content="dynamicContent"></DynamicShadowDomBlock>
      </div>
    </div>

  </div>
</template>

<script>
import RecommendedSoftware from "@/components/RecommendedSoft.vue";
// import DynamicContentBlock from "@/components/DynamicHtmlBlock.vue";
import DynamicShadowDomBlock from "@/components/DynamicShadowDomBlock.vue";
import fetchDynamicContentData from "@/api/dynamicContent.js";

export default {
  name: "BetaSofwarePage",
  components: {
    RecommendedSoftware,
    // DynamicContentBlock,
    DynamicShadowDomBlock
  },
  data() {
    return {
      dynamicContent: ''
    };
  },
  computed: {
    recProd() {
      return [
        {
          id: 1,
          name: this.$t("softwarePage_betaAccess_heading"),
          text: this.$t("softwarePage_betaAccess_mainText"),
          link: "#",
          photo: "url",
          url: this.$t("beta_test"),
          betaAccess: true,
        },
      ];
    },
  },
  async created() {
    this.dynamicContent = await fetchDynamicContentData();
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: calc(100vh - var(--UpperMenuHeight));
  overflow-y: scroll;
}

.software-page {
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "headline"
    "rec-products"
    "DynamicHtml";

  .headline {
    grid-area: headline;

    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .toggle-buttons {
    grid-area: buttons-right;
    justify-self: end;
    margin-right: 15px;

    p {
      margin: 0;
    }

    button {
      border: none;
      font-size: 2.4rem;
      background-color: var(--bg-grey);
    }
  }

  .selected-view {
    color: var(--Green);
  }
}

.rec-products {
  grid-area: rec-products;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  //margin-top: 100px;
  p {
    padding-left: 17px;
  }
}

.software {
  grid-area: center-products;

  p {
    padding-top: 40px;
    padding-left: 17px;
  }

  :deep(.product_column) {
    padding: 0;

    .img-block {
      background-color: #dfebe9;
      display: flex;
      justify-content: center;
      border-radius: 10px 10px 0px 0px;

      img {
        width: 80%;
        height: auto;
        padding: 50px;
        //filter: contrast(100);
      }
    }
  }

  .product-info-block {
    padding: 20px 20px 30px 20px;

    a {
      color: var(--Green);
    }
  }

  .edit-buttons {
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    button {
      border-radius: 20px;
      border: 1px solid var(--Grey);
    }
  }
}

.content-block {
  grid-area: DynamicHtml;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .software-page {
    margin: 20px 30px 10px 30px;
  }
}
</style>
