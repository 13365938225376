import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";
import { jwtDecode } from "jwt-decode";
import store from "@/store";

async function getUserInfo(onFetchedCallback) {
  const accessToken = KeyCloakService.GetAccesToken();
  const decodedToken = jwtDecode(accessToken);
  store.commit("setAccessTokenInfo", decodedToken);
  const uuid = KeyCloakService.GetUUID();
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/users/${uuid}`;
  // console.log("UUID: ", uuid);
  return axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log("User data:", response.data);
      onFetchedCallback(response.data);
    })
    .catch((error) => {
      console.error("Error fetching user info:", error);
      throw error;
    });
}

export default getUserInfo;
