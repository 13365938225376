<template>
  <div class="overflow-hidden" v-if="!$store.state.ErrorWrongRealm">
    <LandingPage v-if="$store.state.landingPage"></LandingPage>
    <UserSelector v-else-if="$store.state.AccountSelectorModal"></UserSelector>
    <div v-else class="app container-fluid p-0 overflow-hidden">
      <VSpinner v-if="$store.getters.isLoading"></VSpinner>
      <div class="menu-container">

        <div class="upper-menu" style="height: var(--UpperMenuHeight)">
          <UpperMenu v-if="$store.getters.isAccessToken"></UpperMenu>
          <UpperMenuShort v-else></UpperMenuShort>
        </div>
        <SidebarMenu id="sidebar"></SidebarMenu>
        <RouterView id="content"></RouterView>
      </div>
    </div>
  </div>
  <ErrorPage v-else></ErrorPage>
</template>

<script>
import SidebarMenu from "./components/navigation/SidebarMenu.vue";
import UpperMenu from "@/components/navigation/UpperMenu.vue";
import UpperMenuShort from "@/components/navigation/UpperMenuShort.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import LandingPage from "@/views/LandingPage.vue";
import VSpinner from "@/components/UI/VSpinner.vue";
import UserSelector from "./components/UserSelector.vue";
import { Tooltip } from 'bootstrap'


export default {
  name: "App",
  components: {
    SidebarMenu,
    UpperMenu,
    ErrorPage,
    VSpinner,
    LandingPage,
    UserSelector,
    UpperMenuShort
  },
  data() {
    return {
      startX: null,
      startY: null,
      currentX: null,
      currentY: null,
      sidebarVisible: false,
      touchActionCompleted: false,
    };
  },
  methods: {
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      if (this.startX === null || this.startY === null) {
        return;
      }

      if (this.touchActionCompleted) {
        return; // Stop if action already performed for current touch
      }

      this.currentX = e.touches[0].clientX;
      this.currentY = e.touches[0].clientY;

      let diffX = this.currentX - this.startX;
      let diffY = this.currentY - this.startY;
      let revertdiffX = this.startX - this.currentX

      // Check for a significant horizontal swipe to open the sidebar
      if (Math.abs(diffX) > Math.abs(diffY) && diffX > 100) {
        this.$store.dispatch("toggleSidebar");
        this.touchActionCompleted = true;
      }

      if (this.$store.state.sidebarVisible) {

        if (revertdiffX > 100) {
          this.$store.dispatch("toggleSidebar");
          this.touchActionCompleted = true;

        }


      }
    },
    handleTouchEnd() {
      this.startX = null;
      this.startY = null;
      this.currentX = null;
      this.currentY = null;
      this.touchActionCompleted = false;
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    setAppVhProperty() {
      // Calculate the viewport height by taking the window's innerHeight and converting it to a value used for CSS
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--appVh', `${vh}px`);
    },

  },
  mounted() {
    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchend", this.handleTouchEnd, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })

  },

  beforeUnmount() {
    document.removeEventListener("touchstart", this.handleTouchStart);
    document.removeEventListener("touchend", this.handleTouchEnd);
    document.removeEventListener("touchmove", this.handleTouchMove);
  },
};
</script>

<style lang="scss">
:root {
  --primary: #4ade80;
  --primary-alt: #22c55e;
  --bg-grey: #f3f3f3;
  --dark: #1e293b;
  --dark-alt: #334155;
  --light: #f1f5f9;
  --sidebar-width: 200px;

  --light-green: #357855;
  --Green: #00865b;
  --White: #ffffff;
  --Black: #000000;
  --Dark-blue: #0e141b;
  --Grey-blue: #192534;
  --Beige: #e9e4d8;
  --Light-grey: #e8e8e8;
  --Pale-grey: #f3f3f3;
  --Grey: #7b7b7a;
  --dropDownSelectGrey: #d9d9d9;

  --UpperMenuHeight: 69px;
  --sidebar-width: 300px;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  background: var(--light);
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  overscroll-behavior-y: none;
  overflow: hidden;
}


.app {
  overscroll-behavior: none;
  overscroll-behavior-y: none;
  overflow: hidden;
  width: 100%;
  //height: 100vh;
  // height: calc(var(--appVh, 1vh) * 100);
}

.menu-container {
  overscroll-behavior: none;
  overscroll-behavior-y: none;
  overflow: hidden;
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-template-rows: var(--UpperMenuHeight) 1fr;
  //height: 100vh;
  // height: calc(var(--appVh, 1vh) * 100);
}

.upper-menu {
  overscroll-behavior: none;
  overscroll-behavior-y: none;
  grid-column: span 2;
  z-index: 200000;
}

#sidebar {
  overflow-y: auto;
  height: auto;
  overscroll-behavior: none;
  overscroll-behavior-y: none;
}

#content {
  height: calc(100dvh - var(--UpperMenuHeight)) !important;
  overflow-y: auto;
  height: auto;
  overscroll-behavior-y: none;
}

/* Responsive part */
@media (max-width: 1024px) {
  .menu-container {
    grid-template-columns: 1fr;
    grid-template-rows: var(--UpperMenuHeight) auto 1fr;
  }

  #sidebar {
    position: absolute;
    width: 100%;
    z-index: 100000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out 0s;
    margin-top: var(--UpperMenuHeight);
  }

  #sidebar.sidebar-visible {
    transform: translateX(0);
  }
}
</style>
