import axios from "axios";
import store from "@/store/index";
import KeyCloakService from "@/security/KeyCloakService.js";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    store.commit("incrementRequest");
    return config;
  },
  (error) => {
    store.commit("decrementRequest");
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.commit("decrementRequest");
    return response;
  },
  async (error) => {
    store.commit("decrementRequest");
    if (error.response?.status === 401) {
      await KeyCloakService.RefreshToken();
      const config = error.config;
      config.headers.Authorization = `Bearer ${KeyCloakService.GetAccesToken()}`;
      return axiosInstance(config);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
