import axiosInstance from "@/api/tokenRefresher";
import KeyCloakService from "@/security/KeyCloakService.js";

const putUpdateUserInfo = async (userData) => {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/users/${KeyCloakService.GetUUID()}`;
  try {
    const response = await axiosInstance.put(url, userData, {
      headers: {
        Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;

  } catch (error) {
    console.error('Failed to update user information: ', error);
    throw error;
  }
};

export default putUpdateUserInfo;
