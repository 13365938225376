<template>
  <div class="main-block">
    <VSpinner v-if="$store.getters.isLoading"></VSpinner>
    <div class="products-page">
      <div class="back-page">
        <font-awesome-icon :icon="['fasl', 'arrow-left']" style="color: var(--Green)" />
        <a href="#" @click="$router.push('/')">
          {{ $t("productRegistration_backPage_a_back") }}
          {{ $t("mainmenu_products") }}
        </a>
      </div>
      <div class="headline">
        <h1>
          <b>{{ $t("productRegistration_headline_h2") }}</b>
        </h1>
        <p>
          {{ $t("productRegistration_headline_p_welcome") }}
        </p>
      </div>
      <div class="products-input">
        <VForm @submit="addSerialtoTheTable()">
          <div class="input-and-button">
            <div class="input-and-warning-box">
              <VField name="serialNumber" v-slot="{ errors }" v-model="serial_number"
                :rules="'required|noLeadingSpace|lengthBetween:1,25'">
                <input type="text" :placeholder="$t('productRegistration_productsInput_placeholder_sn')"
                  v-model="serial_number" @input="this.errorMessage = null" />
                <span hidden>{{ (errorMessageVee = errors[0]) }}</span>
              </VField>
            </div>
            <div class="button-box">
              <button class="btn" type="submit" v-if="preRegisteredProducts.length <= 0">
                {{ $t("productRegistration_productsInput_buttonBox_button") }}
              </button>
              <button class="btn" type="submit" v-else>
                {{ $t("productRegistration_productsInput_buttonBox_button_addMore") }}
              </button>
              <!-- <div class="upload-excel">
                <input
                  type="file"
                  @change="handleExcelFile"
                  accept=".xlsx, .xls"
                />
              </div> -->
            </div>
          </div>

          <div v-if="this.errorMessage || errorMessageVee" class="errorBox">
            <p>{{ this.errorMessage || errorMessageVee }}</p>
          </div>
        </VForm>
      </div>

      <div class="products" v-if="preRegisteredProducts.length > 0">
        <p>
          <strong>{{ $t("productRegistration_products_p_strong") }}</strong>
        </p>

        <table class="table table-borderless">
          <thead class="table-thead">
            <tr>
              <th width="50%" class="ps-0">
                {{ $t("productRegistration_products_table_thead_th1") }}
              </th>
              <th class="ps-0">
                {{ $t("productRegistration_products_table_thead_th2") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in preRegisteredProducts" :key="product.serial_number">
              <td>{{ product.model }}</td>
              <td>{{ product.serial_number }}</td>

              <td class="td-btn-delete">
                <button class="btn-delete" @click="deleteProduct(product.serial_number)">
                  <!-- <font-awesome-icon :icon="['fass', 'xmark']" /> -->
                  <img src="@/assets/icons/delete-cropped.png" style="width: 24px" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-additional-info" v-if="preRegisteredProducts.length > 0">
        <VForm ref="formValid">

          <!-- <p v-if="!meta.valid">{{ meta }}</p> -->
          <!-- Date input with VField  -->
          <div class="form-item safari-input-date">
            <VField name="purchaseDate" v-slot="{ errors }" v-model="purchase_date"
              :rules="'required|noLeadingSpace|lengthBetween:1,15'">
              <label for="purchase_date">
                <b>{{ $t("productRegistration_products_additionalInfo_date") }}
                  <!-- <span style="color: red">*</span> -->
                </b>
              </label>
              <input type="date" id="purchase_date" v-model="purchase_date" :max="today" @change="validateDate" />
              <span style="color: red" class="text-center">{{
                errors[0]
              }}</span>
            </VField>
          </div>
          <!-- Place of Purchase -->
          <div class="form-item">
            <VField name="placeOfPurchase" v-slot="{ field, errors }" v-model="place_of_purchase"
              :rules="'required|noLeadingSpace|lengthBetween:1,50'">
              <label for="place_of_purchase">
                <b>{{ $t("productRegistration_products_additionalInfo_place")
                  }}
                  <!-- <span style="color: red">*</span> -->
                </b>
              </label>
              <input type="text" id="place_of_purchase"
                :placeholder="$t('productRegistration_products_placeholder_placeOfPurchase')" v-bind="field" />
              <span style="color: red" class="text-center">{{
                errors[0]
              }}</span>
            </VField>
          </div>
          <!-- Type of dealer -->
          <div class="form-item">
            <label for="type_of_place">
              <b>{{ $t("productRegistration_products_additionalInfo_dealer") }}
                <!-- <span style="color: red">*</span> -->

              </b>
            </label>

            <div class="custom-select-wrapper">
              <VField name="type_of_place" v-model="type_of_place" :rules="'required'" v-slot="{ field, errors }">
                <select v-bind="field" class="custom-select" id="type_of_place">
                  <option value="Local dealer shop" selected>
                    {{ $t("productRegistration_place_select_localDelaerShop") }}
                  </option>
                  <option value="Webshop">
                    {{ $t("productRegistration_place_select_webshop") }}
                  </option>
                  <option value="Installer">
                    {{ $t("productRegistration_place_select_installer") }}
                  </option>
                  <option value="Local distributor">
                    {{
                      $t("productRegistration_place_select_localDistributor")
                    }}
                  </option>
                  <option value="Other">
                    {{ $t("productRegistration_place_select_other") }}
                  </option>
                </select>
                <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                <span style="color: red" class="text-center d-block">{{
                  errors[0]
                }}</span>
              </VField>
            </div>
          </div>
          <!-- Uploading receipt -->
          <div class="form-item upload-block">
            <label for="upload_file">
              <b>{{ $t("productRegistration_products_additionalInfo_upload")
                }}
                <!-- <span style="color: red">*</span> -->
              </b>
            </label>
            <div class="custom-upload">

              <VField name="upload_file" ref="receiptInputProvider">
                <button type="button" class="upload-btn" @click="triggerFileInputSelect">
                  {{
                    $t(
                      "productRegistration_products_additionalInfo_upload_button"
                    )
                  }}
                </button>
                <div class="file-name-box">
                  <span class="file-name">
                    <!-- {{
                      $t(
                        "productRegistration_products_additionalInfo_upload_nofilechosen"
                      )
                    }} -->
                  </span>
                  <button v-if="deleteReceipt" class="btn-delete border-0 bg-transparent" @click="clearReceipt">
                    <img src="@/assets/icons/delete-cropped.png" style="width: 24px" />
                  </button>
                </div>

                <input type="file" @change="handleFileUpload" id="upload_file_select" ref="fileInputSelect"
                  style="display: none" />
                <span style="color: red" class="text-center" v-if="errorMissingFile">{{
                  $t("error_required")
                }}</span>

              </VField>
            </div>
          </div>
          <!-- Location -->
          <div class="form-item">
            <label for="location"><b>{{
              $t("productRegistration_products_additionalInfo_location")
            }}
              </b></label>
            <div class="custom-select-wrapper">
              <VField name="selectedLocation" v-model="selectedLocation" v-slot="{ field, errors }">
                <select class="custom-select" id="location" v-model="selectedLocation"
                  @change="getRooms(selectedLocation)" v-bind="field">
                  <option :value="null">
                    {{
                      $t(
                        "productRegistration_products_additionalInfo_location_none"
                      )
                    }}
                  </option>
                  <option v-for="location in locations" :value="location.id" :key="location.id">
                    {{ location.name }}
                  </option>
                </select>
                <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                <span style="color: red" class="text-center">{{
                  errors[0]
                }}</span>
              </VField>
            </div>
          </div>
          <!-- Room -->
          <div class="form-item" v-if="selectedLocation">
            <label for="room" @click.stop><b>{{ $t("productRegistration_products_additionalInfo_room") }}
                <!-- <span style="color: red">*</span> -->
              </b></label>
            <div class="custom-select-wrapper">
              <VField name="room" v-slot="{ field, errors }" v-model="room" :rules="selectedLocation ? 'required' : ''">
                <select v-bind="field" class="custom-select" id="room" v-model="room" required>
                  <option v-for="room in rooms" :value="room.id" :key="room.name">
                    {{ room.name }}
                  </option>
                </select>
                <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                <span style="color: red" class="text-center d-block">{{
                  errors[0]
                }}</span>
              </VField>
            </div>
          </div>
          <!-- Complete buttons -->
          <div class="complete-registrations-buttons" v-if="preRegisteredProducts.length > 0">
            <button class="btn" type="button" @click="manualSubmit">
              {{
                $t(
                  "productRegistration_products_completeRegistration_button_complete"
                )
              }}
            </button>
            <button class="btn cancel-button" @click="clearData()">
              {{
                $t(
                  "productRegistration_products_completeRegistration_button_cancel"
                )
              }}
            </button>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<script>
import {
  checkProduct,
  addProductToUserAccount,
  fetchPreRegisteredProducts,
  checkProductForExcelAndRegister,
} from "@/api/registerProducts.js";
import VSpinner from "@/components/UI/VSpinner.vue";
import store from "@/store";
import getUserProducts from "@/api/getUserProducts.js";
import { getLocations, getRooms } from "@/api/addingRooms.js";
import { addReceiptToTheProduct } from "@/api/addReceipt.js";
import {
  readExcelFile,
  extractDataFromWorkbook,
} from "@/excel/excelFunctions.js";

// import { useForm, useField } from 'vee-validate';
// import { watch, ref } from 'vue';

export default {
  name: "ProductRegistration",
  components: {
    VSpinner: VSpinner,
  },


  data() {
    return {
      serial_number: null,
      purchase_date: null,
      place_of_purchase: null,
      type_of_place: null,
      isFormActivated: false,
      locations: [],
      rooms: [],
      selectedLocation: null,
      room: null,
      errorMessage: "",
      uploadedFile: null,
      serialNumbers: [],
      file: null,
      today: new Date().toISOString().slice(0, 10),
      formData: [],
      errorMessageVee: "",
      deleteReceipt: false,
      errorMissingFile: false,
      maxLengthOfTheReceiptName: 15,
      fileName: "",
      quickResponse: null,
    };
  },

  methods: {
    isProductAlreadyAdded(serial_number) {
      return store.getters.getPreRegisteredProducts.some(
        (product) => product.serial_number === serial_number
      );
    },
    addSerialtoTheTable() {
      this.errorMessage = "";
      if (!this.isProductAlreadyAdded(this.serial_number)) {
        try {
          checkProduct(this.serial_number, (message) => {
            this.errorMessage = message;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.errorMessage = this.$t("productRegistration_methods_errorMessage_preRegisterList");
      }
    },
    async fetchLocations() {
      try {
        this.locations = await getLocations();
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    },
    async getRooms(locationId) {
      try {
        this.rooms = await getRooms(locationId);
      } catch (error) {
        console.error("Failed to fetch rooms:", error);
      }
    },
    updateProductInfo() {
      this.isFormActivated = true;
      store.getters.getPreRegisteredProducts.forEach((product, index) => {
        const updatedProduct = {
          ...product,
          purchase_date: this.purchase_date,
          place_of_purchase: this.place_of_purchase,
          type_of_place: this.type_of_place,
          room: this.room,
        };
        store.commit("updatePreRegisteredProduct", {
          index,
          product: updatedProduct,
        });
      });
    },
    clearData() {
      store.dispatch("clearAllPreRegisteredProducts");
      this.purchase_date = null;
      this.place_of_purchase = null;
      this.type_of_place = null;
      this.selectedLocation = null;
      this.room = null;
      this.file = null;
      this.deleteReceipt = false;
    },
    async completeProductRegistration() {
  try {
    if (this.file) {
      this.errorMissingFile = false;
      await this.updateProductInfo();

      const response = await addProductToUserAccount();

      // Separate successful and failed products
      const successfulProducts = response.successfulProducts || [];
      const failedProducts = response.failedProducts || [];

      // Update the list of pre-registered products to only include successful ones
      if (successfulProducts.length > 0) {
        const successfulSerialNumbers = successfulProducts.map(product => product.serial_number);
        store.commit('setPreRegisteredProducts', store.getters.getPreRegisteredProducts.filter(
          product => successfulSerialNumbers.includes(product.serial_number)
        ));

        // Handle successful products
        this.quickResponse = successfulProducts;

        const successMessageCustom = successfulProducts.map((product, index) =>
          `${this.$t('productRegistration_methods_alert_successfullyRegisteredV1', { index: index + 1, serial_number: product.serial_number })}`
        ).join('\n');
        alert(`${this.$t('productRegistration_methods_alert_successfullyRegisteredV2')}\n\n${successMessageCustom}`);

        // Add receipts for successful products
        await this.addReceipt(successfulProducts); // Pass successful products to addReceipt
        this.isFormActivated = false;
        this.clearData();
        await getUserProducts();
      }

      // Handle failed products
      if (failedProducts.length > 0) {
        const productErrors = failedProducts.map((product, index) =>
          `Product ${index + 1}: ${product.error} (Serial Number: ${product.serial_number})`
        );

        const errorMessageCustom = this.$t('errorpage_text') + `\n\n${productErrors.join('\n')}`;
        alert(errorMessageCustom);
      }
    } else {
      this.errorMissingFile = true;
    }
  } catch (error) {
    let errorMessageCustom = this.$t('errorpage_text'); // Default error message

    if (error.response && error.response.data && error.response.data.products) {
      const productErrors = error.response.data.products.map((product, index) =>
        `Product ${index + 1}: ${product.error} (Serial Number: ${product.serial_number})`
      );
      errorMessageCustom += `\n\n${productErrors.join('\n')}`;
    }
    alert(errorMessageCustom);
  }
},









    async addReceipt() {
      store.getters.getPreRegisteredProducts.forEach((product) => {
        this.serialNumbers.push(product.serial_number);
        // console.log("File being sent:", this.file);
        addReceiptToTheProduct(product.serial_number, this.file);
      });
    },

    deleteProduct(serialNumber) {
      store.dispatch("deleteProductFromPreRegistered", serialNumber);
    },
    validateDate() {
      const selectedDate = new Date(this.purchase_date);
      const todayDate = new Date(this.today);

      if (selectedDate > todayDate) {
        this.purchase_date = this.today;
        alert(this.$t("productRegistration_methods_alert_selectedDate")); // Optionally, alert the user
      }
    },
    triggerFileInputSelect() {
      this.$refs.fileInputSelect.click();
    },
    triggerFileInputCapture() {
      this.$refs.fileInputCapture.click();
    },

    async manualSubmit() {

      if (!this.file) {
        this.errorMissingFile = true;
      }
      const isValid = await this.$refs.formValid.validate();


      if (isValid.valid && !this.errorMissingFile) {
        this.completeProductRegistration();
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.file) {
        document.querySelector(".file-name").textContent = this.$t(
          "productRegistration_products_additionalInfo_upload_nofilechosen"
        );
        this.deleteReceipt = false;
        this.errorMissingFile = true;
        return;
      }

      // Check for file size (5 MB = 5 * 1024 * 1024 bytes)
      if (this.file.size > 5 * 1024 * 1024) {
        alert(this.$t("productRegistration_methods_alert_fileTooLarge"));
        event.target.value = ""; // Reset the input
        this.errorMissingFile = true;
        return;
      }

      // Check for file format
      const validImageFormats = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "application/pdf",
      ];
      if (!validImageFormats.includes(this.file.type)) {
        alert(
          this.$t("productRegistration_methods_alert_uploadFormat")
        );
        event.target.value = ""; // Reset the input
        this.errorMissingFile = true;
        return;
      }

      // Update the file name display
      const fileNameIndicator = document.querySelector(".file-name");
      this.deleteReceipt = true;
      this.errorMissingFile = false;
      if (this.file.name.length > this.maxLengthOfTheReceiptName) {
        this.fileName = this.file.name.substring(0, this.maxLengthOfTheReceiptName) + "..."; // Truncate and add ellipsis
        fileNameIndicator.textContent = this.fileName;
      } else {
        fileNameIndicator.textContent = this.file.name;
      }


    },
    clearReceipt() {
      this.deleteReceipt = false;
      this.file = null;
      document.querySelector(".file-name").textContent = this.$t(
        "productRegistration_products_additionalInfo_upload_nofilechosen"
      );
    },
    async addPregisteredProducts() {
      // Fetch pre-registered products
      const data = await fetchPreRegisteredProducts();
      // console.log(data);

      // Check each unique serial number
      for (let serialNumber of data.pending_requests) {
        if (!this.isProductAlreadyAdded(serialNumber)) {
          try {
            await checkProduct(serialNumber);
          }
          catch (error) {
            console.error("Error checking product:", error);
          }
        }
      }
    },
    async handleExcelFile(event) {
      const file = event.target.files[0];
      try {
        const workbook = await readExcelFile(file);
        const extractedDataArray = await extractDataFromWorkbook(workbook);
        const errorMessages = [];

        for (const productData of extractedDataArray) {
          try {
            productData.room = null; // Adding room field with null value to productData
            await checkProductForExcelAndRegister(productData, (message) => {
              errorMessages.push(message);
            });
          } catch (error) {
            // Handle errors, possibly by adding to errorMessages
            console.log(error.message);
            errorMessages.push(error.message); // Use the function to set the error message
          }
        }

        if (errorMessages.length > 0) {
          this.errorMessage = errorMessages.join(", ");
          // Use this.errorMessage in your template to display errors
        }
      } catch (error) {
        console.error("Error reading the Excel file:", error);
      }
    },
  },
  computed: {
    preRegisteredProducts() {
      return store.getters.getPreRegisteredProducts;
    },
    isFormIncomplete() {
      return (
        !this.purchase_date ||
        !this.place_of_purchase ||
        !this.type_of_place ||
        !this.file ||
        (this.selectedLocation && !this.room)
      );
    },
    isCompleteRegistrationDisabled() {
      return this.isFormIncomplete && this.isFormActivated;
    },
    isLocationSelectedButNoRoom() {
      return this.selectedLocation && !this.room;
    },
  },
  created() {
    this.fetchLocations();
    this.addPregisteredProducts(); // add pre-registered products to the table
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  padding-bottom: 80px;
  height: calc(100vh - var(--UpperMenuHeight)) !important;
  overflow-y: scroll;
}

.products-page {
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "back-page back-page . ."
    "headline headline headline headline"
    "products-input products-input products-input products-input"
    "products products products products"
    "form-additional-info form-additional-info form-additional-info form-additional-info"
    ". complete-registrations-buttons complete-registrations-buttons complete-registrations-buttons";

  .headline {
    grid-area: headline;

    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .btn {
    border-radius: 30px;
    background-color: var(--Green);
    color: var(--White);
    width: 30%;
    height: 58px;

    &:hover {
      background-color: var(--Green);
    }
  }

  .complete-registrations-buttons {
    // max-width: 800px;
    width: 100%;
    grid-area: complete-registrations-buttons;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 50px;
    margin-bottom: 50px;

    button {
      width: 100%;
      max-width: 385px;
    }

    .cancel-button {
      margin-left: 30px;
    }

    .cancel-button {
      color: var(--Grey);
      background: none;
      border: 1px solid var(--Grey);
    }
  }

  .back-page {
    margin-bottom: 44px;
    grid-area: back-page;

    a {
      color: var(--Green);
    }
  }

  .products-input {
    grid-area: products-input;
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;

    form {
      width: 100%;
      max-width: 800px;

      .input-and-button {
        //d-flex flex-row flex-nowrap w-100
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
      }

      .input-and-warning-box {
        width: 70%;

        input {
          border: none;
          border-radius: 30px;
          padding: 17px;
          width: 90%;
          margin-bottom: 19px;
          // margin-right: 67px;
        }

        p {
          margin-left: 10px;
        }

        a {
          color: var(--Green);
        }
      }

      .button-box {
        button {
          width: 277px;
        }
      }
    }
  }

  .products {
    grid-area: products;

    p {
      font-size: 21px;
      margin: 30px 0 30px 25px;
    }

    .table {
      th {
        background-color: var(--bg-grey);
      }

      td {
        border-bottom: 1px solid;
        padding: 15px 0 15px 0;
        background-color: var(--bg-grey);
      }

      .td-btn-delete {
        button {
          border: none;
          margin-left: 50px;
          background-color: var(--bg-grey);
        }
      }
    }
  }

  .form-additional-info {
    margin-top: 50px;
    grid-area: form-additional-info;
    width: 100%;

    form {
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      .form-item {
        display: flex;
        flex-flow: column nowrap;
        width: 25%;
        min-width: 256px;
        max-width: 257px;
        margin-right: 77px;
        margin-top: 50px;

        input,
        select {
          border: none;
          border-radius: 30px;
          padding: 15px;
          padding-left: 30px;
          min-width: 256px;
          align-self: center;
        }

        p {
          margin-left: 10px;
        }

        label {
          padding-left: 30px;
          padding-bottom: 10px;
        }
      }

      .safari-input-date {
        width: 100%;

        input {
          margin: 0 !important;
          height: 54px;
          min-width: 95% !important;
        }
      }
    }

    .custom-select-wrapper {
      position: relative;
      display: inline-block;
      width: 100%;
      align-self: center;

      select,
      input,
      ul,
      li,
      option {
        cursor: pointer;
      }
    }

    .custom-select-icon {
      position: absolute;
      //top: 21%;
      top: 26px;
      right: 20px;
      // pointer-events: none;
      // transform: translateY(-50%);
    }

    .custom-select {
      appearance: none;
      -webkit-appearance: none;
      /* For Chrome and Safari */
      -moz-appearance: none;
      /* For Firefox */
    }
  }
}

.errorBox {
  color: red;
  margin-left: 18px;
}

@media screen and (max-width: 1024px) {
  .products-page {
    grid-template-areas:
      "back-page . . ."
      "headline headline headline headline"
      "products-input products-input products-input products-input"
      "products products products products"
      "form-additional-info form-additional-info form-additional-info form-additional-info"
      "complete-registrations-buttons complete-registrations-buttons complete-registrations-buttons complete-registrations-buttons";

    .products-input {
      form {
        .input-and-warning-box {
          input {
            width: 331px;
          }

          h3 {
            text-align: center;
            margin-bottom: 19px;
          }
        }
      }
    }

    .complete-registrations-buttons {
      grid-area: complete-registrations-buttons;
      display: flex;
      flex-flow: column wrap;
      margin-top: 50px;
      margin-bottom: 25px;

      button {
        max-width: initial;
        width: 100%;
        margin-bottom: 41px;
        margin-left: 0px;
      }

      .cancel-button {
        margin-left: 0px;
      }
    }

    .form-additional-info {
      form {
        align-items: flex-start;
        justify-content: center;

        .form-item {
          margin-top: 19px;
          margin-right: 20px;
          margin-left: 20px;
          width: 50%;

          input,
          select {
            cursor: pointer;
            border: none;
            border-radius: 30px;
            padding: 15px;
            padding-left: 30px;
            min-width: 256px;
            align-self: center;
          }

          p {
            margin-left: 10px;
          }

          label {
            padding-left: 30px;
            padding-bottom: 10px;
          }
        }
      }

      .custom-select-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        align-self: center;
      }
    }
  }
}

@media screen and (max-width: 754px) {
  .products-page {
    grid-template-areas:
      "back-page back-page . ."
      "headline headline headline headline"
      "products-input products-input products-input products-input"
      "products products products products"
      "form-additional-info form-additional-info form-additional-info form-additional-info"
      "complete-registrations-buttons complete-registrations-buttons complete-registrations-buttons complete-registrations-buttons";
    margin: 20px 15px 10px 15px;

    .products-input {
      flex-flow: column wrap;

      form {
        .input-and-button {
          flex-flow: column wrap;

          .input-and-warning-box {
            width: 100%;

            input {
              padding: 15px;
              padding-left: 30px;
              width: 100%;
              margin-right: 0px;
            }
          }

          .button-box {
            button {
              width: 100%;
            }
          }
        }
      }
    }

    .complete-registrations-buttons {
      button {
        width: 100%;
        margin-bottom: 25px;
        margin-left: 0px;
      }

      .cancel-button {
        margin-left: 0;
      }
    }

    .form-additional-info {
      form {
        align-items: center;
        justify-content: center;

        .form-item {
          margin: 0px;
          margin-top: 19px;
          width: 100%;
          max-width: none;

          input,
          select {
            border: none;
            border-radius: 30px;
            padding: 15px;
            padding-left: 30px;
            min-width: 256px;
            align-self: center;
            width: 100%;
          }

          p {
            margin-left: 10px;
          }

          label {
            padding-left: 30px;
            padding-bottom: 10px;
          }
        }
      }

      .custom-select-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        align-self: center;
      }
    }
  }

  .errorBox {
    padding: 15px 0px;

    p {
      text-align: center;
    }

  }
}
</style>
