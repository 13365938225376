<template>
    <div class="product-page">
        <ProductList></ProductList>
    </div>
</template>

<script>

import ProductList from "@/components/ProductList.vue"

export default {
    name: "ProductPage",
    components: {
            ProductList
        }
}
</script>

<style lang="scss" scoped>
.product-page {
    background-color: var(--bg-grey);
        margin: 0px;
        padding: 0px;
        height: calc(100vh - var(--UpperMenuHeight));
        overflow-y: scroll;
}
</style>