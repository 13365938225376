<template>
  <div class="main-block">
    <div class="rooms-page">
      <VSpinner v-if="$store.state.isLoadingLocaly"></VSpinner>
      <div class="headline">
        <div>
          <h1>
            <b>{{ $t("roomsPage_headline_h2") }}</b>
          </h1>
          <p>{{ $t("rooms_headline_p_welcome") }}</p>
        </div>
      </div>
      <div class="btn-add-location">
        <button class="btn" @click="addNewLocation">
          {{ $t("roomsPage_btnAddLocation_button") }}
        </button>
      </div>
      <!-- <div class="search-bar">
        <input
          :placeholder="$t('roomPage_searchBar_placeholder')"
          v-model="searchQuery"
          disabled
        />
      </div> -->

      <div class="rooms">
        <table id="tableComponent" class="table table-bordereless">
          <thead class="table-thead">
            <tr class="position-relative">
              <!-- loop through each value of the fields to get the table header -->
              <th width="70%" style="text-align: start">
                {{ $t("roomsPage_rooms_thead_th1") }}
              </th>
              <th>{{ $t("roomsPage_rooms_thead_th2") }}</th>
              <th class="display-none-on-mobile">
                {{ $t("roomsPage_rooms_thead_th3") }}
              </th>
              <th class="display-none-on-mobile">
                {{ $t("roomsPage_rooms_thead_th4") }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(location, index) in locations" :key="index">
            <tr>
              <td class="position-relative">
                <h2 class="accordion-header" :id="`flush-heading${location.id}`">
                  <button class="custom-accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="`#flush-collapse${location.id}`" :aria-expanded="location.isOpen"
                    :aria-controls="`flush-collapse${location.id}`" @click="toggleUserInformation(location)">
                    <h4 class="text-break text-body">
                      {{ location.name }}
                    </h4>
                    <font-awesome-icon class="chevron-icon-center" :icon="['fas', 'chevron-right']" :style="{
                      transform: `rotate(${location.accountIconRotation}deg)`,
                      transition: 'transform 0.3s ease-in-out',
                      color: 'black',
                    }" />
                  </button>
                </h2>
              </td>
              <td class="position-relative">
                <div class="room-number position-absolute top-50 start-50 translate-middle">
                  <p class="fs-4 m-0">{{ getRoomCount(location.id) }}</p>
                </div>
              </td>
              <td class="position-relative">
                <button class="edit-location position-absolute top-50 start-50 translate-middle"
                  @click="openModal(location)">
                  <img src="@/assets/icons/edit.png" />
                </button>
              </td>
              <td class="position-relative">
                <button class="delete-location position-absolute top-50 start-50 translate-middle"
                  @click="openDeleteModal(location)">
                  <img src="@/assets/icons/delete.png" />
                </button>
              </td>
            </tr>
            <tr class="rooms-info">
              <td colspan="4">
                <div class="accordion accordion-flush" id="accordionFlushExampleOne">
                  <div class="accordion-item">
                    <div :ref="`locationAccordion${location.id}`" :id="`flush-collapse${location.id}`"
                      :class="`accordion-collapse collapse ${location.showLocation}`"
                      :aria-labelledby="`flush-heading${location.id}`" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="mob-location-control display-none-on-desktop">
                          <div class="mob-edit-delete">
                            <button @click="openModal(location)">
                              <img src="@/assets/icons/edit.png" />
                              <p>
                                {{
                                  $t("roomsPage_mob_rooms_mobLocationControl")
                                }}
                              </p>
                            </button>
                            <button @click="openDeleteModal(location)">
                              <img src="@/assets/icons/delete.png" />
                              <p>
                                {{
                                  $t("roomsPage_mob_rooms_mobLocationControl")
                                }}
                              </p>
                            </button>
                          </div>
                          <div class="mob-create-new-location">
                            <button @click="addNewRoom(location.id)">
                              {{ $t("roomsPage_mob_rooms_mobLocationControl") }}
                            </button>
                          </div>
                        </div>
                        <DataGridView :products="rooms[location.id] || []" @add-new-room="addNewRoom(location.id)"
                          :showAddNewRoom="true">
                          <template #default="{ product: room }">
                            <div style="cursor: pointer">
                              <div @click="
                                $router.push(
                                  `/location/${location.id}/room/${room.id}`
                                )
                                ">
                                <div class="img-block">
                                  <img src="@/assets/roomPic.svg" />
                                </div>
                                <div class="product-info-block">
                                  <div>
                                    <h3 class="text-break">{{ room.name }}</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="edit-buttons">
                              <button @click="openEditModal(room)">
                                <img src="@/assets/icons/edit-cropped.png" />
                              </button>
                              <button @click="openDeleteModal(location, room)">
                                <img src="@/assets/icons/delete-cropped.png" />
                              </button>
                            </div>
                          </template>
                        </DataGridView>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="deletemodal" v-if="showDeleteModal" @click="closeDeleteModal">
      <div class="content" @click.stop>
        <h1 v-if="showDeleteRoom">
          {{ $t("roomsPage_deletemodal_h1_2vers") }}
        </h1>
        <h1 v-else>{{ $t("roomsPage_deletemodal_h1") }}</h1>
        <p v-if="showDeleteRoom">
          {{ $t("roomsPage_deletemodal_p1") }} "{{
            roomToDelete.name
          }}"?<br />{{ $t("roomsPage_deletemodal_p2") }}
        </p>
        <p v-else>
          {{ $t("roomsPage_deletemodal_p1") }} "{{
            locationToDelete.name
          }}"?<br />{{ $t("roomsPage_deletemodal_p2") }}
        </p>
        <div class="buttons-div">
          <button type="button" class="save-info-button" @click="deleteRoomLocally" v-if="showDeleteRoom"
            :disabled="$store.state.errorStatus">
            {{ $t("button_delete") }}
          </button>
          <button type="button" class="save-info-button" @click="confirmDeleteLocation" v-else
            :disabled="$store.state.errorStatus">
            {{ $t("button_delete") }}
          </button>

          <button type="button" class="cancel-info-button" @click="closeDeleteModal">
            {{ $t("button_cancel") }}
          </button>
        </div>
        <div class="modal-error-block" v-if="$store.state.errorStatus">
          <div class="error">
            <p>
              {{ $t("errorpage_text") }}
            </p>
            <div class="error-context">
              <p>{{ $store.state.errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModal" @click="closeModal">
      <div class="content">
        <VForm @submit="submitForm">
          <div class="header-and-close">
            <h1 v-if="isEdit">
              {{ $t("roomsPage_modal_headerAndClose_h1_edit") }}
            </h1>
            <h1 v-else>{{ $t("roomsPage_modal_headerAndClose_h1_create") }}</h1>
            <span class="close" @click="closeModal">x</span>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Location name" :rules="'required|noLeadingSpace|lengthBetween:1,30'"
              v-slot="{ field, errors }" v-model="formData.name">
              <b><label>{{ $t("roomsPage_modal_locationName") }}</label></b>
              <div class="input-and-error">
                <input type="text" v-model="formData.name" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Street" v-slot="{ field, errors }" v-model="formData.street_address1">
              <b>
                <label>{{ $t("roomsPage_modal_street") }}</label></b>
              <div class="input-and-error">
                <input type="text" v-model="formData.street_address1" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Street 2" v-slot="{ field, errors }" v-model="formData.street_address2">
              <b>
                <label>{{ $t("roomsPage_modal_street") }} 2</label></b>
              <div class="input-and-error">
                <input type="text" v-model="formData.street_address2" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Postal code" v-slot="{ field, errors }" v-model="formData.postal_code">
              <b>
                <label>{{ $t("roomsPage_modal_postal") }}</label></b>
              <div class="input-and-error">
                <input type="text" v-model="formData.postal_code" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>
          <div class="custom-input-wrapper">
            <VField name="City" v-slot="{ field, errors }" v-model="formData.city">
              <b>
                <label>{{ $t("roomsPage_modal_city") }}</label></b>
              <div class="input-and-error">
                <input type="text" v-model="formData.city" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Province/State" v-slot="{ field, errors }" v-model="formData.province_state">
              <b><label>{{ $t("roomsPage_modal_state") }}</label></b>
              <div class="input-and-error">
                <input type="text" v-model="formData.province_state" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <div class="custom-select-wrapper">
            <VField name="Country" v-slot="{ field, errors }" v-model="formData.country_code" rules="required">
              <b>
                <label>{{ $t("profile_p_country") }}</label></b>

              <!-- solve the problem with the countries -->
              <div class="d-flex flex-column country-div">
                <!-- width: 71% -->
                <div class="custom-select-required">
                  <select class="custom-select" v-model="formData.country_code" v-bind="field">
                    <option v-for="country in countries" :value="country.Key" :key="country.Key">
                      {{ country.en }}
                    </option>
                  </select>
                  <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />
                </div>
                <div class="w-100">
                  <span style="
                      height: 25px;
                      display: block;
                      color: red;
                      text-align: center;
                    ">{{ errors[0] }}</span>
                </div>
              </div>
            </VField>
          </div>

          <div class="buttons-div">
            <button type="submit" class="save-info-button" :disabled="$store.state.errorStatus">
              <span v-if="isEdit">{{
                $t("roomsPage_modal_buttons_div_save")
              }}</span>
              <span v-else>{{ $t("roomsPage_modal_buttons_div_create") }}</span>
            </button>
            <button type="button" class="cancel-info-button" @click="closeModal">
              {{ $t("button_cancel") }}
            </button>
          </div>
          <div class="modal-error-block" v-if="$store.state.errorStatus">
            <div class="error">
              <p>
                {{ $t("errorpage_text") }}
              </p>
              <div class="error-context">
                <p>{{ $store.state.errorMessage }}</p>
              </div>
            </div>
          </div>
          <!-- <CustomSelect :options="unassignedProducts"></CustomSelect> -->
        </VForm>
      </div>
    </div>

    <div class="modal" v-if="showModalCreateRoom" @click="closeModal">
      <div class="content">
        <VForm @submit="createRoom">
          <div class="header-and-close">
            <h1 v-if="isRoomEdit">
              {{ $t("roomsPage_modal_createRoom_edit") }}
              {{ formCreateRoom.name }}
            </h1>
            <h1 v-else>
              {{ $t("roomsPage_modal_createRoom_headerAndClose") }}
            </h1>
            <span class="close" @click="closeModal">x</span>
          </div>
          <div class="custom-input-wrapper">
            <VField name="Room name" :rules="'required|noLeadingSpace|lengthBetween:1,30'" v-slot="{ field, errors }"
              v-model="formCreateRoom.name">
              <label>{{ $t("roomsPage_modal_createRoom_roomName") }}</label>
              <div class="input-and-error">
                <input type="text" v-model="formCreateRoom.name" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <div class="custom-select-wrapper">
            <VField name="Room type" rules="required" v-slot="{ field, errors }" v-model="formCreateRoom.type">
              <label>{{ $t("roomsPage_modal_createRoom_roomType") }}</label>

              <div class="select-and-error">
                <select class="custom-select" v-model="formCreateRoom.type" v-bind="field">
                  <option value="room-type-not-selected-key" selected>
                    {{ $t("roomsPage_modal_createRoom_roomType_not") }}
                  </option>
                  <option value="room-studio-key">
                    {{ $t("roomsPage_modal_createRoom_roomType_studio") }}
                  </option>
                  <option value="room-home-studio-key">
                    {{ $t("roomsPage_modal_createRoom_roomType_home") }}
                  </option>
                  <option value="room-mixed-key">
                    {{ $t("roomsPage_modal_createRoom_roomType_mixed") }}
                  </option>
                  <option value="room-recreational-key">
                    {{ $t("roomsPage_modal_createRoom_roomType_recreational") }}
                  </option>
                  <option value="room-public-space-key">
                    {{ $t("roomsPage_modal_createRoom_roomType_public") }}
                  </option>
                  <option value="room-research-key">
                    {{ $t("roomsPage_modal_createRoom_roomType_research") }}
                  </option>
                </select>
                <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" />

                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <div class="custom-input-wrapper">
            <VField name="Notes" v-slot="{ field, errors }" v-model="formCreateRoom.description">
              <label>{{ $t("roomsPage_modal_createRoom_notes") }}</label>
              <div class="input-and-error">
                <input type="text" v-model="formCreateRoom.description" v-bind="field" />
                <span>{{ errors[0] }}</span>
              </div>
            </VField>
          </div>

          <CustomSelect v-if="!isRoomEdit"></CustomSelect>

          <div class="buttons-div">
            <button type="submit" class="save-info-button" :disabled="$store.state.errorStatus">
              <span v-if="isRoomEdit">{{
                $t("roomsPage_modal_buttonsDiv_isRoomEdit")
              }}</span>
              <span v-else>{{
                $t("roomsPage_modal_createRoom_buttonsDiv_create")
              }}</span>
            </button>
            <button type="button" class="cancel-info-button" @click="closeModal">
              {{ $t("button_cancel") }}
            </button>
          </div>
          <div class="modal-error-block" v-if="$store.state.errorStatus">
            <div class="error">
              <p>
                {{ $t("errorpage_text") }}
              </p>
              <div class="error-context">
                <p>{{ $store.state.errorMessage }}</p>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>

<script>
import DataGridView from "@/components/UI/DataGridView.vue";
import CustomSelect from "@/components/UI/CustomSelect.vue";
import VSpinner from "@/components/UI/VSpinner.vue";
import {
  getLocations,
  getRooms,
  createLocation,
  createRoom,
  updateLocation,
  deleteLocation,
  deleteRoom,
  updateRoom,
  addProductToTheRoom,
} from "@/api/addingRooms.js";
import axios from "axios";
import getUserProducts from "@/api/getUserProducts";

import clearRoomsAndLocations from "@/api/checkRoomsAndLocations";

export default {
  name: "RoomsPage",
  components: {
    DataGridView,
    CustomSelect,
    VSpinner,
  },
  data() {
    return {
      locations: [],
      rooms: {},
      searchQuery: "",
      showModal: false,
      showModalCreateRoom: false,
      selectedProduct: null,
      selectedProducts: [],
      isUserInformationExpanded: false,
      countries: [],
      isRequired: "required",
      isEdit: false,
      unassignedProducts: [],
      showDeleteModal: false,
      showDeleteRoom: false,
      locationToDelete: null,
      roomToDelete: null,
      isRoomEdit: false,
      showLocation: "",
      chevronStates: {},

      form: {
        name: "",
        street_address1: "",
        street_address2: "",
        postal_code: "",
        city: "",
        country_code: "",
      },
      formCreateRoom: {
        name: "",
        type: "",
        description: "",
      },

      defaultFormCreateRoom: {
        name: "",
        type: "",
        description: "",
      },
      defaultData: {
        name: "",
        street_address1: "",
        street_address2: "",
        postal_code: "",
        city: "",
        province_state: "",
        country_code: "",
      },
      formData: { ...this.defaultData },
    };
  },
  methods: {
    toggleUserInformation(location) {
      location.isOpen = !location.isOpen;
      this.chevronStates[location.id] = location.isOpen;
      location.accountIconRotation = this.chevronStates[location.id] ? 90 : 0;
    },

    addNewLocation() {
      this.showModal = true;
      this.getUserProducts(); // delete after testing
      this.$store.commit("setErrorStatus", false);
    },
    addNewRoom(locationId) {
      this.formCreateRoom.location_id = locationId;
      this.showModalCreateRoom = true;
      this.getUserProducts();
      this.$store.commit("setErrorStatus", false);
    },

    getUserProducts() {
      this.unassignedProducts = [];
      const products = this.$store.getters.getUserProducts;
      for (let product of products) {
        if (product.room === null) {
          this.unassignedProducts.push(product);
        }
      }
    },
    closeModal(event) {
      if (
        !event ||
        event.target.classList.contains("modal") ||
        event.target.classList.contains("close") ||
        event.target.classList.contains("cancel-info-button")
      ) {
        this.showModal = false;
        this.showModalCreateRoom = false;
        this.isEdit = false;
        this.isRoomEdit = false;
        this.showDeleteModal = false;
        this.formData = { ...this.defaultData };
        this.formCreateRoom = { ...this.defaultFormCreateRoom };
        this.$store.commit("clearTemporaryAssignedProducts");
        this.$store.commit("setErrorStatus", false);
      }
    },
    openModal(location) {
      this.$store.commit("setErrorStatus", false);
      this.isRoomEdit = false;
      this.showModal = true;
      if (location) {
        this.formData = { ...location };
        this.isEdit = true;
      } else {
        this.formData = { ...this.defaultData };
        this.isEdit = false;
      }
    },
    openEditModal(room) {
      this.$store.commit("setErrorStatus", false);
      this.isRoomEdit = true;
      this.formCreateRoom = { ...room };
      this.showModalCreateRoom = true;
    },

    openDeleteModal(location, room) {
      this.$store.commit("setErrorStatus", false);
      this.showDeleteModal = true;
      if (room) {
        this.locationToDelete = location;
        this.roomToDelete = room;
        this.showDeleteRoom = true;
      } else {
        this.locationToDelete = location;
        this.showDeleteRoom = false;
      }
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    async confirmDeleteLocation() {
      try {
        this.$store.commit("setLoadingLocaly", true);
        await deleteLocation(this.locationToDelete.id);
        this.locations = this.locations.filter(
          (location) => location.id !== this.locationToDelete.id
        );
        this.closeDeleteModal();
        await clearRoomsAndLocations();
        this.$store.commit("setLoadingLocaly", false);
      } catch (error) {
        this.$store.commit("setErrorStatus", true);
        this.$store.commit("setLoadingLocaly", false);
      }
    },

    async fetchLocationsAndRooms() {
      try {
        this.$store.commit("setLoadingLocaly", true);
        const locations = await getLocations();

        // Add a new property to each location to track whether its accordion is open or not.
        // Set the first location's accordion to open by default.
        this.locations = locations.map((location, index) => {
          const showLocation = index === 0 ? "show" : "";
          const isOpen = index === 0 ? true : false;

          // Initialize the chevron state for this location
          this.chevronStates[location.id] = isOpen;

          return {
            ...location,
            showLocation: showLocation,
            isOpen: isOpen,
            accountIconRotation: isOpen ? 90 : 0, // Here, add this line to initialize the rotation
          };
        });
        for (let location of locations) {
          const rooms = await getRooms(location.id);
          this.rooms[location.id] = rooms;
        }
        this.$store.commit("setLoadingLocaly", false);
      } catch (error) {
        console.error(error);
        this.$store.commit("setLoadingLocaly", false);
      }
    },
    getRoomCount(locationId) {
      return this.rooms[locationId] ? this.rooms[locationId].length : 0;
    },
    fetchCountries() {
      axios
        .get(
          "https://cdn.contentful.com/spaces/4zjnzn055a4v/environments/master/entries/5AqAHkPDE86gnYyP20u1Vb?access_token=IohwVkm75OGdKdtyQewXdOkMrspUUrZ0OfDEpj_Xqas"
        )
        .then((response) => {
          this.countries = response.data.fields.countryNames.rows;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async createRoom() {
      if (!this.isRoomEdit) {
        try {
          this.$store.commit("setLoadingLocaly", true);
          const room = await createRoom(this.formCreateRoom);
          const roomId = room.id; // assuming the id property is 'id'
          await addProductToTheRoom(roomId);
          await getUserProducts();
          this.closeModal();
          await this.fetchLocationsAndRooms();
          this.$store.commit("setLoadingLocaly", false);
        } catch (error) {
          this.$store.commit("setLoadingLocaly", false);
          console.error(error);
          this.$store.commit("setErrorStatus", true);
        }
      } else {
        this.editRoom();
      }
    },
    async editRoom() {
      // add this method
      try {
        this.$store.commit("setLoadingLocaly", true);
        await updateRoom(this.formCreateRoom);
        // console.log(this.formCreateRoom);
        this.closeModal();
        await this.fetchLocationsAndRooms();
        this.$store.commit("setLoadingLocaly", false);
      } catch (error) {
        this.$store.commit("setLoadingLocaly", false);
        this.$store.commit("setErrorStatus", true);
        console.error(error);
      }
    },
    async submitForm() {
      try {
        this.$store.commit("setLoadingLocaly", true);
        if (this.isEdit) {
          await updateLocation(this.formData);
          // console.log(this.formData);
        } else {
          await createLocation(this.formData);
        }
        this.closeModal();
        await this.fetchLocationsAndRooms();
        this.$store.commit("setLoadingLocaly", false);
        // handle successful creation here, such as displaying a success message
      } catch (error) {
        this.$store.commit("setLoadingLocaly", false);
        this.$store.commit("setErrorStatus", true);
        console.error(error);
      }
    },

    async deleteRoomLocally() {
      try {
        this.$store.commit("setLoadingLocaly", true);
        await deleteRoom(this.roomToDelete.id);
        this.rooms[this.locationToDelete.id] = this.rooms[
          this.locationToDelete.id
        ].filter((room) => room.id !== this.roomToDelete.id);
        this.closeModal();
        clearRoomsAndLocations();
        this.$store.commit("setLoadingLocaly", false);
      } catch (error) {
        this.$store.commit("setLoadingLocaly", false);
        this.$store.commit("setErrorStatus", true);
        console.error(error);
      }
    },
  },
  computed: {
    filteredProducts() {
      if (this.searchQuery) {
        // You may need to adjust this depending on the structure of your room data
        return this.rooms.filter((room) =>
          room.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return this.rooms;
    },
    // accountIconRotation() {
    //   return this.isUserInformationExpanded ? 90 : 0;
    // },
    locationAccordionClasses() {
      return this.$refs.locationAccordion
        ? this.$refs.locationAccordion
          .map((locationAccordion) => locationAccordion.classList.toString())
          .join(" ")
        : "";
    },
  },
  created() {
    clearRoomsAndLocations();
    this.fetchLocationsAndRooms();
    this.fetchCountries();
  },
  watch: {
    locationAccordionClasses(newClassStrings) {
      for (let locationId in newClassStrings) {
        const classString = newClassStrings[locationId];
        this.chevronStates[locationId] = classString.includes("show");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: calc(100vh - var(--UpperMenuHeight)) !important;
  overflow-y: scroll;
}

.rooms-page {
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "headline headline headline btn-add-location"
    // "btn-add-location btn-add-location . ."
    "center-products center-products center-products center-products";

  .headline {
    grid-area: headline;

    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }



  .btn-add-location {
    grid-area: btn-add-location;
    // margin-top: 30px;
    // margin-left: 33px;
    display: flex;
    align-items: center;

    .btn {
      margin-top: 60px;
      border-radius: 30px;
      background-color: var(--Green);
      color: var(--White);
      width: 100%;
      height: 58px;

      &:hover {
        background-color: var(--Green);
      }
    }
  }

  .search-bar {
    grid-area: left-search-bar;
    margin-top: 30px;

    input {
      border: none;
      border-radius: 30px;
      padding: 17px;
      width: 100%;
      margin-bottom: 13px;
    }

    p {
      margin-left: 10px;
    }

    a {
      color: var(--Green);
    }
  }

  .selected-view {
    color: var(--Green);
  }

  .not-found-div {
    margin: 20px 0px 100px 10px;
  }
}

.rooms {
  grid-area: center-products;

  :deep(.product_column) {
    padding: 0;

    .img-block {
      background-color: var(--Light-grey);
      display: flex;
      justify-content: center;
      border-radius: 10px 10px 0px 0px;

      img {
        width: 80%;
        height: auto;
        padding: 50px;
        filter: contrast(100);
      }
    }

    .edit-buttons {
      margin: 10px;
    }
  }

  .product-info-block {
    padding: 20px 20px 40px 20px;
  }

  .edit-location,
  .delete-location {
    padding: 0;
    border: none;
    height: 53px;
    width: 53px;
    background-color: var(--bg-grey);

    img {
      width: 100%;
      height: auto;
    }
  }

  .accordion-item {
    background-color: var(--Pale-grey);
  }

  .table {
    border: none;
    border-bottom: none;

    .table-thead {
      border-bottom: 1px solid var(--Grey);

      th {
        text-align: center;
        background-color: var(--bg-grey);
      }
    }

    td {
      border-bottom: 0px;
      background-color: var(--bg-grey);
    }

    .rooms-info {
      border-bottom: 1px solid var(--Grey);
    }

    .accordion-header {
      height: 100%;

      button {
        display: flex;
        align-items: center;
        height: 100%;

        h2 {
          margin-bottom: 0px;
          margin-right: 20px;
        }
      }
    }

    .custom-accordion-button {
      width: 100%;
      border: 0px;
      background-color: var(--Pale-grey);
      text-align: left;
      padding: 13px;

      h4 {
        margin-right: 25px;
        margin-bottom: 0px;
      }
    }
  }
}

.custom-select-required {
  margin-right: 11px;

  .custom-select-icon {
    top: 35%;
  }
}

.custom-select {
  border: 1px solid var(--Grey);
}

@media (max-width: 1024px) {
  .main-block {
    .rooms-page {
      grid-template-areas:
        "headline headline headline headline"
        "btn-add-location btn-add-location btn-add-location btn-add-location"
        "center-products center-products center-products center-products";
      margin: 20px 15px 10px 15px;

      .btn-add-location {
        width: 100%;

        .btn {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 25px;
        }
      }
    }
  }

  // .modal {
  //   min-width: auto;
  //   .content {
  //     width: 90%;
  //     padding: 20px 40px 45px 40px;
  //     .custom-input-wrapper,
  //     .custom-select-wrapper {
  //       flex-flow: column wrap;
  //       align-items: center;
  //       label {
  //         margin-top: 0px;
  //         margin-right: 0px;
  //         margin-bottom: 8px;
  //         text-align: center;
  //       }

  //       .input-and-error {
  //         margin-right: 0px;
  //       }

  //       .custom-select-required {
  //         margin-right: 0px;
  //       }

  //       .select-and-error {
  //         margin-right: 0px;
  //         .custom-select-icon {
  //           top: 52% !important;
  //           right: 23%;
  //         }
  //       }
  //     }

  //     .custom-select-icon {
  //       right: 20%;
  //       top: 52%;
  //     }

  //     .custom-select {
  //       border: 1px solid var(--Grey);
  //     }
  //   }
  // }
}

@media screen and (max-width: 768px) {
  .rooms-page {
    margin: 20px 30px 10px 30px;

    .rooms {
      .table {
        td {
          padding: 0px;

          .custom-accordion-button {
            padding: 1px;
            padding-right: 5px;
          }
        }
      }

      .edit-location,
      .delete-location {
        width: 70%;
        height: 70%;
        padding: 5px;
        // img{
        //   max-width: 40px;
        //   height: fit-content;
        // }
      }

      td.position-relative {
        height: 50px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
    }

    .deletemodal .content {
      padding: 30px 45px 30px 45px;
    }
  }
}
</style>
