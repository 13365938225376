import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "multipart/form-data",
  };
}

async function getAllPurchases() {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/orders`;
  const organization = store.state.userData.private_organization_id;
  try {
    const response = await axiosInstance.get(url, {
      headers: getHeaders(),
      params: {
        organization_id: organization,
      },
    });
    return response.data.content;
  } catch (error) {
    // store.commit(
    //   "setErrorMessage",
    //   error.config.url + " " + error.response.data
    // );
    console.log(error);
    // throw error;
  }
}

async function getSpecificPayments(id) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/orders/${id}/payments`;
  const organization = store.state.userData.private_organization_id;
  try {
    const response = await axiosInstance.get(url, {
      headers: getHeaders(),
      params: {
        organization_id: organization,
      },
    });
    // console.log(response);
    return response.data.content;
  } catch (error) {
    // store.commit(
    //   "setErrorMessage",
    //   error.config.url + " " + error.response.data
    // );
    console.log(error);
    // throw error;
  }
}

export { getAllPurchases, getSpecificPayments };
