import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "multipart/form-data",
  };
}

async function deleteUserAccount() {
  const userId = store.state.userData.id;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/users/${userId}`;
  return axiosInstance
    .delete(url, {
      headers: getHeaders(),
    })
    .catch((error) => {
      console.error("Error deleteing the account:", error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

export default deleteUserAccount;
