import Keycloak from "keycloak-js";

const realmValue = process.env.VUE_APP_REALM;
const Keycloak_url = process.env.VUE_APP_KEYCLOCK_URL;
console.log("The keycloak url is:", process.env.VUE_APP_KEYCLOCK_URL);
console.log("The process env is:", process.env.VUE_APP_API_ENDPOINT);

const keycloakConfig = {
  url: Keycloak_url,
  realm: realmValue,
  clientId: "mygenelec",
};

console.log(`Keycloak is using realm: ${keycloakConfig.realm}`);

const GetUUID = () => keycloakInstance?.tokenParsed?.sub;

const keycloakInstance = new Keycloak(keycloakConfig);

const RefreshToken = (minValidity) => {
  return new Promise((resolve, reject) => {
    keycloakInstance
      .updateToken(minValidity)
      .then((refreshed) => {
        if (refreshed) {
          resolve(keycloakInstance.token);
        } else {
          console.log(`Token is still valid. Not refreshed.`);
          resolve(keycloakInstance.token);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to refresh the token, or the session is no longer valid: ${error}`
        );
        reject(error);
      });
  });
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback) => {
  return new Promise((resolve, reject) => {
    keycloakInstance
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
        checkLoginIframeInterval: 5,
      })
      .then(function (authenticated) {
        if (authenticated) {
          onAuthenticatedCallback();
          resolve();
        } else {
          reject(new Error("Not authenticated"));
        }
      })
      .catch((e) => {
        console.dir(e);
        console.log(`keycloak init exception: ${e}`);
        reject(e);
      });
  });
};

const initialize = () => {
  return new Promise((resolve, reject) => {
    keycloakInstance
      .init({
        onLoad: "check-sso", // or "login-required" based on your needs
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        checkLoginIframe: true,
        checkLoginIframeInterval: 5,
      })
      .then((authenticated) => {
        if (authenticated) {
          // Optionally clear URL parameters
          clearUrlParameters();
          //store.commit("setAuthenticated", true); // Assuming you're using Vuex to manage state
          resolve();
        } else {
          //store.commit("setAuthenticated", false);
          resolve(); // Resolve even if not authenticated, so the app can continue to load
        }
      })
      .catch((error) => {
        console.error(
          `Keycloak initialization error: ${JSON.stringify(error, null, 2)}`
        );
        reject(error);
      });
  });
};

function clearUrlParameters() {
  const url = window.location.toString();
  const indexOfHash = url.indexOf("#");

  if (indexOfHash > 0) {
    window.history.replaceState(
      {},
      document.title,
      url.substring(0, indexOfHash)
    );
  }
}

const Register = () => {
  // Redirect the user to the registration page.
  window.location.href = keycloakInstance.createRegisterUrl();
};

const UserName = () => keycloakInstance?.tokenParsed?.preferred_username;

const Token = () => keycloakInstance?.token;

const LogOut = () => {
  // Clear the local storage first to remove all saved data
  sessionStorage.clear();

  // Then log out from Keycloak
  keycloakInstance.logout();
};

const isAuthenticated = () => {
  return keycloakInstance.authenticated;
};

const KeyCloakService = {
  CallLogin: Login,
  GetUserName: UserName,
  GetAccesToken: Token,
  RefreshToken: RefreshToken,
  CallLogOut: LogOut,
  GetUUID: GetUUID,
  isAuthenticated: isAuthenticated,
  initialize: initialize,
  CallRegister: Register,
  KeycloakConfig: keycloakConfig,
};

export default KeyCloakService;
