<template>
  <div class="custom_column">
    <table id="tableComponent" class="table table-bordereless">
      <thead>
        <tr>
          <!-- loop through each value of the fields to get the table header -->
          <th v-for="{ label, value } in tableFields" :key="value">
            {{ label }}
            <i class="bi bi-sort-alpha-down" aria-label="Sort Icon"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot> </slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DataListView",
  props: {
    products: Array,
    tableFields: Array,
    onProductClick: Function,
  },
  methods: {
    showProductDetails(item) {
      this.onProductClick(item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
