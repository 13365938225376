<template>
  <div class="home-page">
    <VSpinner v-if="$store.getters.isLoading"></VSpinner>
    <div class="welcome-block">
      <div class="img-block"><img src="@/assets/cubeImage.png" /></div>
      <div class="text-block">
        <h2>
          {{
            $t("homepage_banner_header", { userNameAndSurname: userNameAndSurname })
          }}
        </h2>
        <div class="centered-content">
          <p>{{ $t("homepage_banner_mainText") }}</p>
          <button class="save-info-button text-nowrap" @click="$router.push('/profile')">
            <a style="color: var(--White); text-decoration: none">{{
              $t("homepage_banner_button_text")
            }}</a>
          </button>
        </div>
      </div>
    </div>
    <!-- <videoBotScript></videoBotScript> -->
    <div>
      <ProductList></ProductList>
    </div>
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
import VSpinner from "@/components/UI/VSpinner.vue";
//import videoBotScript from "@/components/videoBot/videoBotScript.vue";

export default {
  name: "HomePage",
  components: {
    ProductList,
    VSpinner,
    //videoBotScript,
  },
  data() {
    return {};
  },
  computed: {
    userNameAndSurname() {
      const { first_name, last_name } = this.$store.state.userData;
      if (this.$store.state.activeLocale === 'ja') {
        return `${last_name} ${first_name}`;
      } else {
        return `${first_name} ${last_name}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  padding-bottom: 70px;
  height: calc(100vh - var(--UpperMenuHeight));
  overflow-y: scroll;
}

.welcome-block {
  display: flex;
  align-items: center;
  height: 420px;
  color: var(--White);
  background-color: var(--Black);

  .img-block {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    img {
      align-self: center;
      //filter: invert(100%);
    }
  }

  .text-block {
    flex-grow: 2;
    margin-right: 70px;

    p {
      max-width: 800px;
    }
  }
}

.img-block {
  img {
    width: 420px;
    height: auto;
  }
}

@media (max-width: 1024px) {
  .img-block {
    img {
      width: 232px;
    }
  }
}

@media (max-width: 758px) {
  .img-block {
    margin: 0px;

    img {
      width: 340px;
    }
  }

  .welcome-block {
    flex-direction: column;
    height: auto;

    .text-block {
      margin: 0px;

      h2 {
        margin: 10px;
        font-size: 2.125rem;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 700;
      }
    }

    .centered-content {
      display: none;
    }
  }
}
</style>
