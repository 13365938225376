<template>
    <div class="header-with-logo">
        <div class="item menuicon d-flex align-items-center">
            <button @click="$store.dispatch('toggleSidebar')" class="toggle-sidebar-button">
                <img src="@/assets/icons/burger.svg" alt="Burger icon" v-if="!$store.state.sidebarVisible" />
                <img src="@/assets/iconsFromFigma/cross.svg" alt="Burger icon" v-else />
            </button>
            <!-- <i class="icon default-icon icon-reorder" id="openNavIcon" style="display: inline;"></i> -->
        </div>
        <img src="@/assets/genelec-logo.png" />
        <div class="lang-selection">
            <div class="dropdown-center" ref="langDropdown">
                <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ ActiveLocale }}
                    <font-awesome-icon :icon="['fas', 'chevron-right']" :style="{
                        transform: `rotate(${langIconRotation}deg)`,
                        transition: 'transform 0.3s ease-in-out',
                        marginLeft: '15px',
                    }" />
                </button>
                <ul class="dropdown-menu">
                    <li @click="changeLocale('en')">
                        <a class="dropdown-item" href="#">English</a>
                    </li>
                    <li @click="changeLocale('fi')">
                        <a class="dropdown-item" href="#">Suomi</a>
                    </li>
                    <li @click="changeLocale('sv')">
                        <a class="dropdown-item" href="#">Svenska</a>
                    </li>
                    <li @click="changeLocale('ja')">
                        <a class="dropdown-item" href="#">日本語</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UpperMenuShort",
    data() {
        return {
            ActiveLocale: "EN",
            langIconRotation: 0,
        };
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
            this.ActiveLocale = locale.toUpperCase();
        },

    },
    mounted() {
        this.$refs.langDropdown.addEventListener("show.bs.dropdown", () => {
            this.langIconRotation = 90;
        });
        this.$refs.langDropdown.addEventListener("hide.bs.dropdown", () => {
            this.langIconRotation = 0;
        });
    },

}
</script>

<style lang="scss" scoped>
.header-with-logo {
    height: var(--UpperMenuHeight);
    background-color: var(--Black);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "menuBurger logo language";
    align-items: center;
    // justify-content: center;

    .menuicon {
        padding-left: 21px;
        font-size: 24px;
        grid-area: menuBurger;

        button {
            background-color: black;
            padding: 0px;
            border: none;
        }
    }

    img {
        height: 25px;
        justify-self: center;
        grid-area: logo;
    }

    .lang-selection {
        justify-self: flex-end;
        grid-area: language;

        .dropdown-center {
            height: 100%;
            background-color: var(--Black);

            .btn {
                padding-left: 1px;
                border: none;
            }

            button {
                height: 100%;
                padding: 1px;
                margin-right: 75px;
                color: var(--White);

            }

            .dropdown-menu {
                cursor: pointer;
                border-radius: 0px;
                min-width: 100px;
                background-color: var(--Black);
                padding: 0;

                .dropdown-item {
                    text-align: left;
                    padding: 0;
                }

                li {
                    border: 1px solid var(--White) !important;
                    padding: 10px;
                    padding-left: 20px;
                    padding-right: 20px;
                    background-color: var(--Black);

                    &:hover {
                        background-color: var(--Grey);
                    }

                    a {
                        color: var(--White);
                        display: block;
                        width: 100%;
                        background-color: inherit; // This will make 'a' take the same background color as its parent 'li'

                        &:hover {
                            background-color: inherit; // Make sure the background color stays the same even when 'a' is hovered
                        }
                    }
                }

                a {
                    color: var(--White);
                }
            }
        }
    }

}

@media (min-width: 1025px) {
    .toggle-sidebar-button {
        display: none;
    }
}

@media (max-width: 768px) {
    .header-with-logo {
        .lang-selection {
            .dropdown-center {
                button {
                    margin-right: 20px;
                }
            }
        }
    }
}
</style>