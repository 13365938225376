<template>
  <div class="main-block">
    <div class="support-page">
      <div class="headline">
        <h1>
          <b>{{ $t("support_headline_h2") }}</b>
        </h1>
        <p>{{ $t("support_headline_p_welcome_1") }}</p>
        <a href="https://support.genelec.com/hc/en-us" target="_blank">
          <button class="green-button">
            {{ $t("support_headline_button_a_1") }}
          </button>
        </a>
        <p class="button-description">
          {{ $t("support_headline_p_welcome_2") }}
        </p>
        <a
          href="https://support.genelec.com/hc/en-us/community/topics"
          target="_blank"
        >
          <button class="green-button">
            {{ $t("support_headline_button_a_2") }}
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
//import ListView from "@/components/UI/DataListView.vue";

export default {
  name: "SupportPage",
  components: {
    //   ListView,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: calc(100vh - var(--UpperMenuHeight)) !important;
  overflow-y: scroll;
}

.support-page {
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "headline headline headline headline";
  .headline {
    grid-area: headline;
    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }

    .button-description {
      margin-top: 1rem;
    }

    // .green-button {
    //   margin-left: 33px;
    // }
  }
}

@media (max-width: 768px) {
  .support-page {
    margin: 20px 30px 10px 30px;
  }
}
</style>
