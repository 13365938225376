<template>
  <div class="masonry">
    <div v-for="product in products" :key="product.id" class="product_row">
      <div class="product-info-block">
        <div class="img-block">
          <img src="@/assets/roomPic.svg" />
        </div>
        <div class="text-div">
          <h4>{{ product.name }}</h4>
          <div><strong></strong>{{ product.text }}</div>
          <div class="btn-div">
            <a :href="product.url" target="_blank" v-if="checkProfile">
              <button v-if="product.track" class="btn" @click="trackRedirectionForGLM">
                {{ $t("recommendedsoft_productRow_btnDiv_a_button") }}
              </button>
              <button v-else class="btn">
                {{ $t("recommendedsoft_productRow_btnDiv_a_button") }}
              </button>
            </a>
            <div v-else class="complete-profile">
              <p style="color: black; font-weight: 700">
                {{
                  $t(
                    "recommendedsoft_productRow_btnDiv_p_completeProfile_part1"
                  )
                }}
                <a @click="$router.push('/profile')" href="#" style="color: var(--Green)">{{
                  $t(
                    "recommendedsoft_productRow_btnDiv_p_completeProfile_part3_link"
                  )
                }}</a>
                {{
                  $t(
                    "recommendedsoft_productRow_btnDiv_p_completeProfile_part2"
                  )
                }}
              </p>
              <!-- 
              <button class="btn" @click="$router.push('/profile')">
                {{
                  $t(
                    "recommendedsoft_productRow_btnDiv_completeProfile_profileBtn"
                  )
                }}
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendTag } from "@/api/activeCampaign.js";

export default {
  name: "RecommendedSoftware",
  props: {
    products: Array,
    onProductClick: Function,
    customClass: String,
  },
  data() {
    return {
      profileFilled: false,
    };
  },
  methods: {
    trackRedirectionForGLM() {
      const tagId = "3041";
      const contactId = this.$store.state.userData.active_campaign_id;
      // console.log(contactId);
      sendTag(tagId, contactId);
    },
  },
  computed: {
    checkProfile() {
      // console.log(this.$store.state.userData.working_environment);
      // console.log(this.$store.state.userData.genelec_user_status);
      if (
        !this.$store.state.userData.working_environment ||
        this.$store.state.userData.working_environment ==
        "segment-not-selected-key" ||
        !this.$store.state.userData.genelec_user_status ||
        this.$store.state.userData.genelec_user_status ==
        "genelec-ownership-status-not-selected-key" ||
        !this.$store.state.userData.country_code ||
        this.$store.state.userData.country_code == "--"
      ) {
        return false;
      } else {
        return true;
      }
    },
    // accessibleProducts() {
    //   const accessToken = KeyCloakService.GetAccesToken();

    //   try {
    //     const decodedToken = VueJwtDecode.decode(accessToken);
    //     const userRoles = decodedToken.realm_access?.roles || [];
    //     // userRoles.push("beta-test-user");
    //     const userIsBetaTester = userRoles.includes("beta-test-user");
    //     console.log(userRoles);

    //     return this.products.filter((product) => {
    //       // Product is accessible if it does not require beta access or if the user is a beta tester
    //       return (
    //         !product.betaAccess || (product.betaAccess && userIsBetaTester)
    //       );
    //     });
    //   } catch (error) {
    //     console.error("Error decoding token:", error);
    //     return [];
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.masonry {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
}

.product_row {
  background-color: var(--White);
  border-radius: 10px;
  padding: 20px;
  margin: 15px;
  height: fit-content;
}

.product-info-block {
  display: flex;
  justify-content: flex-start;
}

.img-block {
  img {
    width: 150px;
    height: 150px;
    padding: 10px;
  }
}

.text-div {
  padding: 15px;
  padding-left: 10px;
}

.btn-div {
  margin: 10px;
  margin-left: 0px;
  justify-self: center;

  .btn {
    border-radius: 30px;
    background-color: var(--Green);
    color: var(--White);
    width: auto;
    height: 40px;

    &:hover {
      background-color: var(--Green);
    }
  }
}

@media (max-width: 1200px) {
  .masonry {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 768px) {
  .product-info-block {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }
}
</style>
