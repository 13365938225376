import { createStore } from "vuex";

export default createStore({
  state: {
    userData: null,
    userProducts: null,
    userLocation: null,
    userRooms: null,
    ErrorWrongRealm: false,
    preRegisteredProducts: [],
    temporaryAssignedProducts: [],
    isLoading: false,
    isLoadingLocaly: false,
    sidebarVisible: false,
    activeLocale: null,
    landingPage: false,
    activeRequests: 0,
    hideTimeout: null,
    errorStatus: false,
    errorMessage: 123,
    activeCampaignData: null,
    accessTokenInfo: null,
    AccountSelectorModal: false,
  },
  mutations: {
    setUserData(state, data) {
      state.userData = data;
    },
    setUserProducts(state, data) {
      state.userProducts = data;
    },
    deleteUserProduct(state, serial_number) {
      state.userProducts = state.userProducts.filter(
        (product) => product.serial_number !== serial_number
      );
    },
    setLocationData(state, data) {
      state.userLocation = data;
    },
    setRooms(state, data) {
      state.userRooms = data;
    },
    setActiveLocale(state, data) {
      state.activeLocale = data;
    },
    clearRooms(state) {
      state.userRooms = [];
    },
    setError(state, value) {
      state.ErrorWrongRealm = value;
    },
    setLandingPage(state, value) {
      state.landingPage = value;
    },
    setPreRegisteredProducts(state, data) {
      state.preRegisteredProducts = data;
    },
    addPreRegisteredProduct(state, product) {
      if (!state.preRegisteredProducts) {
        state.preRegisteredProducts = [];
      }
      state.preRegisteredProducts.push(product);
    },
    deletePreRegisteredProduct(state, serialNumber) {
      if (state.preRegisteredProducts) {
        state.preRegisteredProducts = state.preRegisteredProducts.filter(
          (product) => product.serial_number !== serialNumber
        );
      }
    },
    clearPreRegisteredProducts(state) {
      state.preRegisteredProducts = [];
    },
    updatePreRegisteredProduct(state, { index, product }) {
      state.preRegisteredProducts.splice(index, 1, product);
    },

    updatePrivateOrganizationId(state, privateOrganizationId) {
      if (state.userData) {
        state.userData.private_organization_id = privateOrganizationId;
      }
    },

    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setLoadingLocaly(state, payload) {
      state.isLoadingLocaly = payload;
    },
    // Assign products at Rooms and locations

    setTemporaryAssignProducts(state, products) {
      for (const product of products) {
        state.temporaryAssignedProducts.push(product);
      }
    },
    clearTemporaryAssignedProducts(state) {
      state.temporaryAssignedProducts = [];
    },

    deleteTemporaryAssignedProduct(state, serialNumber) {
      if (state.temporaryAssignedProducts) {
        state.temporaryAssignedProducts =
          state.temporaryAssignedProducts.filter(
            (product) => product.serial_number !== serialNumber
          );
      }
    },
    clearAssignedToTheRoomProduct(state, serialNumber) {
      for (let product of state.userProducts) {
        if (product.serial_number === serialNumber) {
          product.room = null;
        }
      }
    },
    setSidebarVisible(state, payload) {
      state.sidebarVisible = payload;
    },
    // start New version of the spinner
    incrementRequest(state) {
      state.activeRequests++;
      if (state.hideTimeout) {
        clearTimeout(state.hideTimeout);
        state.hideTimeout = null;
      }
    },

    decrementRequest(state) {
      if (state.activeRequests > 0) {
        state.activeRequests--;
      }
      if (state.activeRequests === 0 && !state.hideTimeout) {
        state.hideTimeout = setTimeout(() => {
          state.activeRequests = 0;
          state.hideTimeout = null;
        }, 300); // 300ms delay
      }
    },
    // end New version of the spinner

    // START = error status
    setErrorStatus(state, value) {
      state.errorStatus = value;
    },
    setErrorMessage(state, value) {
      state.errorMessage = value;
    },
    // END = error status

    // START = ACTIVE CAMPAING DATA

    setActiveCampaignData(state, value) {
      state.activeCampaignData = value;
    },

    // END = ACTIVE CAMPAING DATA

    // START = ACCESS TOKEN INFO

    setAccessTokenInfo(state, value) {
      state.accessTokenInfo = value;
    },

    // END = ACCESS TOKEN INFO

    // START = ACCOUNT SELECTOR MODAL WINDOW

    showAccountSelector(state, value) {
      state.AccountSelectorModal = value;
    },

    // END = ACCOUNT SELECTOR MODAL WINDOW
  },
  actions: {
    updateUserData({ commit }, data) {
      commit("setUserData", data);
    },
    updateUserProducts({ commit }, data) {
      commit("setUserProducts", data);
    },
    updateUserLocationData({ commit }, data) {
      commit("setLocationData", data);
    },
    updatePreRegisteredProducts({ commit }, data) {
      commit("setPreRegisteredProducts", data);
    },
    addProductToPreRegistered({ commit }, product) {
      commit("addPreRegisteredProduct", product);
    },
    deleteProductFromPreRegistered({ commit }, serialNumber) {
      commit("deletePreRegisteredProduct", serialNumber);
    },
    clearAllPreRegisteredProducts({ commit }) {
      commit("clearPreRegisteredProducts");
    },
    updateTemporaryAssignProducts({ commit }, products) {
      commit("setTemporaryAssignProducts", products);
    },
    toggleSidebar({ commit, state }) {
      commit("setSidebarVisible", !state.sidebarVisible);
    },
    updateActiveCampaignData({ commit }, data) {
      commit("setActiveCampaignData", data);
    },
    updateAccessTokenInfo({ commit }, data) {
      commit("setAccessTokenInfo", data);
    },
  },
  getters: {
    getUserData: (state) => state.userData,
    getUserProducts: (state) => state.userProducts,
    getPreRegisteredProducts: (state) => state.preRegisteredProducts,
    getUserLocation: (state) => state.userLocation,
    getTemporaryAssignProducts: (state) => state.temporaryAssignedProducts,
    // start New version of the spinner
    isLoading(state) {
      return state.activeRequests > 0;
    },
    isAccessToken(state) {
      return state.accessTokenInfo;
    },
    // end New version of the spinner
  },
});
