<template>
  <div class="main-block">
    <div class="sub-page">
      <div class="headline">
        <h2>
          <b>{{ $t("subscriptions_headline_h2") }}</b>
        </h2>
        <p>{{ $t("subscriptions_headline_p_welcome") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
//import ListView from "@/components/UI/DataListView.vue";

export default {
  name: "SubscriptionsPage",
  components: {
    //   ListView,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: calc(100vh - var(--UpperMenuHeight));
  overflow-y: scroll;
}

.sub-page {
  margin-top: 40px;
  margin-right: 40px;
  margin-left: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas: "headline headline headline headline";
  .headline {
    grid-area: headline;

    h2 {
      border-left: 6px solid var(--Green);
      margin: 10px;
      padding: 15px;
      margin-bottom: 25px;
    }

    p {
      padding-left: 33px;
      max-width: 800px;
    }

    .green-button {
      margin-left: 33px;
    }
  }
}
</style>
