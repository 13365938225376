import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";

async function sendLoginTimestamp(timestamp) {
  const ownerOrgId = store.state.userData.id; // user id

  const url = `${process.env.VUE_APP_API_ENDPOINT}/active-campaign/users/${ownerOrgId}/fields/update`;
  const timestampField = [];

  try {
    timestampField.push({
      fieldId: "73",
      fieldValue: timestamp,
    });
    await axiosInstance.put(
      url,
      { fields: timestampField },
      {
        headers: {
          Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("Timestamp sent successfully", response.data);
  } catch (error) {
    console.error("Error sending login timestamp:", error);
  }
}

export default sendLoginTimestamp;
