<template>
  <div class="main">
    <div class="inner-div">
      <h4>{{ $t("errorpage_text") }}</h4>


      <div v-if="$store.state.errorMessage" class="error-message">
        <p><strong>URL:</strong> {{ $store.state.errorMessage.url }}</p>
        <p><strong>Message:</strong> {{ $store.state.errorMessage.message }}</p>
        <button v-if="$store.state.errorMessage.details" class="btn-more-text" @click="moreData">{{ showMoreData ?
          $t("errorpage_btn_show_less") :
          $t("errorpage_btn_show_more")
          }}</button>
        <pre
          v-if="$store.state.errorMessage.details && showMoreData"><code>{{ $store.state.errorMessage.details }}</code></pre>
      </div>
      <button class="btn btn-danger " @click="LogOut">{{ $t("errorpage_btn_logout") }}</button>
    </div>

  </div>
</template>


<script>
import KeyCloakService from "@/security/KeyCloakService.js";

export default {
  name: "ErrorPage",
  data() {
    return {
      errorMessage: "",
      showMoreData: false
    };
  },
  methods: {
    LogOut() {
      KeyCloakService.CallLogOut();
    },
    moreData() {
      this.showMoreData = !this.showMoreData;
    }
  },

};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-size: 1.2em;
  height: 100vh;
  overflow-y: scroll !important;
  padding: 10%;

  .inner-div {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 800px;

    h4 {
      width: 100%;
      margin: 0.5rem;
    }

    .btn {
      font-size: 1.1em;
      padding: 0.5em 1.2em;
      align-self: flex-end;
    }

    .error-message {
      background-color: #f8d7da;
      /* Light red background */
      color: #842029;
      /* Dark red text */
      border: 1px solid #f5c2c7;
      border-radius: 0.25rem;
      padding: 1rem;
      margin: 1rem 0;
      // width: 90%;
      width: 100%;
      height: auto;
      // max-height: 300px;

      p {
        margin: 0.5rem 0;
      }

      pre {
        background-color: #f1f1f1;
        /* Light grey background */
        border-radius: 0.25rem;
        padding: 0.5rem;
        overflow-x: auto;
      }

      code {
        font-family: monospace;
      }

      .btn-more-text {
        border: 2px solid White;
        border-radius: 0.25rem;
        background-color: transparent;
        margin-bottom: 10px;
        color: #842029;
        font-weight: bold;
        padding: 5px;
        width: 100%;
      }
    }
  }
}
</style>
