<template>
  <div class="main-block">
    <UpperMenuForLandingPage></UpperMenuForLandingPage>

    <div class="landing-page">
      <div class="welcome-block">
        <h1>{{ $t("landingpage_h1_header") }}</h1>
        <p>
          {{ $t("landingpage_p_main_text") }}
        </p>
        <div class="welcome-buttons-block">
          <button class="green-button" @click="Register()">
            <b>{{ $t("landingpage_welcome-buttons-block_reg") }}</b>
          </button>
          <button class="green-button" style="
              background: rgba(0, 0, 0, 0.5);

              border: 2px solid var(--Green);
            " @click="auth">
            <b>{{ $t("landingpage_welcome-buttons-block_login") }}</b>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sendLoginTimestamp from "@/api/timeStamp.js";
import KeyCloakService from "@/security/KeyCloakService";
import { initFullApp } from "@/main.js";
import store from "@/store";
import UpperMenuForLandingPage from "@/components/navigation/UpperMenuForLandingPage.vue"

export default {
  name: "LandingPage",
  components: {
    UpperMenuForLandingPage
  },
  data() {
    return {
      ActiveLocale: "EN",
      langIconRotation: 0,
    };
  },

  methods: {
    auth() {
      KeyCloakService.CallLogin(() => {
        console.log("User authenticated");
        const timestamp = new Date().toISOString();
        initFullApp();
        sendLoginTimestamp(timestamp);
        store.commit("setLandingPage", false);
      }).catch((error) => {
        console.error("Error during Keycloak initialization:", error);
        // Handle the error, maybe show an error message to the user
      });
    },
    Register() {
      KeyCloakService.CallRegister();

    },

  },


};
</script>


<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: 100vh;
  overflow-y: scroll;
  background-image: url("@/assets/72043f6f049b5370955e31535c26b506.jpg");
  color: var(--White);
  background-position: center;
  background-size: cover;
}

.landing-page {
  margin-top: 47px;
  margin-right: 70px;
  margin-left: 163px;
  display: flex;
  justify-content: start;

  .welcome-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;

    h1 {
      margin-bottom: 21px;
    }

    p {
      margin-bottom: 60px;
    }

    .welcome-buttons-block {
      display: flex;
      // justify-content: space-between;
      width: 100%;

      .green-button {
        margin-right: 60px;
      }
    }
  }
}

.header-with-logo {
  height: var(--UpperMenuHeight);
  background-color: var(--Black);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". logo language";
  align-items: center;
  // justify-content: center;

  img {
    height: 25px;
    justify-self: center;
    grid-area: logo;
  }

  .lang-selection {
    justify-self: flex-end;
    grid-area: language;

    .dropdown-center {
      height: 100%;
      background-color: var(--Black);

      .btn {
        padding-left: 1px;
        border: none;
      }

      button {
        height: 100%;
        padding: 1px;
        margin-right: 75px;
        color: var(--White);

      }

      .dropdown-menu {
        cursor: pointer;
        border-radius: 0px;
        min-width: 100px;
        background-color: var(--Black);
        padding: 0;

        .dropdown-item {
          text-align: left;
          padding: 0;
        }

        li {
          border: 1px solid var(--White) !important;
          padding: 10px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: var(--Black);

          &:hover {
            background-color: var(--Grey);
          }

          a {
            color: var(--White);
            display: block;
            width: 100%;
            background-color: inherit; // This will make 'a' take the same background color as its parent 'li'

            &:hover {
              background-color: inherit; // Make sure the background color stays the same even when 'a' is hovered
            }
          }
        }

        a {
          color: var(--White);
        }
      }
    }
  }

}

@media (max-width: 1024px) {
  .landing-page {
    justify-content: center;
    margin-top: 63px;
    margin-right: 70px;
    margin-left: 70px;
  }
}

@media (max-width: 768px) {
  .landing-page {
    margin: 20px 30px 10px 30px;

    .welcome-block {
      .welcome-buttons-block {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        .green-button {
          margin: 15px 0px;
        }
      }
    }
  }

  .header-with-logo {
    .lang-selection {
      .dropdown-center {
        button {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
