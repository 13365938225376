<template>
  <div class="main-block">
    <VSpinner v-if="$store.getters.isLoading"></VSpinner>
    <div class="grade-page">
      <div class="headline">
        <h1>
          <b>{{ $t("grade_headline_h2") }}</b>
        </h1>
        <p>{{ $t("grade_headline_p_welcome") }}</p>
        <a :href="$t('glm_grade_link')" target="_blank">
          <button class="green-button">
            {{ $t("grade_headline_button_a") }}
          </button>
        </a>
      </div>
      <div class="grade-table" v-if="userGrades.length > 0">
        <table class="table table-hover table-custom " v-if="!$store.state.errorStatus">
          <thead>
            <tr>
              <th scope="col" class="col-10">{{ $t("grade_gradeTable_th_1") }}</th>
              <th scope="col" class="col-1">{{ $t("grade_gradeTable_th_2") }}</th>
            </tr>
          </thead>
          <tbody v-for="report in userGrades" :key="report.filename">
            <tr>
              <td>{{ report.filename }}</td>
              <td>
                <div class="download-report">
                  <button class="btn ps-0" @click="downloadReport(report.filename)">
                    {{ $t("grade_gradeTable_button_download") }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="error-div">
          <div class="alert alert-danger w-100">
            <p>{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getGLMUserInfo,
  getGradeReports,
  getGradeDownloadLink,
} from "@/api/gradeReports";
import VSpinner from "@/components/UI/VSpinner.vue";

export default {
  name: "GradeReports",
  components: {
    VSpinner,
  },
  data() {
    return {
      userInfo: null,
      userGrades: [],
    };
  },
  methods: {
    async downloadReport(reportName) {
      try {
        const link = await getGradeDownloadLink(
          this.userInfo[0].user_id,
          this.userInfo[0].login_id,
          reportName
        );

        window.open(link, "_blank");
      } catch (error) {
        console.error("Error fetching download link:", error);
      }
    },
  },
  async created() {
    try {
      this.$store.commit("setErrorStatus", false);
      this.userInfo = await getGLMUserInfo();
      this.userGrades = await getGradeReports(
        this.userInfo[0].user_id,
        this.userInfo[0].login_id
      );
      console.log(this.userInfo);
    } catch (error) {
      this.$store.commit("setErrorStatus", true);
    }
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: calc(100vh - var(--UpperMenuHeight)) !important;
  overflow-y: scroll;
}

.grade-page {
  margin: 40px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "headline headline headline headline"
    "grade-service  grade-service  grade-service  grade-service"
    "grade-table grade-table grade-table grade-table";

  .headline {
    grid-area: headline;
    margin-bottom: 20px;

    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }

    // .green-button {
    //   margin-left: 33px;
    // }
  }

  .grade-table {
    grid-area: grade-table;
    max-width: 800px;
    width: 100%;
  }
}

.grade-service {
  grid-area: grade-service;
  height: fit-content;
}

@media (max-width: 768px) {
  .grade-page {
    margin: 20px 30px 10px 30px;
  }
}
</style>
