import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "multipart/form-data",
  };
}

async function getAccounts() {
  const ownerOrgId = store.state.userData.id; // user id
  // console.log(ownerOrgId);
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/users/${ownerOrgId}/organizations`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error getting accounts", error);
      //throw error;
    });
}

export default getAccounts;
