// i18-data.js
const fetchLocalizationData = async () => {
  const contentfulSpaceId = "4zjnzn055a4v";
  const contentfulAccessToken = "IohwVkm75OGdKdtyQewXdOkMrspUUrZ0OfDEpj_Xqas";
  const contentfulEntryId = "GP7n8AAK3h7HJYI993kOf";
  const contentfulUrl = `https://cdn.contentful.com/spaces/${contentfulSpaceId}/environments/master/entries/${contentfulEntryId}?access_token=${contentfulAccessToken}`;
  //
  const response = await fetch(contentfulUrl);
  const rawData = await response.json();

  const messages = {
    en: {},
    fi: {},
    sv: {},
    ja: {},
    es: {},
  };

  rawData.fields.mainMenuTexts.rows.forEach((row) => {
    messages.en[row.Key] = row.en;
    if (row.fi) {
      messages.fi[row.Key] = row.fi;
    }
    if (row.sv) {
      messages.sv[row.Key] = row.sv;
    }
    if (row.ja) {
      messages.ja[row.Key] = row.ja;
    }
    if (row.es) {
      messages.es[row.Key] = row.es;
    }
  });

  rawData.fields.appTexts.rows.forEach((row) => {
    messages.en[row.Key] = row.en;
    if (row.fi) {
      messages.fi[row.Key] = row.fi;
    }
    if (row.sv) {
      messages.sv[row.Key] = row.sv;
    }
    if (row.ja) {
      messages.ja[row.Key] = row.ja;
    }
    if (row.es) {
      messages.es[row.Key] = row.es;
    }
  });

  rawData.fields.resourceURLs.rows.forEach((row) => {
    messages.en[row.Key] = row.en;
    if (row.fi) {
      messages.fi[row.Key] = row.fi;
    }
    if (row.sv) {
      messages.sv[row.Key] = row.sv;
    }
    if (row.ja) {
      messages.ja[row.Key] = row.ja;
    }
    if (row.es) {
      messages.es[row.Key] = row.es;
    }
  });

  rawData.fields.documentURLs.rows.forEach((row) => {
    messages.en[row.Key] = row.en;
    if (row.fi) {
      messages.fi[row.Key] = row.fi;
    }
    if (row.sv) {
      messages.sv[row.Key] = row.sv;
    }
    if (row.ja) {
      messages.ja[row.Key] = row.ja;
    }
    if (row.es) {
      messages.es[row.Key] = row.es;
    }
  });

  return {
    en: messages.en,
    fi: messages.fi,
    sv: messages.sv,
    ja: messages.ja,
    es: messages.es,
  };
};

export default fetchLocalizationData;
