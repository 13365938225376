import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "multipart/form-data",
  };
}

async function getGLMUserInfo() {
  const email = store.state.userData.email;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/glm/users?email_address=${email}`;
  try {
    const response = await axiosInstance.get(url, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    store.commit(
      "setErrorMessage",
      error.config.url + " " + error.response.data
    );
    console.log("Error getting GLM USER INFO: ", error);
  }
}

async function getGradeReports(user, login) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/glm/grade/reports?user_id=${user}&login_id=${login}`;
  try {
    const response = await axiosInstance.get(url, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    store.commit(
      "setErrorMessage",
      error.config.url + " " + error.response.data
    );
    console.log("Error getting Grade reports: ", error);
  }
}

async function getGradeDownloadLink(user, login, filename) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/glm/grade/reports/download?user_id=${user}&login_id=${login}&file_name=${filename}`;
  try {
    const response = await axiosInstance.get(url, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    store.commit(
      "setErrorMessage",
      error.config.url + " " + error.response.data
    );
    console.log("Error getting download links: ", error);
  }
}

export { getGLMUserInfo, getGradeReports, getGradeDownloadLink };
