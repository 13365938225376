import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "application/json",
  };
}

async function getAllServices() {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/services`;
  const country_code = store.state.userData.country_code;
  const orgId = store.state.userData.private_organization_id;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
      params: {
        country_code: country_code,
        organization_id: orgId,
        sort: "internalName,asc",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting the service list", error);
      throw error;
    });
}

async function getServiceList(serviceId) {
  const country_code = store.state.userData.country_code;
  const orgId = store.state.userData.private_organization_id;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/services/${serviceId}/service-products/service-plans`;
  return axiosInstance
    .get(url, {
      // withCredentials: true,
      headers: getHeaders(),
      params: {
        country_code: country_code,
        organization_id: orgId,
        sort: "internalName,asc",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting services", error);
      throw error;
    });
}

async function checkStatusOfService(serviceId) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/services/${serviceId}/status`;
  const orgId = store.state.userData.private_organization_id;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
      params: {
        organization: orgId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting services", error);
      throw error;
    });
}

async function getServicePlan(planId) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/service-plans/${planId}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting services", error);
      throw error;
    });
}

async function activateServiceById(serviceId) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/service-management/orders`;
  const orgId = store.state.userData.private_organization_id;

  const orderForm = {
    service_plan_id: serviceId,
    organization_ids: [orgId],
  };

  return axiosInstance
    .post(url, orderForm, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error activating the service", error);
    });
}

export {
  getAllServices,
  getServiceList,
  checkStatusOfService,
  getServicePlan,
  activateServiceById,
};
