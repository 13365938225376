import * as XLSX from "xlsx";

function formatDate(cellValue) {
  // XLSX reads in the date as a number of days since (Excel's base date) January 1, 1900
  // We subtract 1 to compensate for the fact that Excel thinks 1900 was a leap year, which it wasn't
  const date = XLSX.SSF.parse_date_code(cellValue, { date1904: false });
  const year = date.y;
  const month = (date.m < 10 ? "0" : "") + date.m; // Ensure two digits
  const day = (date.d < 10 ? "0" : "") + date.d; // Ensure two digits
  return `${year}-${month}-${day}`;
}

async function readExcelFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      resolve(workbook);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

async function extractDataFromWorkbook(workbook) {
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  // Get JSON data with raw values (r) to preserve empty cells as null
  const data = XLSX.utils.sheet_to_json(sheet, {
    header: 1,
    raw: true,
    defval: null,
  });

  // Assuming the first row is the header
  const headers = data[0];
  const serialNumberIndex = headers.indexOf("Serial number");
  const dateOfPurchaseIndex = headers.indexOf("Date of purchase");
  const placeOfPurchaseIndex = headers.indexOf("Place of purchase");
  const typeOfPlaceIndex = headers.indexOf("Purchase place type");
  // const roomIndex = headers.indexOf("Room"); // Added index for Room

  // Filter out any empty rows or rows with empty required cells
  const validRows = data.filter((row, index) => {
    // Skip header row
    if (index === 0) return false;
    // Check if all required cells are not null or empty string
    return (
      row[serialNumberIndex] &&
      row[dateOfPurchaseIndex] &&
      row[placeOfPurchaseIndex] &&
      row[typeOfPlaceIndex]
      // row[roomIndex] // Check for Room as well
    );
  });

  // Map the valid rows to objects with the data
  return validRows.map((row) => {
    // Format the date of purchase if it exists and is a number (Excel date format)
    const purchaseDate =
      typeof row[dateOfPurchaseIndex] === "number"
        ? formatDate(row[dateOfPurchaseIndex])
        : row[dateOfPurchaseIndex]; // Or handle non-date formats as needed

    return {
      serial_number: row[serialNumberIndex].toString(),
      purchase_date: purchaseDate, // Now formatted as YYYY-MM-DD
      place_of_purchase: row[placeOfPurchaseIndex],
      type_of_place: row[typeOfPlaceIndex],
      // room: row[roomIndex], // Include the Room field
    };
  });
}

export { readExcelFile, extractDataFromWorkbook };
