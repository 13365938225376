import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";
import { i18n } from "@/locales/i18n";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "application/json",
  };
}

async function checkProduct(serialnumber, setErrorMessage) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/check-product-serial?serial=${serialnumber}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      if (response.data.results[0].product_exists === true) {
        if (response.data.results[0].is_registered === false) {
          getProductInfoAndAddToStore(serialnumber);
        } else {
          setErrorMessage(
            i18n.global.t("registerProductsJS_checkProduct_alreadyRegistered")
          );
        }
      } else {
        setErrorMessage(
          i18n.global.t("registerProductsJS_checkProduct_notExist")
        );
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 500) {
        setErrorMessage(
          i18n.global.t("registerProductsJS_checkProduct_errorDuringCheck")
        );
      }
      console.error("Error checking the product:", error);
      throw error;
    });
}

async function checkProductForExcelAndRegister(data, setErrorMessage) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/check-product-serial?serial=${data.serial_number}&setErrorMessage`;
  const url2 = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/register-products`;
  const organization = store.state.userData.private_organization_id;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      if (response.data.results[0].product_exists === true) {
        console.log(response.data.results);
        if (response.data.results[0].is_registered === false) {
          const requestData = {
            organization,
            products: [data],
          };
          try {
            axiosInstance
              .post(url2, requestData, {
                headers: getHeaders(),
              })
              .then((response) => {
                // Here you get the status
                console.log("Status:", response.status);
                // And here you get the response data/content
                console.log("Response Data:", response.data);
              });
            // console.log(response.data);
          } catch (error) {
            console.error(error);
            throw error;
          }
        } else {
          setErrorMessage(data.serial_number + " is already registered");
        }
      } else {
        setErrorMessage(data.serial_number + " does not exist");
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 500) {
        setErrorMessage("Error during serial number check. Please try again");
      }
      console.error("Error checking the product:", error);
      throw error;
    });
}

async function getProductInfoAndAddToStore(serialnumber) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${serialnumber}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      //console.log("Product data:", response.data); // Add this line
      store.dispatch("addProductToPreRegistered", response.data);
      // console.log("getPreRegisteredProducts:", store.getters.getPreRegisteredProducts);
    })
    .catch((error) => {
      console.error("Error adding product to the store", error);
      // store.commit(
      //   "setErrorMessage",
      //   error.config.url + " " + error.response.data
      // );
      throw error;
    });
}

async function addProductToUserAccount() {
  const organization = store.state.userData.private_organization_id;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/register-products`;

  const products = store.getters.getPreRegisteredProducts.map((product) => ({
    place_of_purchase: product.place_of_purchase,
    purchase_date: product.purchase_date,
    serial_number: product.serial_number,
    type_of_place: product.type_of_place,
    room: product.room,
  }));

  const requestData = {
    organization,
    products,
  };

  try {
    const response = await axiosInstance.post(url, requestData, {
      headers: getHeaders(),
    });

    const productsResponse = response.data.products;

    // Separate successful and failed products
    const successfulProducts = productsResponse.filter(
      (product) => product.status === "OK"
    );

    const failedProducts = productsResponse.filter(
      (product) => product.status === "Failed"
    );

    // Return an object containing both successful and failed products
    return { successfulProducts, failedProducts };
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const productsResponse = error.response.data.products;

      // Separate successful and failed products
      const successfulProducts = productsResponse.filter(
        (product) => product.status === "OK"
      );

      const failedProducts = productsResponse.filter(
        (product) => product.status === "Failed"
      );

      // Return an object containing both successful and failed products
      return { successfulProducts, failedProducts };
    } else {
      // Handle any unexpected errors
      store.commit(
        "setErrorMessage",
        error.config.url +
          " " +
          (error.response ? error.response.data : error.message)
      );
      throw error;
    }
  }
}



async function unregisterProduct(productId) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/register-products/${productId}`; // error here - roducts to products
  return axiosInstance
    .delete(url, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

async function updadeProduct(product) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${product.serial_number}`; // error here - produc to products
  return axiosInstance
    .put(url, product, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating the product: ", error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

async function fetchPreRegisteredProducts() {
  const encodedemail = encodeURIComponent(store.state.userData.email);
  // console.log(email);
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/pending-requests?email=${encodedemail}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting preregisterred products", error);
      // store.commit(
      //   "setErrorMessage",
      //   error.config.url + " " + error.response.data
      // );
      throw error;
    });
}

async function postPreRegisteredProducts(preregisterredproducts) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/pre-register-products`;
  return axiosInstance
    .post(url, preregisterredproducts, {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error updating preregisterred products", error);
      // store.commit(
      //   "setErrorMessage",
      //   error.config.url + " " + error.response.data
      // );
      throw error;
    });
}

export {
  checkProduct,
  getProductInfoAndAddToStore,
  addProductToUserAccount,
  unregisterProduct,
  updadeProduct,
  fetchPreRegisteredProducts,
  postPreRegisteredProducts,
  checkProductForExcelAndRegister,
};
