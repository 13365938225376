<template>
    <UpperMenuShort></UpperMenuShort>
    <div class="background-image"></div>
    <div class="userSelectorModal">
        <div class="content">
            <!-- Use optional chaining to safely access nested properties -->
            <div class="headline">
                <h2>{{ $t("accountSelector_headline_h2") }}</h2>
                <p>{{ $t("accountSelector_headline_p") }}</p>
            </div>
            <div v-for="account in accounts.data.content" :key="account.id">
                <div class="account-box">
                    <button class="account-button" @click="selectAccount(account)">
                        <img src="@/assets/mygenelec_icons_2024.png">
                        <p><b>{{ account.name }}</b></p>
                    </button>
                </div>
            </div>
            <button class="logout-button" @click="LogOut">{{ $t("accountSelector_headline_logoutButton") }}</button>
        </div>
    </div>
</template>

<script>
import getAccounts from "@/api/accountSelector"
import UpperMenuShort from "@/components/navigation/UpperMenuShort.vue"
import KeyCloakService from "@/security/KeyCloakService.js";

export default {
    name: "UserSelector",
    components: {
        UpperMenuShort,
    },
    data() {
        return {
            // Initialize accounts with a structure that prevents the error
            accounts: { data: { content: [] } },
        };
    },
    methods: {
        selectAccount(account) {
            console.log(account);
            sessionStorage.setItem("selectedAccountId", account.id);
            sessionStorage.setItem("selectedAccountPrivateOrg", account.privat_org);
            window.location.reload();
        },
        LogOut() {
            KeyCloakService.CallLogOut();
        },

    },
    async created() {
        try {
            this.accounts = await getAccounts();
            console.log(this.accounts.data.content);
            if (this.accounts.data.content.length == 1) {
                this.selectAccount(this.accounts.data.content[0].id);
                window.location.reload();
            }
        } catch (error) {
            console.error("Failed to fetch accounts:", error);
            // Handle error (e.g., set an error message or use a fallback value)
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/sass/userSelector.scss"></style>
