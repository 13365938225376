<template>
  <div class="spinner">
    <svg width="65px" height="65px" alt="Cargando imagen" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"
      draggable="false">
      <circle stroke-width="6" fill="none" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "VSpinner",
};
</script>

<style lang="css" scoped>
.spinner {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,
      255,
      255,
      0.4);
  /* this will create a white overlay with 70% opacity */
}

svg {
  margin: 1em;
  animation: rotator 1.3s linear infinite;
  stroke: #399;
}

circle {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 2s ease-in-out infinite;
}

@keyframes rotator {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 188;
  }

  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(0deg);
  }

  to {
    stroke-dashoffset: 188;
    transform: rotate(360deg);
  }
}
</style>
