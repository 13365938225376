<template>
    <div ref="shadowRootContainer"></div>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
    name: 'DynamicShadowDomBlock',
    props: ['content'],
    data() {
        return {
            shadowRoot: null,
            markdownParser: new MarkdownIt({
                html: true, // Enable HTML tags in source
            }),
        };
    },
    watch: {
        content(newContent) {
            this.updateShadowDom(newContent);
        }
    },
    mounted() {
        this.shadowRoot = this.$refs.shadowRootContainer.attachShadow({ mode: 'open' });
        this.updateShadowDom(this.content);
    },
    methods: {
        updateShadowDom(content) {
            if (this.shadowRoot) {
                // First pass: Render markdown to HTML
                const parsedContent = this.markdownParser.render(content);
                // Second pass: Set the parsed HTML as innerHTML
                this.shadowRoot.innerHTML = parsedContent;
            }
        }
    }
};
</script>

<style scoped></style>
