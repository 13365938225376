async function fetchDynamicContentData() {
  const url =
    "https://cdn.contentful.com/spaces/4zjnzn055a4v/environments/test/entries/2AfZlPX3HtKYAVUKJF1Im0?access_token=z9v1qjcBzcW2oxfg0Polr-9KXTSZosguXOdTASk3eM4&locale=fi-FI";

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const rawData = await response.json();
    // console.log(rawData.fields.content);

    return rawData.fields.content;
  } catch (error) {
    console.error("Fetch error:", error);
  }
}

export default fetchDynamicContentData;
