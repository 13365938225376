<template>
  <div class="main">
    <div class="dropzone-container" :style="isDragging && 'border: 3px solid green;'" @dragover="dragover"
      @dragleave="dragleave" @drop="drop">
      <input type="file" multiple name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
        accept=".pdf,.jpg,.jpeg,.png" />

      <label for="fileInput" class="file-label">
        <div v-if="isDragging">{{ $t("dragAndDropBox_releaseToDrop_dragging") }}</div>
        <div v-else :hidden="files.length > 0">
          {{ $t("dragAndDropBox_releaseToDrop_part1") }} <u>{{ $t("dragAndDropBox_releaseToDrop_part2_underlined") }}</u>.
        </div>
        <!-- <div v-else>Drop the receipt here or <u>click here</u> to upload.</div> -->
      </label>
      <!-- Note: Only add the code block below -->
      <div class="preview-container" v-if="files">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div class="d-flex justify-content-center align-items-center">
            <!-- <img class="preview-img" :src="generateURL(file)" /> -->
            <p class="text-center m-0">
              {{ truncateFileName(file.name) }}
            </p>
          </div>
          <div>
            <button class="ml-2 cross-button" type="button" @click="remove" title="Remove file">
              <img src="@/assets/icons/delete.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropFile",
  data() {
    return {
      isDragging: false,
      files: [],
      dragTimeout: null,
      maxFiles: 1,
      incomingFiles: null,
    };
  },
  methods: {
    onChange() {
      this.incomingFiles = Array.from(this.$refs.file.files);

      // Check if new files exceed the limit
      if (this.incomingFiles && this.incomingFiles.length > this.maxFiles) {
        alert(`You can only upload 1 file.`);
        return;
      }

      // Check file type and size
      // if (this.incomingFiles.some((file) => !file.type.match("image/", "application/pdf"))) {
      //   alert("Only image files are allowed (jpg, jpeg, png, pdf).");
      //   return;
      // }

      if (this.incomingFiles.some((file) => file.size > 5242880)) {
        // 5 MB in bytes
        alert(this.$t("productRegistration_methods_alert_fileTooLarge"));
        return;
      }

      // Replace existing files with new ones
      this.files = this.incomingFiles;
      if (this.files.length > 0) {
        this.$emit("addedReceipt", this.files[0]); // Emitting only the first file
      }
    },

    dragover(e) {
      e.preventDefault();
      clearTimeout(this.dragTimeout);
      this.isDragging = true;
    },
    dragleave() {
      // Debounce the removal of the drag state
      this.dragTimeout = setTimeout(() => {
        this.isDragging = false;
      }, 100); // Adjust the timeout as needed
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove() {
      this.files = [];
      if (this.$refs.file) {
        this.$refs.file.value = ""; // Reset the file input
      }
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    truncateFileName(name) {
      const maxLength = 25; // Maximum display length
      if (name.length > maxLength) {
        return name.substring(0, maxLength) + "..."; // Truncate and add ellipsis
      }
      return name;
    },
  },
};
</script>
<style scoped lang="scss" src="@/assets/sass/dropFile.scss"></style>
