import { createI18n } from "vue-i18n";
import fetchLocalizationData from "@/locales/i18-data";
import { defineRule } from "vee-validate";

const messages = await fetchLocalizationData();

const i18n = createI18n({
  locale: "en", // Change this value to set the initial locale
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true,
  // warnHtmlMessage: false,
});

defineRule("required", (value) => {
  if (!value || !value.length) {
    return i18n.global.t("error_required");
  }
  return true;
});

defineRule("lengthBetween", (value, [min, max]) => {
  if (value.length < min || value.length > max) {
    return i18n.global.t("error_lengthBetween", { min, max });
  }
  return true;
});

defineRule("noLeadingSpace", (value) => {
  if (value.startsWith(" ")) {
    return i18n.global.t("error_noLeadingSpace");
  }
  return true;
});

defineRule("email", (value) => {
  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(value)) {
    return "This field must be a valid email";
  }
  return true;
});

export { i18n };
