<template>
    <div class="main-block">
        <div class="payments-page">
            <div class="headline">
                <h2>
                    <b>{{ $t("paymentsPage_headline_h2") }}</b>
                </h2>
                <p>{{ $t("paymentsPage_headline_p") }}</p>
            </div>
            <div class="accordion-box-main">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header accordion-header-custom" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                <h5><b> {{ $t("paymentsPage_accordionBox_accordionTitle_Sub") }}</b></h5>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse accordion-collapse-custom collapse "
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body p-0">

                                <div class="accordion" id="accordionExample">
                                    <div v-if="this.onlySubscription.length > 0">
                                        <div class="accordion-item" v-for="(subscription, index) in sortedSubscriptions"
                                            :key="'subscription-' + subscription.id">
                                            <h2 class="accordion-header"
                                                :id="'heading-subscription-' + subscription.id">
                                                <button class="accordion-button collapsed accordion-button-custom"
                                                    :class="{ 'border-upper-sub-custom': index !== 0 }" type="button"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapse-subscription-' + subscription.id"
                                                    aria-expanded="false"
                                                    :aria-controls="'collapse-subscription-' + subscription.id">
                                                    <div class="accordion-button-headings-sub">
                                                        <span>{{ $t(subscription.service_plan_name) }}</span>
                                                        <span>{{ subscription.status }}</span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div :id="'collapse-subscription-' + subscription.id"
                                                class="accordion-collapse collapse"
                                                :aria-labelledby="'heading-subscription-' + subscription.id">
                                                <!-- data-bs-parent="#accordionExample" -->
                                                <div class="accordion-body">
                                                    <!-- Accordion body content here -->
                                                    <div class="header-of-the-accordion">
                                                        <div class="item">
                                                            <b>{{
                                                                $t("paymentsPage_accordionBox_accordion_body_orderNumber")
                                                                }}</b>
                                                            <p>{{ subscription.reference_number }}</p>
                                                        </div>
                                                        <div class="item">
                                                            <b>{{
                                                                $t("paymentsPage_accordionBox_accordion_body_startDate")
                                                                }}</b>
                                                            <p>{{ convertDate(subscription.start_date) }}</p>
                                                        </div>
                                                        <div class="item">
                                                            <b>{{
                                                                $t("paymentsPage_accordionBox_accordion_body_nextDate")
                                                                }}</b>
                                                            <p>{{ convertDate(subscription.next_billing_date) }}</p>
                                                        </div>
                                                        <div class="item"
                                                            v-if="subscription.payments && subscription.payments.length > 0">
                                                            <b>{{
                                                                $t("paymentsPage_accordionBox_accordion_body_latestPayment")
                                                                }}</b>
                                                            <p>{{ subscription.payments[0].amount }} {{
                                                                subscription.payments[0].currency }}</p>
                                                        </div>
                                                        <div class="item">
                                                            <b>{{ $t("paymentsPage_accordionBox_accordion_body_trial")
                                                                }}</b>
                                                            <p>{{ subscription.trial_days }}</p>
                                                        </div>
                                                        <a class="update-btn"
                                                            :href="subscription.payment_app_update_url">{{
                                                                $t("paymentsPage_accordionBox_accordion_body_update") }}</a>
                                                        <a class="cancel-btn"
                                                            :href="subscription.payment_app_cancel_url">{{
                                                                $t("paymentsPage_accordionBox_accordion_body_cancel") }}</a>
                                                    </div>

                                                    <!-- <div class="interactive-buttons">
                                                        <button class="update-btn">Update subscription</button>
                                                        <button class="cancel-btn">Cancel subscription</button>
                                                    </div> -->
                                                    <div class="payments">
                                                        <div class="payments-headline"><b>{{
                                                            $t('paymentsPage_accordionBox_accordion_body_payments')
                                                                }}</b>
                                                        </div>
                                                        <div class="payments-block"
                                                            v-if="subscription.payments && subscription.payments.length">
                                                            <div class="payments-card"
                                                                v-for="payment in subscription.payments"
                                                                :key="'subscription-' + payment.id">
                                                                <div>
                                                                    <div class="item">
                                                                        <p>{{ convertDate(payment.created) }}</p>
                                                                        <p>{{ payment.amount }} {{ payment.currency }}
                                                                        </p>
                                                                    </div>
                                                                    <div class="item">
                                                                        <p>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_type')
                                                                            }}
                                                                        </p>
                                                                        <p>{{ payment.event_type }}</p>
                                                                    </div>
                                                                    <div class="item">
                                                                        <p>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_reference')
                                                                            }}
                                                                        </p>
                                                                        <p>{{ payment.paddle_order_id }}</p>
                                                                    </div>
                                                                    <div class="item receipt">
                                                                        <p class="display-none-on-mobile"></p>
                                                                        <a :href="payment.receipt_url"><button>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_downloadReceipt')
                                                                                }}</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>{{
                                                            $t('paymentsPage_accordionBox_accordion_body_noPaymentsFound')
                                                            }}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p> {{ $t('paymentsPage_accordionBox_accordion_body_noSubscriptionFound') }}
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header accordion-header-custom" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                aria-controls="flush-collapseTwo">
                                <h5><b>{{ $t("paymentsPage_accordionBox_accordionTitle_Purchases") }}</b></h5>
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse accordion-collapse-custom collapse  "
                            aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body p-0">

                                <div class="accordion" id="accordionExample">
                                    <div v-if="this.onlyPurchases.length > 0">
                                        <div class="accordion-item" v-for="(purchase, index) in this.onlyPurchases"
                                            :key="'purchase-' + purchase.id">
                                            <h2 class="accordion-header" :id="'heading-purchase-' + purchase.id">
                                                <button class="accordion-button collapsed accordion-button-custom"
                                                    :class="{ 'border-upper-sub-custom': index !== 0 }" type="button"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapse-purchase-' + purchase.id"
                                                    aria-expanded="false"
                                                    :aria-controls="'collapse-purchase-' + purchase.id">
                                                    <div class="accordion-button-headings-sub">
                                                        <span>{{ $t(purchase.service_plan_name) }}</span>
                                                        <span v-if="purchase.payments && purchase.payments.length > 0">
                                                            {{ purchase.payments[0].amount }} {{
                                                            purchase.payments[0].currency }}
                                                        </span>
                                                        <span v-else>
                                                            {{ $t('paymentsPage_accordionBox_accordion_body_free') }}
                                                        </span>
                                                    </div>
                                                </button>

                                            </h2>
                                            <div :id="'collapse-purchase-' + purchase.id"
                                                class="accordion-collapse collapse "
                                                :aria-labelledby="'heading-purchase-' + purchase.id">
                                                <div class="accordion-body">
                                                    <div class="payments">
                                                        <div class="payments-block"
                                                            v-if="purchase.payments && purchase.payments.length > 0">
                                                            <div class="payments-card-list"
                                                                v-for="payment in purchase.payments"
                                                                :key="'purchase-' + payment.event_id">
                                                                <div class="payments-row">
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_orderNumber')
                                                                            }}</b>
                                                                        <p>{{ purchase.payment_app_order_id }}</p>
                                                                    </div>
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_startDate')
                                                                            }}</b>
                                                                        <p>{{ convertDate(payment.created) }}</p>
                                                                    </div>
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_status')
                                                                            }}</b>
                                                                        <p>{{ payment.event_type }}</p>
                                                                    </div>
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_reference')
                                                                            }}</b>
                                                                        <p>{{ payment.paddle_order_id }}</p>
                                                                    </div>
                                                                    <div class="item-purchases receipt">
                                                                        <p class="display-none-on-mobile"></p>
                                                                        <a :href="payment.receipt_url"><button>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_downloadReceipt')
                                                                                }}</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else style="padding: 26px">{{
                                                            $t('paymentsPage_accordionBox_accordion_body_noPaymentsFound')
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>{{ $t('paymentsPage_accordionBox_accordion_body_noPurchasesFound') }}</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header accordion-header-custom" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree" aria-expanded="false"
                                aria-controls="flush-collapseThree">
                                <h5><b>{{ $t("paymentsPage_accordionBox_accordionTitle_LastPayments") }}</b></h5>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse accordion-collapse-custom collapse "
                            aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body p-0">

                                <div class="accordion" id="accordionExample">
                                    <div v-if="this.allOrders.length > 0">
                                        <div class="accordion-item" v-for="(order, index) in sortedUpdatedOrders"
                                            :key="'lastPayment-' + order.created">
                                            <h2 class="accordion-header" :id="'heading-lastPayment-' + order.id">
                                                <button class="accordion-button collapsed accordion-button-custom"
                                                    :class="{ 'border-upper-sub-custom': index !== 0 }" type="button"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapse-lastPayment-' + order.id"
                                                    aria-expanded="false"
                                                    :aria-controls="'collapse-lastPayment-' + order.id">
                                                    <div class="accordion-button-headings-sub">
                                                        <span>{{ convertDate(order.created) }}</span>
                                                        <!-- <span>{{ order.payments.amount }}</span> -->


                                                        <span class="display-none-on-mobile"
                                                            v-if="order.payments && order.payments.length > 0">
                                                            {{ order.payments[0].amount }} {{
                                                            order.payments[0].currency }}
                                                        </span>
                                                        <span class="display-none-on-mobile" v-else>
                                                            {{ $t('paymentsPage_accordionBox_accordion_body_free') }}
                                                        </span>
                                                        <span v-if="order.payments && order.payments.length > 0">{{
                                                            order.payments[0].event_type }}</span>
                                                        <span v-else>{{
                                                            }}</span>
                                                    </div>
                                                </button>

                                            </h2>
                                            <div :id="'collapse-lastPayment-' + order.id"
                                                class="accordion-collapse collapse"
                                                :aria-labelledby="'heading-lastPayment-' + order.id">
                                                <div class="accordion-body">
                                                    <div class="payments">
                                                        <!-- <span>{{ order }}</span> -->
                                                        <div class="payments-block"
                                                            v-if="order.payments && order.payments.length > 0">
                                                            <div class="payments-card-list"
                                                                v-for="payment in order.payments"
                                                                :key="'lastPayment-' + payment.event_id">
                                                                <div class="payments-row">
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_servicePlan')
                                                                            }}</b>
                                                                        <p>{{ $t(order.service_plan_name) }}</p>
                                                                    </div>
                                                                    <div class="item-purchases display-none-on-desktop">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_price')
                                                                        }}</b>
                                                                        <!-- <p>{{ payment.paddle_order_id }}</p> -->
                                                                        <p>
                                                                            {{ order.payments[0].amount }} {{
                                                                                order.payments[0].currency }}
                                                                        </p>
                                                                    </div>
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_orderNumber')
                                                                        }}</b>
                                                                        <p v-if="order.payment_app_order_id">{{
                                                                            order.payment_app_order_id }}</p>
                                                                        <p v-else>N/A</p>
                                                                    </div>
                                                                    <div class="item-purchases">
                                                                        <b>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_reference')
                                                                        }}</b>
                                                                        <p>{{ payment.paddle_order_id }}</p>
                                                                    </div>
                                                                    <div class="item-purchases receipt">
                                                                        <p class="display-none-on-mobile"></p>
                                                                        <a :href="payment.receipt_url"><button>{{
                                                                            $t('paymentsPage_accordionBox_accordion_body_downloadReceipt')
                                                                        }}</button></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else style="padding: 26px">{{
                                                            $t('paymentsPage_accordionBox_accordion_body_noPaymentsFound')
                                                        }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-else style="padding: 26px">
                                        {{ $t('paymentsPage_accordionBox_accordion_body_noPaymentsFound') }}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import { getAllPurchases, getSpecificPayments } from "@/api/purchases";
import { getServicePlan } from "@/api/serviceMethods";

export default {
    name: "PaymentsPage",

    data() {
        return {
            allOrders: [],
            onlySubscription: [],
            onlyPurchases: [],
            subscriptionPayments: {},
            purchasePayments: {},
            services: [],
            updatedOrders: []
        };
    },
    methods: {
        async sortedOrderList() {
            this.allOrders = await getAllPurchases();

            for (const order of this.allOrders) {
                const service = await getServicePlan(order.service_plan_id);
                const payments = await getSpecificPayments(order.id);
                order.payments = payments;
                if (order.payments && Array.isArray(order.payments)) {
                    order.payments.sort((a, b) => new Date(b.created) - new Date(a.created));
                }
                if (service) {
                    order.service_plan_name = service.name_key;
                }

                if (order.purchase_type === 'recurring' && order.payment_type === 'payment_app') {
                    this.onlySubscription.push(order);
                } else if (order.purchase_type === 'one-time' && (order.payment_type === 'payment_app' || order.payment_type === 'free_of_charge')) {
                    this.onlyPurchases.push(order);
                }
                this.updatedOrders.push(order);
            }
        },

        convertDate(receivedDate) {
            if (!receivedDate) {
                // If receivedDate is null, undefined, or empty string, return a default value
                return "N/A";
            }
            const date = new Date(receivedDate);
            if (isNaN(date.getTime())) {
                // If receivedDate cannot be parsed into a Date object, return a default value
                return "N/A";
            }
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
            const year = date.getFullYear();
            if (this.$store.state.activeLocale === 'ja') {

                return `${year}-${month}-${day}`

            } else {

                return `${day}-${month}-${year}`
            }

        },
    },
    async created() {
        await this.sortedOrderList();
        // await this.fetchAllPayments()
    },
    computed: {
        sortedSubscriptions() {
            return this.onlySubscription.slice().sort((a, b) => a.service_plan_name.localeCompare(b.service_plan_name));
        },
        sortedPurchases() {
            // Sorting purchases from newest to oldest
            return this.onlyPurchases.slice().sort((a, b) => new Date(b.created) - new Date(a.created));
        },
        sortedUpdatedOrders() {
            // Sorting updated orders from newest to oldest
            return this.updatedOrders.slice().sort((a, b) => new Date(b.created) - new Date(a.created));
        },

    },

};
</script>

<style lang="scss" scoped>
h5 {
    margin: 0;
}

.main-block {
    background-color: var(--bg-grey);
    margin: 0px;
    padding: 0px;
    height: calc(100vh - var(--UpperMenuHeight)) !important;
    overflow-y: scroll;
}

.payments-page {
    margin: 24px;
    margin-bottom: 150px;
    display: flex;
    flex-flow: column wrap;
}

.accordion-box-main {
    .accordion {
        .accordion-item {
            .accordion-header-custom {
                border-bottom: 1px solid;

            }

            .accordion-collapse-custom {
                border-bottom: 1px solid;
                border-radius: 0;
            }

            .accordion-body {
                .header-of-the-accordion {
                    margin: 0px 26px;
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    grid-template-rows: auto auto;
                    grid-gap: 10px;
                    /* Adjust based on your spacing preference */
                    grid-template-areas:
                        "item1 item2 item3 item4 item5"
                        "btn1 . btn2 . .";
                }

                .header-of-the-accordion .item:nth-child(1) {
                    grid-area: item1;
                }

                .header-of-the-accordion .item:nth-child(2) {
                    grid-area: item2;
                }

                .header-of-the-accordion .item:nth-child(3) {
                    grid-area: item3;
                }

                .header-of-the-accordion .item:nth-child(4) {
                    grid-area: item4;
                }

                .header-of-the-accordion .item:nth-child(5) {
                    grid-area: item5;
                }

                .header-of-the-accordion .update-btn {
                    grid-area: btn1;
                }

                .header-of-the-accordion .cancel-btn {
                    grid-area: btn2;
                }

                .update-btn,
                .cancel-btn {
                    justify-self: start;
                    /* Adjust button alignment if needed */
                }
            }
        }
    }
}

.headline {

    h2 {
        border-left: 6px solid var(--Green);
        font-weight: 700;
        padding: 15px;
        margin-bottom: 25px;
    }

    p {
        max-width: 800px;
    }
}

.accordion-button-headings-sub {
    width: 100%;
    display: flex;
    // border-bottom: 1px solid;

    span {
        width: 50%;
    }
}

.accordion-button-custom {
    padding: 10px 0px;
}

.border-upper-sub-custom {
    border-top: 1px solid grey;
}

.payments-headline {
    margin: 26px 0px 10px 26px;

}

/* Assume 4 cards are desired per row by default */
.payments-block {
    --cards-per-row: 4;
    /* Default number of cards per row */
    --card-gap: 20px;
    /* Gap between cards */
    display: flex;
    flex-wrap: wrap;
    gap: var(--card-gap);
    justify-content: flex-start;
    padding: 0px 26px;
}

.payments-card {
    flex: 1 0 calc((100% - (var(--cards-per-row) - 1) * var(--card-gap)) / var(--cards-per-row));
    //border: 1px solid black;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;

    .item {
        margin-bottom: 20px;

        p {
            margin: 0px;
        }
    }


}

.receipt {
    a {


        button {
            border: none;
            background-color: transparent;
            color: var(--Green);
            padding: 0;
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

.payments-card-list {
    width: 100%;

    .payments-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}

.update-btn,
.cancel-btn {
    border: none;
    background-color: transparent;
    color: var(--Green);
    padding: 0;
    font-weight: bold;
    text-decoration: underline;
}

.cancel-btn {
    color: Grey;
}

/* Add invisible placeholders after all .payments-card elements */
.payments-block:after {
    content: '';
    width: calc((100% - (var(--cards-per-row) - 1) * var(--card-gap)) / var(--cards-per-row));
    flex: auto;
    flex-grow: 999;
    /* Large flex-grow ensures it takes up as much space as possible, without affecting layout */
}

/* Adjustments for different screen sizes */
@media (max-width: 1439px) and (min-width: 1220px) {
    .payments-block {
        --cards-per-row: 3;
        /* 3 cards per row for this screen width range */
    }
}

@media (max-width: 1219px) and (min-width: 768px) {
    .payments-block {
        --cards-per-row: 2;
        /* 2 cards per row for this screen width range */
    }
}

@media (max-width: 767px) {
    .payments-block {
        --cards-per-row: 1;
        --card-gap: 0px;
        /* 1 card per row for screens narrower than 1024px */
    }

    .accordion-box-main {
        .accordion {
            .accordion-item {
                .accordion-body {
                    .header-of-the-accordion {
                        display: flex;
                        flex-flow: column;
                    }
                }
            }
        }
    }

    .payments-card-list {
        .payments-row {
            flex-flow: column;
        }
    }
}
</style>