import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";
import { updadeProduct } from "@/api/registerProducts";
// import getUserProducts from "@/api/getUserProducts";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "application/json",
  };
}

async function getLocations() {
  const ownerOrgId = store.state.userData.private_organization_id;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/organizations/${ownerOrgId}/locations`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      store.dispatch("updateUserLocationData", response.data.content);
      return response.data.content;
    })
    .catch((error) => {
      console.error("Error getting user locations:", error);
      throw error;
    });
}

async function getRooms(id) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/locations/${id}/rooms`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data.content;
    })
    .catch((error) => {
      console.error("Error getting user locations:", error);
      throw error;
    });
}

async function createLocation(formData) {
  const ownerOrgId = store.state.userData.private_organization_id;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/locations`;

  formData.owner_organization_id = ownerOrgId;

  return axiosInstance
    .post(url, formData, {
      headers: getHeaders(),
    })
    .catch((error) => {
      console.error("Error creating location:", error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

async function getRoomInfo(id) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/rooms/${id}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting room information:", error);
      throw error;
    });
}

async function getLocationInfo(id) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/locations/${id}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting location information:", error);
      throw error;
    });
}

async function createRoom(formCreateRoom) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/rooms`;

  return axiosInstance
    .post(url, formCreateRoom, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error creating room:", error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

async function updateRoom(formCreateRoom) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/rooms/${formCreateRoom.id}`;

  return axiosInstance
    .put(url, formCreateRoom, {
      headers: getHeaders(),
    })
    .catch((error) => {
      console.error("Error creating room:", error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

async function deleteRoom(roomId) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/rooms/${roomId}`;

  return axiosInstance
    .delete(url, {
      headers: getHeaders(),
    })
    .catch((error) => {
      console.error("Error deleteing the room:", error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    });
}

const updateLocation = async (updatedData) => {
  const locationId = updatedData.id;
  // console.log(locationId, updatedData);
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/locations/${locationId}`;
  try {
    const response = await axiosInstance.put(url, updatedData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update location: ", error);
    store.commit(
      "setErrorMessage",
      error.config.url + " " + error.response.data
    );
    throw error;
  }
};

async function addProductToTheRoom(roomId) {
  const organizationID = store.state.userData.private_organization_id;

  // If there is more than one product, loop through all products
  for (const product of store.state.temporaryAssignedProducts) {
    const payload = {
      ...product,
      room: roomId,
      organization: organizationID,
    };

    try {
      await updadeProduct(payload);

      // Find the product in userProducts and update its room property
      const productIndex = store.state.userProducts.findIndex(
        (p) => p.id === product.id
      );
      if (productIndex !== -1) {
        store.state.userProducts[productIndex].room = roomId;
      }
    } catch (error) {
      console.error(error);
      store.commit(
        "setErrorMessage",
        error.config.url + " " + error.response.data
      );
      throw error;
    }
  }

  store.commit("clearTemporaryAssignedProducts");
  // If you want to fetch the updated list from the server, you can call getUserProducts
  // await getUserProducts();
}

async function deleteLocation(locationId) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/user-registry/locations/${locationId}`;
  try {
    await axiosInstance.delete(url, {
      headers: getHeaders(),
    });
  } catch (error) {
    console.error(error);
    store.commit(
      "setErrorMessage",
      error.config.url + " " + error.response.data
    );
    throw error;
  }
}

async function unassignProduct(productID) {
  const product = store.state.userProducts.find(
    (product) => product.serial_number === productID
  );
  product.room = null;
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${productID}`;
  try {
    await axiosInstance.put(url, product, {
      headers: getHeaders(),
    });
  } catch (error) {
    console.error(error);
    store.commit(
      "setErrorMessage",
      error.config.url + " " + error.response.data
    );
    throw error;
  }
}

export {
  getLocations,
  getRooms,
  createLocation,
  createRoom,
  updateLocation,
  addProductToTheRoom,
  deleteLocation,
  getRoomInfo,
  getLocationInfo,
  unassignProduct,
  deleteRoom,
  updateRoom,
};
