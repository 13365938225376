<template>
  <div class="MainRow">
    <div class="item menuicon d-flex align-items-center">
      <button @click="$store.dispatch('toggleSidebar')" class="toggle-sidebar-button">
        <img src="@/assets/icons/burger.svg" alt="Burger icon" v-if="!$store.state.sidebarVisible" />
        <img src="@/assets/iconsFromFigma/cross.svg" alt="Burger icon" v-else />
      </button>
      <!-- <i class="icon default-icon icon-reorder" id="openNavIcon" style="display: inline;"></i> -->
    </div>
    <div class="item logo">
      <img @click="$router.push('/')" src="@/assets/genelec-logo.png" />
    </div>
    <div class="item account">
      <div class="lang-selection">
        <div class="dropdown-center" ref="langDropdown">
          <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ ActiveLocale }}
            <font-awesome-icon :icon="['fas', 'chevron-right']" :style="{
              transform: `rotate(${langIconRotation}deg)`,
              transition: 'transform 0.3s ease-in-out',
              marginLeft: '15px',
            }" />
          </button>
          <ul class="dropdown-menu">
            <li @click="changeLocale('en')">
              <a class="dropdown-item" href="#">English</a>
            </li>
            <li @click="changeLocale('fi')">
              <a class="dropdown-item" href="#">Suomi</a>
            </li>
            <li @click="changeLocale('sv')">
              <a class="dropdown-item" href="#">Svenska</a>
            </li>
            <li @click="changeLocale('ja')">
              <a class="dropdown-item" href="#">日本語</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="account-info">
        <div class="dropdown-center" ref="accountDropdown">
          <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center">
              <img src="@/assets/icons/profile_pic.png" />
              <p class="m-0">
                {{ userNameAndSurname }}
                <font-awesome-icon :icon="['fas', 'chevron-right']" :style="{
                  transform: `rotate(${accountIconRotation}deg)`,
                  transition: 'transform 0.3s ease-in-out',
                  marginLeft: '15px',
                }" />
              </p>
            </div>
          </button>
          <ul class="dropdown-menu">
            <!-- Start Only for the mobile and tablet-->
            <li class="tablet-only dropdown-submenu" @click="toggleSubMenu($event)">
              <a class="dropdown-item dropdown-toggle" href="#">{{
                $t("mainmenu_language")
              }}</a>
              <ul class="dropdown-menu" v-show="showSubMenu" aria-labelledby="dropdownMenuClickableOutside">
                <li @click="changeLocale('en')">
                  <a class="dropdown-item" href="#">English</a>
                </li>
                <li @click="changeLocale('fi')">
                  <a class="dropdown-item" href="#">Suomi</a>
                </li>
                <li @click="changeLocale('sv')">
                  <a class="dropdown-item" href="#">Svenska</a>
                </li>
                <li @click="changeLocale('ja')">
                  <a class="dropdown-item" href="#">日本語</a>
                </li>
              </ul>
            </li>
            <!-- End Only for the mobile and tablet-->

            <li @click="$router.push('/profile')">
              <a class="dropdown-item" href="#">{{ $t("mainmenu_profile") }}</a>
            </li>
            <li @click="$router.push('/payments')">
              <a class="dropdown-item" href="#">{{ $t("mainmenu_payments") }}</a>
            </li>
            <li @click="LogOut()">
              <a class="dropdown-item" href="#">{{ $t("mainmenu_logout") }}</a>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KeyCloakService from "@/security/KeyCloakService.js";
import { getFieldValue } from "@/api/activeCampaign.js";

export default {
  name: "UpperMenu",
  data() {
    return {
      user: "Test User",
      ActiveLocale: null,
      langIconRotation: 0,
      accountIconRotation: 0,
      userInfo: null,
      showSubMenu: false,
      languageMap: {
        // This map now uses the expected format directly
        'fi': 'fi',
        'en': 'en',
        'sv': 'sv',
        'ja': 'ja',
        'de': 'de',
        'pt': 'pt',
        'es': 'es',
        'english': 'en',
        'finnish': 'fi',
        'swedish': 'sv',
        'japan': 'ja'

      },
    };
  },
  methods: {
    LogOut() {
      KeyCloakService.CallLogOut();
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.ActiveLocale = locale.toUpperCase();
      this.$store.commit('setActiveLocale', locale);
      // console.log(locale)
    },
    toggleSubMenu(event) {
      event.stopPropagation();
      this.showSubMenu = !this.showSubMenu;
    },

    updateActiveLocale(newLocale) {
      try {
        let activeLocale = newLocale ? newLocale.toLowerCase() : this.getDefaultLocale();
        if (this.languageMap[activeLocale]) {
          if (this.ActiveLocale !== this.languageMap[activeLocale]) {
            this.ActiveLocale = this.languageMap[activeLocale];
            this.changeLocale(this.ActiveLocale);
          }
          // console.log("First action " + activeLocale + " " + this.ActiveLocale)
        } else {
          console.warn(`Received unknown active locale: ${activeLocale}`);
        }
      } catch (error) {
        console.error("Failed to update active locale:", error);
      }
    },

    async fetchAndSetActiveLocale() {
      try {
        // Attempt to get the locale from getFieldValue(66)
        const fieldValueLocale = (await getFieldValue(66))?.toLowerCase();
        // console.log(fieldValueLocale);
        // If fieldValueLocale exists and is in the language map, set it as the active locale
        if (fieldValueLocale && this.languageMap[fieldValueLocale]) {
          this.changeLocale(this.languageMap[fieldValueLocale]);
        } else {
          // If fieldValueLocale does not exist or is not in the language map,
          // fall back to the store's locale
          const storeLocale = this.$store.state.accessTokenInfo.locale.toLowerCase();
          this.changeLocale(this.languageMap[storeLocale] || 'en');
        }
      } catch (error) {
        console.error("Failed to fetch or set active locale:", error);
        // As a last resort, default to 'en' if an error occurs
        if (this.$store?.state?.accessTokenInfo?.locale) {
          this.changeLocale(this.$store.state.accessTokenInfo.locale);
        } else {
          this.changeLocale('en');
        }

      }
    },

    // Fetch the default locale if newLocale is not provided
    async getDefaultLocale() {
      try {
        const defaultLocale = (await getFieldValue(66)).toLowerCase();
        // console.log("Second action " + defaultLocale)
        return this.languageMap[defaultLocale] || this.$store.state.accessTokenInfo.locale; // Return 'en' if not found in the map

      } catch (error) {
        console.error("Failed to fetch default locale:", error);
        if (this.$store?.state?.accessTokenInfo?.locale) {
          return this.$store.state.accessTokenInfo.locale;
        } else {
          return 'en';
        }

      }
    },
  },
  created() {
    this.fetchAndSetActiveLocale();
    if (this.$store?.state?.accessTokenInfo?.locale) {
      this.ActiveLocale = this.$store.state.accessTokenInfo.locale
    } else {
      this.ActiveLocale = 'en';
    }
  },
  mounted() {
    this.$refs.langDropdown.addEventListener("show.bs.dropdown", () => {
      this.langIconRotation = 90;
    });
    this.$refs.langDropdown.addEventListener("hide.bs.dropdown", () => {
      this.langIconRotation = 0;
    });

    this.$refs.accountDropdown.addEventListener("show.bs.dropdown", () => {
      this.accountIconRotation = 90;
    });
    this.$refs.accountDropdown.addEventListener("hide.bs.dropdown", () => {
      this.accountIconRotation = 0;
    });
    this.ActiveLocale = this.$i18n.locale.toUpperCase();
  },
  computed: {
    userNameAndSurname() {
      if (this.$store.state.userData) {
        return (
          this.$store.state.userData.first_name +
          " " +
          this.$store.state.userData.last_name
        );
      } else {
        return "";
      }
    },
  },
  watch: {
    // Watch the store state for changes
    '$store.state.activeLocale': {
      handler(newLocale) {
        this.updateActiveLocale(newLocale);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.MainRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: var(--Black);
  height: 100%;
  color: var(--White);
  //transition: 0.2s ease-in-out;

  //basic styles for menu icons
  .menuicon {
    padding-left: 21px;
    font-size: 24px;

    button {
      background-color: black;
      padding: 0px;
      border: none;
    }
  }

  .account {
    padding-left: 1rem;
    padding-right: 1rem;

    img {
      width: 32px;
      height: auto;
      background-color: white;
    }
  }

  .icon-reorder:before {
    content: "\f0c9";
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: 25px;
      //background-color: white;
    }
  }

  .account {
    display: flex;
    flex-flow: row nowrap;
    transition: 0.2s ease-in-out;
    justify-self: end;
    align-items: center;

    img {
      border-radius: 30px;
    }

    .btn {
      color: var(--White);
      border: none;
    }

    .lang-selection {
      height: 100%;

      .dropdown-center {
        height: 100%;

        .btn {
          padding-left: 1px;
        }

        button {
          height: 100%;
          padding: 1px;
          margin-right: 75px;
        }
      }
    }

    .account-info {
      height: 100%;

      .dropdown-center {
        height: 100%;
        background-color: var(--Black);

        img {
          border-radius: 0px;
          filter: invert(100%);
        }
      }

      button {
        height: 100%;
      }

      .btn {
        padding-left: 0;

        img {
          margin-right: 17px;
        }
      }

      .logout-image {
        border-radius: 0px;
        background-color: var(--Black);
      }
    }

    .dropdown-menu {
      cursor: pointer;
      border-radius: 0px;
      min-width: 100px;
      background-color: var(--Black);
      padding: 0;

      .dropdown-item {
        text-align: left;
        padding: 0;
      }

      li {
        border: 1px solid var(--White) !important;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: var(--Black);

        &:hover {
          background-color: var(--Grey);
        }

        a {
          color: var(--White);
          display: block;
          width: 100%;
          background-color: inherit; // This will make 'a' take the same background color as its parent 'li'

          &:hover {
            background-color: inherit; // Make sure the background color stays the same even when 'a' is hovered
          }
        }
      }

      a {
        color: var(--White);
      }

      .disabled {
        //pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .dropdown-menu .dropdown-submenu {
    position: relative;

    .dropdown-item {
      a {
        background-color: var(--Black);
      }
    }
  }

  .dropdown-menu .dropdown-submenu>.dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -2px;
    margin-right: 0;
  }

  .dropdown-menu .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }

  .dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: left;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  .dropdown-submenu:hover>a:after {
    border-left-color: #fff;
  }

  .dropdown-submenu.pull-left {
    float: none;
  }

  .dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
  }
}

// TABLET
// TABLET
// TABLET
// TABLET

@media (min-width: 1025px) {
  .toggle-sidebar-button {
    display: none;
  }

  .tablet-only {
    display: none;
  }
}

@media (max-width: 1024px) {
  .lang-selection {
    display: none;
  }

  .account-info {
    p {
      display: none;
    }
  }
}

//// PHONE
//// PHONE
//// PHONE
//// PHONE

@media (min-width: 768px) {}

@media (max-width: 768px) {
  .dropdown-center {
    display: none;
  }
}
</style>
