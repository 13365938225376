<template>
  <div class="grid" :class="customClass">
    <!-- loop through products in grid view -->
    <div
      class="product_column"
      :class="{
        selected: isSelected(product),
        'add-room-block': product.isNewRoomButton,
      }"
      v-for="product in limitedProducts"
      :key="product.serial_number || 'add-new-room'"
      @click="
        product.isNewRoomButton ? addNewRoom() : showProductDetails(product)
      "
    >
      <slot v-if="!product.isNewRoomButton" :product="product"></slot>
      <template v-else>
        <div class="product-content display-none-on-mobile">
          <div class="img-block">
            <img src="@/assets/roomPic.svg" />
          </div>
          <div class="product-info-block">
            <button class="add-or-save-button">
              {{ $t("dataGridView_productInfoBlock_button") }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>

  <!-- <div class="grid" :class="customClass">
    <div
      class="product_column"
      :class="{ selected: isSelected(product) }"
      v-for="product in limitedProducts"
      :key="product.serial_number"
      @click="showProductDetails(product)"
    >
      <slot :product="product"></slot>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "DataGridView",
  props: {
    products: Array,
    onProductClick: Function,
    customClass: String,
    selectedProducts: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 10000,
    },
    showAddNewRoom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productsWithAddNew() {
      if (this.showAddNewRoom) {
        return [...this.products, { isNewRoomButton: true }];
      } else {
        return this.products;
      }
    },
    limitedProducts() {
      return (this.productsWithAddNew || []).slice(0, this.limit);
    },
  },
  methods: {
    showProductDetails(item) {
      this.$emit("product-click", item);
    },
    isSelected(product) {
      return this.selectedProducts.some(
        (p) => p.serial_number === product.serial_number
      );
    },
    addNewRoom() {
      this.$emit("add-new-room");
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: flex;
  flex-wrap: wrap;

  //justify-content: space-between;
}

.product_column {
  flex: 0 0 calc(25% - 30px);
  padding: 24px;
  background-color: var(--White);
  margin: 15px;
  border-radius: 10px;
  cursor: pointer;
}

.product-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .img-block {
    flex-shrink: 0; // This will prevent the image block from shrinking
  }

  .product-info-block {
    flex-grow: 1; // This will make the info block take up the remaining space
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.product_column.selected {
  outline: 6px solid var(--Green);
}

.product-info-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  .add-or-save-button {
    margin-top: 8%;
    margin-bottom: 8%;
    height: 50px;
    width: 141px;
    border: none;
    font-size: 16px;
  }
}

@media screen and (max-width: 1611px) {
  .product_column {
    flex: 0 0 calc(33% - 30px);
  }
}

@media screen and (max-width: 1304px) {
  .product_column {
    flex: 0 0 calc(50% - 30px);
  }
}

@media screen and (max-width: 655px) {
  .product_column {
    flex: 0 0 calc(100% - 30px);
  }
}

@media (max-width: 768px) {
  .product-content,
  .add-room-block {
    display: none;
    height: auto;
  }
}
</style>
