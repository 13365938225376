import store from "@/store";
import getUserProducts from "@/api/getUserProducts";
import { getLocations, getRooms } from "@/api/addingRooms";
import { updadeProduct } from "@/api/registerProducts.js";

async function clearRoomsAndLocations() {
    await getUserProducts();
    const products = store.state.userProducts;
    // console.log("products: ", products);
    await getLocations();
    const locations = store.state.userLocation;
    // console.log("locations: ", locations);

    // Create a new array to store all the rooms
    let allRooms = [];

    for (let location of locations) {
        const rooms = await getRooms(location.id);
        allRooms = [...allRooms, ...rooms];
    }

    // Commit all the rooms to the state
    store.commit("setRooms", allRooms);

    const rooms = store.state.userRooms;
    // console.log("rooms: ", rooms);

    const validRoomIds = rooms.map(room => room.id);

    for (let product of products) {
        let shouldUpdate = false;

        if (!product.room) {
            continue;
        }

        if (!validRoomIds.includes(product.room)) {
            product.room = null;
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            try {
                await updadeProduct(product);
                await getUserProducts();
            } catch (e) {
                console.log("problems with clearinng products", e);
            }
            
            // console.log("Clearing the product: ", product);
        }
    }
}

export default clearRoomsAndLocations;
