<template>
  <div class="main-block">
    <VSpinner v-if="$store.getters.isLoading"></VSpinner>
    <div class="service-page">
      <div class="headline">
        <h1>
          <b>{{ $t("services_headline_h2") }}</b>
        </h1>
        <p>{{ $t("services_headline_p_welcome") }}</p>
      </div>
      <div class="component-block">
        <TemplateForServices></TemplateForServices>
      </div>
    </div>
  </div>
</template>

<script>
/* global Paddle */
import TemplateForServices from "@/components/TemplateForServices.vue";
import VSpinner from "@/components/UI/VSpinner.vue";

export default {
  name: "ServicePage",
  components: {
    TemplateForServices,
    VSpinner,
  },
  data() {
    return {};
  },
  methods: {
    openPaddleCheckout() {
      Paddle.Checkout.open({
        product: "54806",
        locale: this.$i18n.locale,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background-color: var(--bg-grey);
  margin: 0px;
  padding: 0px;
  height: calc(100vh - var(--UpperMenuHeight)) !important;
  overflow-y: scroll;
}

.service-page {
  margin: 40px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "headline headline headline headline"
    "component-block  component-block  component-block  component-block"
    // ". . . old-reports";
  ;

  .headline {
    grid-area: headline;

    h1 {
      border-left: 7px solid var(--Green);
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin-top: 0px;
      padding-top: 0px;
    }

    // .green-button {
    //   margin-left: 33px;
    // }
  }
}

.component-block {
  grid-area: component-block;
  height: fit-content;
}

@media (max-width: 768px) {
  .grade-page {
    margin: 20px 30px 10px 30px;
  }
}
</style>
