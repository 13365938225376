<template>
  <div class="menuBox" :class="{ 'is-expanded': is_expanded, 'sidebar-visible': sidebarVisible }"
    @click="$store.dispatch('toggleSidebar')">
    <div v-if="!isAuthenticated" class="blur-overlay">
      <div class="blur-overlay-text">
        <p>{{ $t("mainmenu_blurOverlay_p") }}</p>
        <button class="login-button" @click="auth">
          {{ $t("mainmenu_blurOverlay_button") }}
        </button>
      </div>
    </div>

    <div class="menu">
      <router-link to="/" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_products.svg" /></span>
        <span class="text">{{ $t("mainmenu_products") }}</span>
      </router-link>
      <router-link to="/rooms" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_rooms.svg" /></span>
        <span class="text">{{ $t("mainmenu_rooms") }}</span>
      </router-link>

      <div class="submenu-styles" @click.stop>
        <button @click="toggleUnioServicesDropdown" class="submenu-styles-button">
          <span class="material-icons"><img src="@/assets/iconsFromFigma/black_unio.svg" /></span>
          <span class="text">{{ $t("mainmenu_UNIO") }}</span>
          <font-awesome-icon :icon="['fas', 'chevron-right']" :style="{
            transform: `rotate(${UnioIconRotation}deg)`,
            transition: 'transform 0.3s ease-in-out',
            marginLeft: '15px',
            color: 'var(--Black)',
          }" />
        </button>
        <div :class="{ visible: showUnioServicesDropdown }" class="submenu-styles-dropdown">
          <router-link to="/grade" class="button" @click="$store.dispatch('toggleSidebar')">
            <span class="material-icons"><img src="@/assets/iconsFromFigma/black_grade.svg" /></span>
            <span class="text">{{ $t("mainmenu_grade") }}</span>
          </router-link>
          <router-link to="/software" class="button" @click="$store.dispatch('toggleSidebar')">
            <span class="material-icons"><img src="@/assets/iconsFromFigma/black_software.svg" /></span>
            <span class="text">{{ $t("mainmenu_software") }}</span>
          </router-link>
          <!-- <router-link to="/services" class="button" @click="$store.dispatch('toggleSidebar')">
            <span class="material-icons"><img src="@/assets/iconsFromFigma/black_software.svg" /></span>
            <span class="text">{{ $t("mainmenu_services") }}</span>
          </router-link> -->

        </div>
      </div>
      <router-link to="/smart-ip" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_smartip.svg" /></span>
        <span class="text">{{ $t("mainmenu_smartIP") }}</span>
      </router-link>

      <!-- <router-link v-if="isBetaAccess" to="/beta-software" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_smartip.svg" /></span>
        <span class="text">{{ $t("mainmenu_betaSoftware") }}</span>
      </router-link>
      <router-link v-if="isWarrantyAccess" to="/warranty-check" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_smartip.svg" /></span>
        <span class="text">{{ $t("mainmenu_checkWarranty") }}</span>
      </router-link> -->
      <!-- <router-link to="/payments" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_support.svg" /></span>
        <span class="text">{{ $t("mainmenu_payments") }}</span>
      </router-link> -->
      <router-link to="/support" class="button">
        <span class="material-icons"><img src="@/assets/iconsFromFigma/black_support.svg" /></span>
        <span class="text">{{ $t("mainmenu_support") }}</span>
      </router-link>
      <div class="bottom-buttons">
        <router-link to="/profile" class="button">
          <span class="material-icons"><img src="@/assets/iconsFromFigma/black_profile.svg" /></span>
          <span class="text">{{ $t("mainmenu_profile") }}</span>
        </router-link>
        <router-link to="/payments" class="button">
          <span class="material-icons"><img src="@/assets/iconsFromFigma/black_profile.svg" /></span>
          <span class="text">{{ $t("mainmenu_payments") }}</span>
        </router-link>

        <div class="submenu-styles" @click.stop>
          <button @click="toggleDropdown" class="submenu-styles-button">
            <span class="material-icons">
              <img src="@/assets/iconsFromFigma/black_language.svg" />
            </span>
            <span class="text">{{ $t("mainmenu_language") }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" :style="{
              transform: `rotate(${LangIconRotation}deg)`,
              transition: 'transform 0.3s ease-in-out',
              marginLeft: '15px',
              color: 'var(--Black)',
            }" />
          </button>
          <div :class="{ visible: dropdownOpen }" class="submenu-styles-dropdown">
            <span class="dropdown-item">
              <a href="#" @click="changeLocale('en')">English</a>
            </span>
            <span class="dropdown-item">
              <a href="#" @click="changeLocale('fi')">Suomi</a>
            </span>
            <span class="dropdown-item">
              <a href="#" @click="changeLocale('sv')">Svenska</a>
            </span>
            <span class="dropdown-item">
              <a href="#" @click="changeLocale('ja')">日本語</a>
            </span>
          </div>
        </div>

        <button class="button btn w-100 mob-buttons" @click="LogOut()">
          <span class="material-icons">
            <img src="@/assets/iconsFromFigma/black_logout.svg" />
          </span>
          <span class="text">{{ $t("mainmenu_logout") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import KeyCloakService from "@/security/KeyCloakService.js";
import { initFullApp } from "@/main.js";
import store from "@/store";
export default {
  name: "SidebarManu",
  data() {
    return {
      showSubMenu: false,
      dropdownOpen: false,
      showUnioServicesDropdown: true,
      UnioIconRotation: 90,
      LangIconRotation: 0,
    };
  },
  computed: {
    ...mapState({
      sidebarVisible: (state) => state.sidebarVisible,
    }),
    is_expanded() {
      return true;
    },
    isAuthenticated() {
      return KeyCloakService.isAuthenticated();
    },
    isBetaAccess() {
      if (
        store.state?.accessTokenInfo?.realm_access?.roles.includes(
          "beta-test-user"
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isWarrantyAccess() {
      if (
        store.state?.accessTokenInfo?.realm_access?.roles.includes(
          "support-access-warranty-read"
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ToggleMenu() {
      this.is_expanded = !this.is_expanded;
    },
    LogOut() {
      KeyCloakService.CallLogOut();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        this.LangIconRotation = 90;
        this.showUnioServicesDropdown = false;
        this.UnioIconRotation = 0;
      } else {
        this.LangIconRotation = 0;

      }
    },

    toggleUnioServicesDropdown() {
      this.showUnioServicesDropdown = !this.showUnioServicesDropdown;
      if (this.showUnioServicesDropdown) {
        this.UnioIconRotation = 90;
        this.dropdownOpen = false;
        this.LangIconRotation = 0;
      } else {
        this.UnioIconRotation = 0;
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.ActiveLocale = locale.toUpperCase();
      this.toggleDropdown();
    },
    auth() {
      KeyCloakService.CallLogin(() => {
        console.log("User authenticated");
        initFullApp();
        store.commit("setLandingPage", false);
      }).catch((error) => {
        console.error("Error during Keycloak initialization:", error);
        // Handle the error, maybe show an error message to the user
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-overlay {
  position: absolute;
  height: calc(100vh - var(--UpperMenuHeight));
  left: 0;
  width: var(--sidebar-width); // Assuming you have this variable for the width of the sidebar
  height: 100%; // Adjust according to your menu's height
  background-color: rgba(192, 192, 192, 0.8); // semi-transparent white
  backdrop-filter: blur(3px); // blur effect
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  .blur-overlay-text {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: rgba(192, 192, 192, 0.8);
    margin-bottom: var(--UpperMenuHeight);
    border: 2px solid white;
    color: white;
    cursor: pointer;
    // any other styles you want for the button
  }
}

.menuBox {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  //height: calc(100vh - 50vh);
  padding: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: 0.2s ease-in-out;
  z-index: 10;

  h3,
  .button .text {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .menu {
    display: flex;
    flex-direction: column;
    margin: 0 -1rem;
    width: var(--sidebar-width);
    height: calc(100vh - var(--UpperMenuHeight));
    background-color: var(--White);
    overflow: auto !important;
    //padding-bottom: 200px;
    color: var(--Black);

    .button {
      display: flex;
      position: relative;
      align-items: center;
      text-decoration: none;
      transition: 0.2s ease-in-out;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid var(--Grey);
      height: 60px;

      .material-icons {
        font-size: 2rem;
        color: var(--Black);
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
      }

      img {
        width: 32px;
        height: auto;
      }

      .text {
        color: var(--Black);
        transition: 0.2s ease-in-out;
      }

      &:hover {
        background-color: var(--Pale-grey);

        .material-icons,
        .text {
          color: var(--Black);
        }
      }

      &.router-link-exact-active {
        background-color: var(--Pale-grey);
        border-right: 5px solid var(--Green);

        .material-icons,
        .text {
          color: var(--Black);
        }
      }

      //added a dot to the active element
      &.router-link-exact-active::after {
        //content: '•';
        font-size: 35px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        color: var(--Green);
      }
    }

    .submenu-styles {
      position: relative;

      .submenu-styles-button {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border: none;
        border-bottom: 1px solid var(--Grey);
        height: 60px;
        width: 100%;
        background-color: var(--White);
        cursor: pointer;

        .material-icons {
          margin-right: 1rem;

          img {
            width: 32px;
            height: auto;
          }
        }

        .text {
          color: var(--Black);
        }
      }

      .submenu-styles-dropdown {
        display: block;
        width: 100%;
        background-color: var(--White);
        border: none;
        z-index: 1;
        transform-origin: top;
        transform: scaleY(1);
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; // Added opacity to the transition
        overflow: hidden;
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        transition: max-height 0.3s ease-in-out,
          visibility 0.3s 0.3s ease-in-out,
          // Add delay equal to max-height transition duration
          opacity 0.3s 0.3s ease-in-out; // Add delay equal to max-height transition duration

        .dropdown-item {
          a {
            padding-top: 18px;
            padding-left: 64px; // 64px
            color: var(--Black);
          }
        }

        a {
          display: flex;
          padding: 0.5rem 1rem;
          text-decoration: none;
          color: var(--dark-alt);
          border-bottom: 1px solid var(--Grey);
          height: 60px;
          padding-left: 64px;

          &:hover {
            background-color: var(--Pale-grey);
          }
        }

        &.visible {
          visibility: visible;
          max-height: 300px;
          opacity: 1;
          transition-delay: 0s; // Reset delay when dropdown is visible
          //transform: scaleY(1);
        }
      }
    }
  }

  &.is-expanded {

    h3,
    .button .text {
      opacity: 1;
    }

    .button {
      .material-icons {
        margin-right: 1rem;
      }
    }
  }
}

.bottom-buttons {
  .dropdown {
    display: flex;
    position: relative; // Set position relative for the button and dropdown container
    align-items: center;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    border-bottom: 1px solid var(--Grey);
    height: 60px;

    .dropdown-toggle {
      padding: 0.5rem 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      text-align: center;
      border: none;

      p {
        margin: 0;
        text-align: center;
      }

      &::after {
        margin-left: 1rem;
      }
    }

    .material-icons {
      font-size: 2rem;
      color: var(--dark-alt);
      transition: 0.2s ease-in-out;
      display: flex;
      align-items: center;
      margin-right: 1rem;
    }

    img {
      width: 32px;
      height: auto;
    }

    .text {
      color: var(--dark-alt);
      transition: 0.2s ease-in-out;
    }

    &:hover {
      background-color: var(--Pale-grey);

      .material-icons,
      .text {
        color: var(--Black);
      }
    }
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 0;
    width: 100%;
    position: absolute; // Set position absolute for the dropdown menu
    top: 100%; // Position it right below the button
    left: 0; // Align it to the left of the button
    z-index: 10; // Ensure it overlays on top of other elements

    // ... other styles
    a {
      padding-top: 17px;
      border-bottom: 1px solid black;
      text-align: center;
      height: 60px;
    }
  }
}

.mob-buttons {
  border-radius: 0px;
}

// TABLET
// TABLET
// TABLET
// TABLET

@media (max-width: 1024px) {}

@media (min-width: 1024px) {}

// PHONE
// PHONE
// PHONE
// PHONE

@media (max-width: 768px) {}

@media (min-width: 768px) {
  .bottom-buttons {
    display: none;
  }
}
</style>
