import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "application/json",
  };
}

async function getProductInfo(serialnumber) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${serialnumber}`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error getting the data:", error);
      // store.commit(
      //   "setErrorMessage",
      //   error.config.url + " " + error.response.data
      // );
      // throw error;
    });
}

export { getProductInfo };
