<template>
  <div class="main-block" :class="viewClass" :style="{ paddingBottom: paddingSize }">
    <div class="headline">
      <h1>
        <b>{{ $t("mainmenu_products") }}</b>
      </h1>
      <p>{{ $t("products_headline_p_welcome") }}</p>
    </div>
    <div class="register-button">
      <button class="save-info-button" @click="$router.push('/product-registration')">
        {{ $t("products_link_register_products") }}
      </button>
    </div>
    <div class="toggle-buttons">
      <button v-on:click="gridView()" :class="{ 'selected-view': grid_view }">
        <!-- <i class="fa fa-th-large" aria-hidden="true"></i> -->
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group 83">
            <rect id="Rectangle 66" width="10" height="10" fill="currentColor" />
            <rect id="Rectangle 67" x="13" width="10" height="10" fill="currentColor" />
            <rect id="Rectangle 68" x="13" y="13" width="10" height="10" fill="currentColor" />
            <rect id="Rectangle 69" y="13" width="10" height="10" fill="currentColor" />
          </g>
        </svg>
      </button>
      <button v-on:click="listView()" :class="{ 'selected-view': list_view }">
        <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group 82">
            <rect id="Rectangle 70" width="21" height="3" fill="currentColor" />
            <rect id="Rectangle 71" y="7" width="23" height="3" fill="currentColor" />
            <rect id="Rectangle 72" y="13" width="20" height="3" fill="currentColor" />
            <rect id="Rectangle 73" y="20" width="22" height="3" fill="currentColor" />
          </g>
        </svg>
      </button>
    </div>
    <div class="search-bar">
      <input :placeholder="$t('input_search_for_a_model')" v-model="searchQuery" />
    </div>
    <!-- Selected products menu, deselect all -->
    <div :class="{ 'selected-products-visible': selectedProductsVisible }"
      class="selected-products display-none-on-mobile">
      <div class="selected-input">
        <CustomDropdown>
          <li @click="selectedAction = 'delete'">
            {{ $t("products_select_option_2") }}
          </li>
          <li @click="selectedAction = 'assign'">
            {{ $t("products_select_option_3") }}
          </li>
          <li @click="selectedAction = 'addReceipt'">
            {{ $t("products_select_option_4") }}
          </li>
        </CustomDropdown>
      </div>

      <div class="counter-and-button">
        <p class="m-0">
          {{ $t("products_select_selected_products") }}
          {{ selectedProducts.length }}
        </p>
        <button @click="deselectAll" class="deselect-btn text-nowrap">
          {{ $t("products_select_button_deselect_all") }}
        </button>
      </div>
    </div>

    <div class="products">
      <div v-if="true">
        <grid-view v-if="grid_view" :products="filteredProducts" :selectedProducts="selectedProducts"
          @product-click="productClick">
          <template #default="{ product }">
            <div @click.stop="productClick(product)" style="cursor: pointer">
              <div class="img-block">
                <img draggable="false" v-if="speakerPhotos[product.model]"
                  :src="speakerPhotos[product.model] + '?w=230&h=230&fit=pad'" />
                <img draggable="false" v-else src="@/assets/SpeakerImg.png" />
              </div>
              <div class="product-info-block">
                <div>
                  <h3>{{ product.model }}</h3>
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_name") }}&nbsp;</strong>{{ product.model }}
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_sn") }}&nbsp; </strong>{{ product.serial_number }}
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_warranty") }}&nbsp;</strong>{{
                    $t("products_grid_view_warranty_until")
                  }}
                  {{ convertDate(product.warranty_end_date)
                  }}<!-- {{ convertDate(product.warranty_end_date) }} !-->
                </div>
                <div>
                  <strong>{{ $t("products_grid_view_room") }}</strong>&nbsp; {{ product.roomName }}
                </div>

                <div v-if="product.productAttachments == 'No attachments'" @click.stop>
                  <button class="button-for-uploading-receipt-grid display-none-on-mobile" @click.stop="
                    openUploadModalForSingleProduct(product.serial_number)
                    " style="color: red">
                    <b>{{ $t("products_receipt_upload") }}</b>
                  </button>
                  <button class="button-for-uploading-receipt-grid display-none-on-desktop"
                    @click.stop="openFilePicker(product.serial_number, 'grid')" style="color: red">
                    <b>{{ $t("products_receipt_upload") }}</b>
                  </button>
                  <input type="file" :ref="`fileInputGrid-${product.serial_number}`" @change="handleFile"
                    accept="image/*, application/pdf" style="display: none" />
                </div>
                <div v-else @click.stop>
                  <button class="button-for-uploading-receipt-grid" @click.stop="
                    downloadReceipt(
                      product.serial_number,
                      product.productAttachments[0].filename
                    )
                    ">
                    <b>{{ $t("products_receipt_download") }}</b>
                  </button>
                </div>
              </div>
              <div class="edit-buttons" @click.stop="productClick(product)">
                <button @click.stop="openDeleteModal(product)">
                  <img src="@/assets/icons/delete-cropped.png" />
                </button>
                <button @click.stop>
                  <a :href="$t(product.model)" target="_blank">
                    <img src="@/assets/icons/information.png" />
                  </a>
                </button>
              </div>
            </div>
          </template>
        </grid-view>

        <list-view v-else :products="filteredProducts" :tableFields="fieldsToShow"
          :onProductClick="toggleProductSelection">
          <tr v-for="item in filteredProducts" :key="item" @click="toggleProductSelection(item)"
            :class="{ 'selected-td-content': isSelected(item) }">
            <td v-for="{ value } in table_fields" :key="value" class="display-none-on-mobile">
              <div class="td-content">
                <!-- Conditionally render select button -->

                <!-- <div v-if="value === 'select'" class="custom-checkbox">
                  <input
                    type="checkbox"
                    :id="`checkbox-${item.serial_number}`"
                    :value="item"
                    v-model="selectedProducts"
                    :class="{ 'selected-checkbox': isSelected(item) }"
                    disabled
                    hidden
                  />
                  <label :for="`checkbox-${item.serial_number}`"></label>
                </div> -->

                <!-- Conditionally render image before the model -->
                <div v-if="value === 'image'">
                  <img v-if="speakerPhotos[item.model]" :src="speakerPhotos[item.model]" alt="Speaker Image"
                    style="width: 50px" />
                  <img v-else alt="Speaker Image" style="width: 50px" src="@/assets/SpeakerImg.png" />
                </div>

                <!-- Conditionally render actions -->
                <div v-else-if="value === 'actions'" @click.stop="productClick(item)" class="d-flex">
                  <button class="rounded-btn" @click.stop="openDeleteModal(item)">
                    <img src="@/assets/icons/delete-cropped.png" style="width: 40px" />
                  </button>
                  <button class="manual rounded-btn" @click.stop>
                    <a :href="$t(item.model)" target="_blank">
                      <img src="@/assets/icons/information.png" style="width: 40px" />
                    </a>
                  </button>

                  <!-- Add more buttons as needed -->
                </div>

                <!-- Warranty -->
                <div v-else-if="value === 'warranty_end_date'">
                  {{ $t("products_grid_view_warranty_until") }}:
                  {{ convertDate(item[value]) }}
                </div>
                <!-- Receipt -->
                <div v-else-if="value === 'productAttachments'">
                  <button v-if="item.productAttachments === 'No attachments'"
                    class="button-for-uploading-receipt-grid display-none-on-mobile" @click.stop="
                      openUploadModalForSingleProduct(item.serial_number)
                      " style="color: red">
                    <b>{{ $t("products_receipt_upload") }}</b>
                  </button>
                  <button class="button-for-uploading-receipt-grid" v-else @click.stop="
                    downloadReceipt(
                      item.serial_number,
                      item.productAttachments[0].filename
                    )
                    ">
                    {{ $t("products_receipt_download") }}
                  </button>
                </div>

                <!-- Render other fields -->
                <div v-else>
                  {{ item[value] }}
                </div>
              </div>
            </td>

            <!-- Mobile version -->

            <td class="display-none-on-desktop text-center">
              <!-- <div v-for="{ value } in table_fields" :key="value"> -->
              <div class="td-content" v-for="{ value } in table_fields" :key="value">
                <!-- Conditionally render select button -->
                <div v-if="value === 'select'" class="custom-checkbox">
                  <input type="checkbox" :id="`checkbox-${item.serial_number}`" :value="item" v-model="selectedProducts"
                    :class="{ 'selected-checkbox': isSelected(item) }" disabled hidden />
                  <label :for="`checkbox-${item.serial_number}`"></label>
                </div>

                <!-- Conditionally render actions -->
                <div v-else-if="value === 'actions'" @click.stop="productClick(item)" class="btns-for-list-view">
                  <button @click.stop="openDeleteModal(item)">
                    <img src="@/assets/icons/delete-cropped.png" style="width: 50px" />
                  </button>
                  <button class="manual" @click.stop>
                    <a :href="$t(item.model)" target="_blank">
                      <img src="@/assets/icons/information.png" style="width: 50px" />
                    </a>
                  </button>

                  <!-- Warranty -->
                </div>
                <div v-else-if="value === 'warranty_end_date'">
                  {{ $t("products_grid_view_warranty_until") }}:
                  {{ convertDate(item[value]) }}
                </div>

                <!-- Receipt -->
                <div v-else-if="value === 'productAttachments'" @click.stop>
                  <button v-if="item.productAttachments === 'No attachments'"
                    class="button-for-uploading-receipt-grid display-none-on-desktop"
                    @click.stop="openFilePicker(item.serial_number, 'list')" style="color: red">
                    <b>{{ $t("products_receipt_upload") }}</b>
                    <input type="file" :ref="`fileInputList-${item.serial_number}`" @change="handleFile"
                      accept="image/*" style="display: none" />
                  </button>
                  <button class="button-for-uploading-receipt-grid" v-else @click.stop="
                    downloadReceipt(
                      item.serial_number,
                      item.productAttachments[0].filename
                    )
                    ">
                    {{ $t("products_receipt_download") }}
                  </button>
                </div>
                <!-- Render other fields -->
                <div v-else>
                  {{ item[value] }}
                </div>
              </div>
              <!-- </div> -->
            </td>
          </tr>
        </list-view>
      </div>

      <div v-else class="not-found-div">
        <h2 style="color: red">{{ $t("products_notFoundDiv_h2") }}</h2>
      </div>
    </div>
    <!-- Delete modal for 1 product-->
    <div class="deletemodal" v-if="showDeleteModal" @click="closeDeleteModal">
      <div class="content" @click.stop>
        <h1>{{ $t("products_deletemodal_h1") }}</h1>
        <p>
          {{ $t("products_deletemodal_p1") }} "{{
            productToDelete.model
          }}"?<br />{{ $t("products_deletemodal_p2") }}
        </p>
        <div class="buttons-div">
          <button type="button" class="save-info-button" @click="confirmDelete" :disabled="$store.state.errorStatus">
            {{ $t("button_delete") }}
          </button>
          <button type="button" class="cancel-info-button" @click="closeDeleteModal">
            {{ $t("button_cancel") }}
          </button>
        </div>
        <div class="modal-error-block" v-if="$store.state.errorStatus">
          <div class="error">
            <p>
              {{ $t("errorpage_text") }}
            </p>
            <div class="error-context">
              <p>{{ $store.state.errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete modal for selected products-->
    <div class="deletemodal" v-if="showDeleteModalForSelectedProducts" @click="closeDeleteModal">
      <div class="content" @click.stop>
        <h1>{{ $t("products_deletemodal_h1") }}</h1>
        <p>
          {{ $t("products_deletemodal_p1") }}
          {{ $t("products_deletemodal_selectedProducts") }}
        </p>
        <p class="mt-0">{{ $t("products_deletemodal_p2") }}</p>

        <div class="buttons-div">
          <button type="button" class="save-info-button" @click="deleteSelectedProducts"
            :disabled="$store.state.errorStatus">
            {{ $t("button_delete") }}
          </button>
          <button type="button" class="cancel-info-button" @click="closeDeleteModal">
            {{ $t("button_cancel") }}
          </button>
        </div>
        <div class="modal-error-block" v-if="$store.state.errorStatus">
          <div class="error">
            <p>
              {{ $t("errorpage_text") }}
            </p>
            <div class="error-context">
              <p>{{ $store.state.errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Upload Receipt modal-->
    <div class="uploadReceiptModal" v-if="showUploadReceiptModalForSelectedProducts" @click="closeDeleteModal">
      <div class="content" @click.stop>
        <div class="close-button-div">
          <img @click="closeDeleteModal" src="@/assets/iconsFromFigma/cross.svg" />
        </div>
        <div class="upload-text-div">
          <h3>{{ $t("products_modal_uploadReceipt_h3") }}</h3>
          <p>{{ $t("products_modal_uploadReceipt_p1") }}</p>
          <b v-if="this.selectedProducts.length > 0">{{
            $tc(
              "products_modal_uploadReceipt_p2_bold",
              this.selectedProducts.length,
              { amount: this.selectedProducts.length }
            )
          }}</b>
          <b v-else>{{
            $tc("products_modal_uploadReceipt_p2_bold", 1, { amount: 1 })
          }}</b>
        </div>
        <DropFile @addedReceipt="handleAddedReceipt"></DropFile>

        <div class="buttons-div">
          <button type="button" class="save-info-button" @click="uploadTheReceipt"
            :disabled="$store.state.errorStatus || !receiptFile">
            {{ $t("products_modal_uploadReceipt_button_confirm") }}
          </button>
          <button type="button" class="cancel-info-button" @click="closeDeleteModal">
            {{ $t("button_cancel") }}
          </button>
        </div>
        <div class="modal-error-block" v-if="$store.state.errorStatus">
          <div class="error">
            <p>
              {{ $t("errorpage_text") }}
            </p>
            <div class="error-context">
              <p>{{ $store.state.errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Assign to Location modal-->
    <div class="assign-modal" v-if="showAssignModalForSelectedProducts" @click="closeDeleteModal">
      <div class="content" @click.stop>
        <VForm @submit="assignToTheLocation">
          <h1>{{ $t("products_assignModal_h1") }}</h1>
          <p></p>
          <div class="assignToTheLocation">
            <div class="custom-select-wrapper">
              <VField name="Location" rules="required" v-model="selectedLocation" v-slot="{ field, errors }">
                <div class="select-and-error">
                  <div>
                    <select class="custom-select" id="location" v-model="selectedLocation" v-bind="field"
                      @change="getRooms(selectedLocation)">
                      <option :value="null" disabled>
                        {{ $t("products_assignModal_option_location") }}
                      </option>
                      <option v-for="location in locations" :value="location.id" :key="location.id">
                        {{ location.name }}
                      </option>
                    </select>
                    <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" style="top: 28" />
                  </div>

                  <span>{{ errors[0] }}</span>
                </div>
              </VField>
            </div>
            <div class="custom-select-wrapper">
              <VField name="Room" rules="required" v-model="selectedRoom" v-slot="{ field, errors }">
                <div class="select-and-error">
                  <select class="custom-select" id="room" v-model="selectedRoom" v-bind="field" required>
                    <option :value="null" disabled>
                      {{ $t("products_assignModal_option_room") }}
                    </option>
                    <option v-for="room in rooms" :value="room.id" :key="room.name">
                      {{ room.name }}
                    </option>
                  </select>
                  <font-awesome-icon class="custom-select-icon" :icon="['fas', 'chevron-down']" style="top: 28" />
                  <span>{{ errors[0] }}</span>
                </div>
              </VField>
            </div>
          </div>
          <div class="buttons-div">
            <button type="submit" class="save-info-button" :disabled="$store.state.errorStatus">
              {{ $t("products_assignModal_button_assign") }}
            </button>
            <button type="button" class="cancel-info-button" @click="closeDeleteModal">
              {{ $t("button_cancel") }}
            </button>
          </div>
          <div class="modal-error-block" v-if="$store.state.errorStatus">
            <div class="error">
              <p>
                {{ $t("errorpage_text") }}
              </p>
              <div class="error-context">
                <p>{{ $store.state.errorMessage }}</p>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
    <!-- Bottom float bar for mobile devices -->
    <div class="float-bottom-bar display-none-on-desktop" v-if="selectedProducts.length > 0">
      <div class="d-flex justify-content-between counter-and-button">
        <p class="m-0">
          {{ selectedProducts.length }}
          {{ $t("products_select_productsChosen") }}
        </p>
        <button @click="deselectAll" class="deselect-btn text-nowrap">
          <b> {{ $t("products_select_button_deselect_all") }}</b>
        </button>
      </div>
      <div class="d-flex justify-content-between action-buttons">
        <button class="assign-button" @click="openAssignModalForSelectedProducts()">
          <b>{{ $t("products_select_option_3") }}</b>
        </button>
        <button class="assign-button" @click="openUploadModalForSelectedProducts('mobile')">
          <b>{{ $t("products_select_option_4") }}</b>
        </button>
        <button class="delete-button" @click="openDeleteModalForSelectedProduct()">
          <b>{{ $t("products_select_button_delete") }}</b>
        </button>
        <input type="file" :ref="`fileInputGrid`" @change="handleFile" accept="application/pdf,image/*"
          style="display: none" />
      </div>
    </div>
  </div>
</template>

<script>
import GridView from "@/components/UI/DataGridView.vue";
import ListView from "@/components/UI/DataListView.vue";
import CustomDropdown from "@/components/UI/CustomDropdown.vue";
import { unregisterProduct } from "@/api/registerProducts";
import fetchSpeakerPhotos from "@/api/getProductPhoto";
import { mapGetters } from "vuex";
import { getRoomInfo } from "@/api/addingRooms";
import fetchLocalizationData from "@/locales/i18-data.js";
import clearRoomsAndLocations from "@/api/checkRoomsAndLocations";
import { getLocations, getRooms } from "@/api/addingRooms.js";
import { addProductToTheRoom } from "@/api/addingRooms.js";
import getUserProducts from "@/api/getUserProducts";
import DropFile from "@/components/UI/DropFile.vue";
import {
  addReceiptToTheProduct,
  getReceiptForTheProduct,
  downloadReceipt,
} from "@/api/addReceipt";

export default {
  name: "ProductList",
  props: {
    uploadedFile: File,
  },
  components: {
    GridView,
    ListView,
    CustomDropdown,
    DropFile,
  },
  data() {
    return {
      elements: document.getElementsByClassName("product_column"),
      speakerPhotos: {},
      productManuals: {},
      table_fields: [
        { label: "", value: "select" },
        { label: "", value: "image" },
        { label: this.$t("products_listView_table_heading_model"), value: "model" },
        { label: this.$t("products_listView_table_heading_serialNumber"), value: "serial_number" },
        { label: this.$t("products_listView_table_heading_warranty"), value: "warranty_end_date" },
        { label: this.$t("products_listView_table_heading_receipt"), value: "productAttachments" },
        { label: this.$t("products_listView_table_heading_actions"), value: "actions" },
      ],

      table_fields_phone: [{ label: "", value: "select" }],
      isDesktop: window.innerWidth > 767,

      searchQuery: "",
      grid_view: true,
      list_view: false,
      showModal: false,
      selectedProduct: null,
      selectedProducts: [],
      selectedAction: "",
      convertedDateFormat: "",
      showDeleteModal: false,
      productToDelete: null,
      showDeleteModalForSelectedProducts: false,
      showAssignModalForSelectedProducts: false,
      showUploadReceiptModalForSelectedProducts: false,
      products: [],
      roomNameCache: {},
      locations: [],
      rooms: [],
      selectedLocation: null,
      selectedRoom: null,
      room: null,
      productToAssign: null,
      receiptFile: null,
      productToUploadReceipt: null,
      selectedProductsVisible: null,
    };
  },
  methods: {
    downloadReceipt,
    listView() {
      this.grid_view = false;
      this.list_view = true;
    },
    gridView() {
      this.list_view = false;
      this.grid_view = true;
    },
    openDeleteModal(product) {
      this.$store.commit("setErrorStatus", false);
      this.showDeleteModal = true;
      this.productToDelete = product;
      this.deselectAll();
    },
    openUploadModalForSingleProduct(serial_number) {
      this.$store.commit("setErrorStatus", false);
      this.showUploadReceiptModalForSelectedProducts = true;
      this.productToUploadReceipt = serial_number;
      // this.deselectAll();
    },
    openDeleteModalForSelectedProduct() {
      this.$store.commit("setErrorStatus", false);
      this.showDeleteModalForSelectedProducts = true;
    },
    openAssignModalForSelectedProducts() {
      this.$store.commit("setErrorStatus", false);
      this.showAssignModalForSelectedProducts = true;
    },
    openUploadModalForSelectedProducts(device) {
      if (device == "desktop") {
        this.$store.commit("setErrorStatus", false);
        console.log(this.selectedProducts)
        for (const product of this.selectedProducts) {
          if (product.productAttachments !== "No attachments") {
            this.$store.commit(
              "setErrorMessage",
              this.$t("error_receipt_mobile")
            );
            this.$store.commit("setErrorStatus", true);
            console.log(product.productAttachments[0]);
          }
        }
        this.showUploadReceiptModalForSelectedProducts = true;
      } else {
        this.$store.commit("setErrorStatus", false);
        // console.log(this.selectedProducts);
        for (const product of this.selectedProducts) {
          if (product.productAttachments !== "No attachments") {
            this.$store.commit(
              "setErrorMessage",
              this.$t("error_receipt_mobile")
            );
            this.$store.commit("setErrorStatus", true);
            alert(this.$t("error_receipt_mobile"));
            return;
          }
        }
        const input = this.$refs[`fileInputGrid`];
        input.click();
        // if (this.grid_view) {
        //   if (input && input.click) {
        //     input.click();
        //   }
        // } else {
        //   // const input = this.$refs[`fileInputList`][0];
        //   if (input && input.click) {
        //     input.click();
        //   }
        // }
      }
    },
    closeDeleteModal() {
      this.$store.commit("setErrorStatus", false);
      this.showDeleteModal = false;
      this.showDeleteModalForSelectedProducts = false;
      this.showAssignModalForSelectedProducts = false;
      this.showUploadReceiptModalForSelectedProducts = false;
      this.selectedAction = "";
      this.productToUploadReceipt = null;
      this.receiptFile = null;
    },
    async confirmDelete() {
      try {
        await unregisterProduct(this.productToDelete.serial_number);
        this.$store.commit(
          "deleteUserProduct",
          this.productToDelete.serial_number
        );
        this.closeDeleteModal();
        await this.fetchProducts(); // Fetch products after product deletion
      } catch (error) {
        this.$store.commit("setErrorStatus", true);
      }
    },

    toggleProductSelection(product) {
      //console.log(product); // Inspect the product object
      const index = this.selectedProducts.findIndex(
        (p) => p.serial_number === product.serial_number
      );

      if (index > -1) {
        this.selectedProducts = this.selectedProducts.filter(
          (p) => p.serial_number !== product.serial_number
        );
      } else {
        this.selectedProducts.push(product);
      }
    },

    isSelected(product) {
      return this.selectedProducts.some(
        (p) => p.serial_number === product.serial_number
      );
    },

    productClick(product) {
      this.toggleProductSelection(product);
    },

    async deleteSelectedProducts() {
      try {
        for (const product of this.selectedProducts) {
          await unregisterProduct(product.serial_number);
          this.$store.commit("deleteUserProduct", product.serial_number);
        }
        this.closeDeleteModal();
        this.deselectAll();
        this.selectedAction = "";
        await this.fetchProducts(); // Fetch products after product deletions
      } catch (error) {
        this.$store.commit("setErrorStatus", true);
      }
    },

    async assignToTheLocation() {
      try {
        this.$store.dispatch(
          "updateTemporaryAssignProducts",
          this.selectedProducts
        );
        await addProductToTheRoom(this.selectedRoom);
        this.closeDeleteModal();
        this.deselectAll();
        this.selectedAction = "";
        this.selectedLocation = null;
        this.selectedRoom = null;
        await this.fetchProducts();
      } catch (error) {
        this.$store.commit("setErrorStatus", true);
      }
    },

    deselectAll() {
      this.selectedProducts = [];
    },
    convertDate(receivedDate) {
      if (!receivedDate) {
        // If receivedDate is null, undefined, or empty string, return a default value
        return "N/A";
      }
      const date = new Date(receivedDate);
      if (isNaN(date.getTime())) {
        // If receivedDate cannot be parsed into a Date object, return a default value
        return "N/A";
      }
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
      const year = date.getFullYear();
      if (this.$store.state.activeLocale === 'ja') {

        return `${year}-${month}-${day}`

      } else {

        return `${day}-${month}-${year}`
      }

    },
    async getRoomName(roomId) {
      // Check if the roomName is in the cache
      if (this.roomNameCache[roomId]) {
        return this.roomNameCache[roomId];
      }

      try {
        if (roomId === null) {
          return this.$t("products_methods_unassigned");
        } else {
          const roomInfo = await getRoomInfo(roomId);
          const roomName = roomInfo.name;
          // Save the roomName in the cache
          this.roomNameCache[roomId] = roomName;
          return roomName;
        }
      } catch (error) {
        console.log("Error getting room name: ", error);
      }
    },
    async fetchProducts() {
      await getUserProducts();
      const userProducts = this.getUserProducts;
      this.products = await Promise.all(
        userProducts.map(async (product) => {
          product.productAttachments = await getReceiptForTheProduct(
            product.serial_number
          );
          // console.log(product.productAttachments);
          if (!product.productAttachments[0]) {
            product.productAttachments = "No attachments";
          }
          if (product.room) {
            product.roomName = await this.getRoomName(product.room);
            product.roomExists = true;
          } else {
            product.roomName = false;
            product.roomExists = false;
          }
          return product;
        })
      );
      this.updateUnassignedRoomNames();
    },
    async fetchLocations() {
      try {
        this.locations = await getLocations();
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    },
    async getRooms(locationId) {
      try {
        this.rooms = await getRooms(locationId);
      } catch (error) {
        console.error("Failed to fetch rooms:", error);
      }
    },
    updateWindowSize() {
      this.isDesktop = window.innerWidth > 768;
    },
    handleAddedReceipt(uploadedFile) {
      this.receiptFile = uploadedFile;
    },

    async uploadTheReceipt() {
      if (!this.$store.state.errorStatus) {
        try {

          if (this.productToUploadReceipt) {
            await addReceiptToTheProduct(
              this.productToUploadReceipt,
              this.receiptFile
            );

          } else {
            for (const product of this.selectedProducts) {
              await addReceiptToTheProduct(
                product.serial_number,
                this.receiptFile
              );

            }
          }
          // Alert accumulated responses
          this.closeDeleteModal();
          this.deselectAll();
          this.selectedAction = "";
          await this.fetchProducts(); // Refresh products list
        } catch (error) {
          console.error("Failed to upload the receipt:", error);
          this.$store.commit("setErrorStatus", true);

        }
      } else {
        alert(this.$t("error_receipt_mobile"));
      }
    },

    openFilePicker(serialNumber, view) {
      this.productToUploadReceipt = serialNumber;
      let input;
      input = this.$refs[`fileInputGrid-${serialNumber}`];
      if (view === "grid") {

        // input = this.$refs[`fileInputGrid-${serialNumber}`];
        input = this.$refs[`fileInputGrid-${serialNumber}`];
        console.log(input);// Accessing the first element of the array
      } else {
        // console.log(serialNumber);
        input = this.$refs[`fileInputList-${serialNumber}`][0]; // Accessing the first element of the array
        // console.log(input);
      }
      if (input && input.click) {
        input.click();

      }
    },

    async handleFile(event) {
      const file = event.target.files[0];
      if (file && file.size <= 5242880) {
        // 5 MB in bytes
        // Handle the file upload process here
        this.receiptFile = file;
        await this.uploadTheReceipt();
      } else {
        alert(this.$t("productRegistration_methods_alert_fileTooLarge"));
      }
    },
    activeLocaleState() {
      return this.$store.state.activeLocale;
    },
    updateUnassignedRoomNames() {
      this.products = this.products.map((product) => {
        if (product.roomExists === false) {
          // Update room name for products without an assigned room
          product.roomName = this.$t("products_methods_unassigned");
        }
        return product;
      });
    },
  },
  mounted() {
    clearRoomsAndLocations();
    window.addEventListener("resize", this.updateWindowSize);
  },
  computed: {
    ...mapGetters(["getUserProducts"]),
    filteredProducts() {
      if (this.searchQuery) {
        return this.products.filter(
          (product) =>
            product.model
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            product.serial_number
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
        );
      }
      return this.products;
    },
    viewClass() {
      return this.grid_view ? "grid-view" : "list-view";
    },
    fieldsToShow() {
      return this.isDesktop ? this.table_fields : this.table_fields_phone;
    },
    paddingSize() {
      return this.selectedProducts.length > 0 ? "150px" : "0";
    },
  },

  async created() {
    this.speakerPhotos = await fetchSpeakerPhotos();
    this.productManuals = await fetchLocalizationData();
    await this.fetchProducts(); // Fetch products in created hook
    await this.fetchLocations();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  },
  watch: {
    selectedAction(newValue) {
      switch (newValue) {
        case "delete":
          this.openDeleteModalForSelectedProduct();
          this.selectedAction = ""; // reset the value after the action
          break;
        case "assign":
          this.openAssignModalForSelectedProducts();
          this.selectedAction = ""; // reset the value after the action
          break;
        case "addReceipt":
          this.openUploadModalForSelectedProducts("desktop");
          this.selectedAction = ""; // reset the value after the action
          break;
        default:
          break;
      }
    },
    "selectedProducts.length"(newLength) {
      this.selectedProductsVisible = newLength > 0;
    },
    activeLocaleState: {
      handler(newLocale) {
        if (newLocale) {
          this.table_fields = [
            { label: "", value: "select" },
            { label: "", value: "image" },
            { label: this.$t("products_listView_table_heading_model"), value: "model" },
            { label: this.$t("products_listView_table_heading_serialNumber"), value: "serial_number" },
            { label: this.$t("products_listView_table_heading_warranty"), value: "warranty_end_date" },
            { label: this.$t("products_listView_table_heading_receipt"), value: "productAttachments" },
            { label: this.$t("products_listView_table_heading_actions"), value: "actions" },
          ]
        }
      },
      immediate: true,
    },
    '$i18n.locale': {
      immediate: true, // run the handler immediately
      handler() {
        // Update room names if products are already fetched
        if (this.products && this.products.length > 0) {
          this.updateUnassignedRoomNames();
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
td,
tr {
  height: 50px;
}



.img-block {
  display: flex;
  justify-content: center;

  img {
    align-self: center;
    width: 230px;
    height: 230px;
    object-fit: cover;
  }
}

.main-block {
  transition: grid-template-rows 0.3s ease-in-out;
  margin: 40px;
  display: grid;
  height: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "headline headline headline register-button"
    "left-search-bar left-search-bar .  buttons-right"
    "selected-products selected-products selected-products selected-products"
    "center-products center-products center-products center-products";

  .headline {
    grid-area: headline;

    h1 {
      border-left: 7px solid var(--Green);
      margin: 15px;
      padding: 10px;
      padding-left: 18px;
      margin-bottom: 25px;
    }

    p {
      max-width: 800px;
      margin: 15px;
      //padding: 15px;
      //margin-left: 20px;
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .register-button {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    grid-area: register-button;

    .save-info-button {
      margin-bottom: 55px;
      margin-top: 65px;
      height: 50px;
      width: 277px;
      margin-right: 20px;
    }
  }

  .toggle-buttons {
    grid-area: buttons-right;
    justify-self: end;
    margin-right: 15px;
    display: flex;
    align-items: center;

    p {
      margin: 0;
    }

    button {
      border: none;
      font-size: 2.4rem;
      background-color: var(--bg-grey);
      color: var(--Grey);
      // i{
      //   color: var(--bg-grey);
      //   background-color: var(--bg-grey);
      // }
    }
  }

  .search-bar {
    grid-area: left-search-bar;
    margin-top: 30px;
    margin-left: 15px;

    input {
      border: none;
      border-radius: 30px;
      padding: 17px;
      width: 100%;
      margin-bottom: 15px;
    }

    p {
      margin-left: 10px;
    }

    a {
      color: var(--Green);
    }
  }

  .selected-products {
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
    grid-area: selected-products;
    height: auto;
    margin-bottom: 8px;
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-input {
      width: 30%;

      div {
        height: 54px !important;
      }
    }
  }

  .selected-products-visible {
    overflow: initial;
    opacity: 1;
    max-height: 200px;
  }

  .products {
    grid-area: center-products;

    .rounded-btn {
      //border-radius: 20px;
      padding: 0 0px 0 0;
      margin-right: 25px;
      border: 0px;
      background-color: var(--bg-grey);

      img {
        width: 34px;
        height: auto;
        background-color: var(--bg-grey);
      }
    }

    .edit-buttons {
      margin-top: 14px;
      // padding-bottom: 5px;

      .manual {
        width: 34px;
        height: auto;
      }
    }
  }

  .selected-view {
    color: var(--Green) !important;
  }

  .not-found-div {
    margin: 20px 0px 100px 10px;
  }
}

.assignToTheLocation {
  display: flex;
  flex-direction: column;

  .custom-select-wrapper {
    width: 100%;
  }
}

.counter-and-button {
  display: flex;
  justify-content: end;
}

@media (max-width: 1024px) {
  .main-block {
    grid-template-areas:
      "headline headline headline headline"
      "left-search-bar left-search-bar left-search-bar left-search-bar"
      ". register-button register-button ."
      "selected-products selected-products selected-products buttons-right"
      "center-products center-products center-products center-products";

    .register-button {
      .save-info-button {
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 16px;
      }
    }

    .selected-products {
      height: auto;

      p {
        padding-left: 40px;
      }

      .selected-products-inner {
        .custom-select-wrapper {
          width: 100%;
        }
      }
    }

    .deselect-btn {
      height: 54px;
      margin-left: 24px;
      margin-right: 0;
    }

    .counter-and-button {
      justify-content: end;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .main-block {
    grid-template-areas:
      "headline headline headline headline"
      "register-button register-button register-button register-button"
      "left-search-bar left-search-bar left-search-bar left-search-bar"
      "buttons-right buttons-right buttons-right buttons-right"
      "selected-products selected-products selected-products selected-products"
      "center-products center-products center-products center-products";

    margin: 20px 15px 10px 15px;

    .headline {
      margin-bottom: 37px;

      p {
        margin-bottom: 0px;
      }
    }

    .register-button {
      margin: 0px 15px;

      .save-info-button {
        height: 35px;
        width: 100%;
      }
    }

    .search-bar {
      margin: 15px;
      margin-top: 2px;
      margin-bottom: 2px;

      input {
        height: 43px;
        padding: 5px;
        text-align: center;
      }
    }

    .toggle-buttons {
      justify-self: center;
      margin: 0px;

      button {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    &.list-view {
      margin-right: 0px;
      margin-left: 0px;

      // Styles specific to list view
      td {
        padding: 3px !important;
      }
    }

    .selected-products {
      height: auto;
      margin: 15px 15px;

      p {
        padding-left: 40px;
      }

      .selected-products-inner {
        flex-direction: column;

        .custom-select-wrapper {
          width: 100%;
          margin-bottom: 15px;

          select {
            height: 43px;
          }
        }

        .counter-and-button {
          width: 100%;
          justify-content: space-between !important;

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }

    .deselect-btn {
      margin: 0;
      //padding: auto;
      height: 27px;
      align-self: flex-end;
    }
  }

  .display-none-on-desktop {
    .td-content {
      .btns-for-list-view {
        button {
          margin-bottom: 20px;
        }
      }
    }
  }

  td,
  tr {
    height: auto;
  }

  td.display-none-on-desktop.text-center {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-template-areas:
      "col1 col2_2 col3_2"
      "col1 col2_3 col3_2"
      "col1 col3_1 col3_3";
  }

  td.display-none-on-desktop.text-center>div:nth-child(1) {
    grid-area: col1;
  }

  td.display-none-on-desktop.text-center>div:nth-child(2) {
    grid-area: col2_1;
    display: none;
  }

  td.display-none-on-desktop.text-center>div:nth-child(3) {
    grid-area: col2_2;
  }

  td.display-none-on-desktop.text-center>div:nth-child(4) {
    grid-area: col2_3;
  }

  td.display-none-on-desktop.text-center>div:nth-child(5) {
    grid-area: col3_1;
    display: flex;
    align-items: center;
  }

  td.display-none-on-desktop.text-center>div:nth-child(6) {
    grid-area: col3_2;
  }

  td.display-none-on-desktop.text-center>div:nth-child(7) {
    grid-area: col3_3;
  }

  .btns-for-list-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
</style>
