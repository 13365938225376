<template>
  <div class="dropdown">
    <div class="dropdown-select" @click="toggleDropdown">
      {{ selectedOption || $t("products_select_option_1") }}
      <font-awesome-icon :icon="['fas', 'chevron-down']" />
    </div>
    <ul v-show="showDropdown" class="dropdown-options">
      <slot @click="handleOptionClick"></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CustomDropdown",
  props: {
    selectedOption: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.$el.classList.toggle("open", this.showDropdown);
    },
    handleOptionClick(event) {
      const selectedValue = event.target.getAttribute("data-value");
      if (selectedValue) {
        this.$emit("update:selectedOption", selectedValue);
        this.showDropdown = false;
        this.$el.classList.remove("open");
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.showDropdown) {
        this.showDropdown = false;
        this.$el.classList.remove("open");
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    selectedOption() {
      // Close dropdown when a new value is selected
      this.showDropdown = false;
      this.$el.classList.remove("open");
    }
  },

};
</script>


<style lang="scss">
.dropdown {
  position: relative;
  z-index: 10;
}

.dropdown-select {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--White);
  width: 100%;
  height: 100%;
  min-height: 40px;
  border: none;
  border-radius: 71px;
  padding-left: 35px;
  padding-right: 42px;
  align-items: center;
  color: var(--Grey);
  gap: 10px;
  border: 1px solid black;
}

.dropdown-options {
  display: none;
  position: absolute;
  top: 100%;
  /* Position dropdown right below the select */
  left: 0;
  z-index: 2000;
  /* Ensure dropdown is above other elements */
  width: 100%;
  /* Adjust as needed */
  background-color: var(--White);
  border-radius: 0 0 71px 71px;
  border: 1px solid black;
  padding-left: 0px;
  opacity: 0;
  transition: opacity 5s ease-in-out, visibility 5s ease-in-out;
}

.dropdown-options li {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 54px;
  padding-left: 32px;

  &:hover {
    transition: 0.2s ease-in-out;
    background-color: var(--dropDownSelectGrey);
  }
}

.dropdown-options li:last-child {
  border-radius: 0 0 10px 10px;
}

.dropdown.open .dropdown-options {
  display: block;
  opacity: 1;
  visibility: visible;
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.dropdown.open .dropdown-select {
  /* border-radius: 71px 71px 0 0; Top corners rounded, bottom corners squared */
  border-bottom: none;
  border-radius: 27px 27px 0 0;
}
</style>
