import axiosInstance from "@/api/tokenRefresher";
import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";

async function getUserProducts() {
  const private_organization_id = store.state.userData.private_organization_id;
  // console.log(private_organization_id);
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products?organization=${private_organization_id}`;
  return axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      store.dispatch("updateUserProducts", response.data.products); // Use response.data.products instead of response.data
    });
}

export default getUserProducts;
