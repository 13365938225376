<template>
    <div class="main-block">
        <div class="warranty-check-page">
            <!-- <div class="headline">
                <h1>
                    <b>Warranty information</b>
                </h1>
                <p>{{ $t("software_headline_p_welcome") }}</p>
            </div> -->
            <div class="warranty-box">
                <div class="serial_window_out">
                    <div class="serial_window_in">
                        <div>
                            <h3>{{ $t("warrantyCheckPage_headline_h") }}</h3>
                            <p>{{ $t("warrantyCheckPage_headline_p") }}</p>
                            <VForm @submit="checkSerialInfo()">
                                <div class="user-input-wrp">
                                    <div style="width: 100%;">
                                        <VField name="serialNumber" v-slot="{ field, errors }" v-model="serial_number"
                                            :rules="'required|noLeadingSpace|lengthBetween:1,25'">
                                            <div style="width: 100%;">
                                                <input type="text" class="inputText" v-model="serial_number"
                                                    v-bind="field" />

                                                <span class="error-span">{{ errors[0] }}</span>
                                            </div>
                                            <span class="floating-label">{{ $t("warrantyCheckPage_input_label")
                                                }}</span>

                                        </VField>
                                    </div>
                                    <div>
                                        <!-- <img src="@/assets/iconsFromFigma/black_support.svg" /> -->
                                        <button type="submit" class="menu_btn btn">
                                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                                        </button>
                                    </div>
                                </div>
                            </VForm>
                        </div>
                        <div v-if="serial_number_data && !errorDuringRequest" class="warranty-info">

                            <div style="white-space: nowrap;">
                                <h5>{{ $t("warrantyCheckPage_warrantyInfo_status") }} <b><span
                                            style="color: var(--Green);" v-if="this.warranty_status">{{
                                                $t("warrantyCheckPage_warrantyInfo_status_valid") }}</span><span
                                            style="color: red;" v-else>{{
                                                $t("warrantyCheckPage_warrantyInfo_status_expired") }}</span></b>
                                </h5>
                            </div>
                            <div class="warranty-info-data">
                                <p>{{ $t("warrantyCheckPage_warrantyInfo_data_endDate") }} {{
                                    convertDate(this.warrantyDate) }}</p>
                                <p>{{ $t("warrantyCheckPage_warrantyInfo_data_daysRemaining") }} <b>{{
                                        this.daysRemaining }}</b></p>
                            </div>


                        </div>
                        <div class="errorBlock" v-else-if="errorDuringRequest">
                            <p>{{ $t("warrantyCheckPage_errorBlock_p") }}</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import { getProductInfo } from "@/api/WarrantyCheck";

export default {
    name: "WarrantyCheckPage",
    data() {
        return {
            serial_number: null,
            serial_number_data: null,
            warranty_status: true,
            errorDuringRequest: false,
            daysRemaining: null,
            warrantyDate: null,
        };
    },
    methods: {
        async checkSerialInfo() {
            this.errorDuringRequest = false;
            try {
                this.serial_number_data = await getProductInfo(this.serial_number);
                if (this.serial_number_data) {
                    this.currentDate = new Date(); // Current date
                    this.warrantyDate = new Date(this.serial_number_data.warranty_end_date); // Parses the ISO string
                    // console.log(this.serial_number_data);
                    // console.log('Current Date:', this.currentDate);
                    // console.log('Warranty Date:', this.warrantyDate);

                    // Check if the current date is before the warranty end date
                    if (this.currentDate < this.warrantyDate) {
                        this.warranty_status = true;
                        const timeDifference = this.warrantyDate.getTime() - this.currentDate.getTime();
                        this.daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                    } else {
                        this.warranty_status = false;
                    }
                } else {
                    this.errorDuringRequest = true;
                    console.error('No data received for serial number:', this.serial_number);
                }
            } catch (error) {
                this.errorDuringRequest = true;
                console.error('Error during fetching product info:', error);
            }
        },

        convertDate(receivedDate) {
            if (!receivedDate) {
                // If receivedDate is null, undefined, or empty string, return a default value
                return "N/A";
            }
            const date = new Date(receivedDate);
            if (isNaN(date.getTime())) {
                // If receivedDate cannot be parsed into a Date object, return a default value
                return "N/A";
            }
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
            const year = date.getFullYear();
            if (this.$store.state.activeLocale === 'ja') {

                return `${year}-${month}-${day}`

            } else {

                return `${day}-${month}-${year}`
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.main-block {
    background-color: var(--bg-grey);
    margin: 0px;
    padding: 0px;
    height: calc(100vh - var(--UpperMenuHeight)) !important;
    overflow-y: scroll;
}

.warranty-check-page {
    // margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
    height: 100%;

    .warranty-box {
        height: 100%;
    }
}

.serial_window_out {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.serial_window_in {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: auto;
    border-radius: 20px;
    min-height: 50px;
    min-width: 540px;
    padding: 30px;
    background: #f8f9fa;
}

.user-input-wrp {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    color: grey;
    border: 1.5px solid;
    border-radius: 20px;
    padding: 15px;
}

.inputText {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    outline: none;
}

.user-input-wrp .inputText {
    border: none;
    background-color: #f8f9fa;
    // padding: 15px 80px;
    // padding-left: 0px;
}

.user-input-wrp .floating-label {
    position: absolute;
    pointer-events: none;
    top: 18px;
    left: 15px;
    transition: 0.2s ease all;
    font-size: 20px;
    opacity: 0.6;
}

.floating-label {
    position: absolute;
    pointer-events: none;
    top: 0px !important;
    left: 15px !important;
    font-size: 13px !important;
    font-weight: 200 !important;
    transition: 0.2s ease all;
    opacity: 0.6;
}

.menu_btn {
    background: none;
    color: dark;
    font-weight: bold;
    align-self: flex-end;
    height: 100%;
    padding: 0;

    svg {
        height: 20px !important;
    }
}

.errMessage {
    align-self: center;
    text-align: center;
    font-size: 20px;
    width: 100%;
}

.error-span {
    position: absolute;
    color: red;
    left: 15px;
    bottom: 2px;

    align-self: center;
}

.warranty-info {
    padding-top: 20px;
    padding-bottom: 0px;
}

.warranty-info-data {
    p {
        padding: 0;
        margin: 0;
        margin-top: 10px;
    }
}

.errorBlock {
    p {
        color: red;
        font-weight: bold;
        margin: 0;
        margin-top: 10px;
    }
}



@media (max-width: 768px) {
    .software-page {
        margin: 20px 30px 10px 30px;
    }
}
</style>