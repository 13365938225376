const fetchSpeakerPhotos = async () => {
    const contentfulUrl = 'https://cdn.contentful.com/spaces/4zjnzn055a4v/environments/master/entries/GP7n8AAK3h7HJYI993kOf?access_token=IohwVkm75OGdKdtyQewXdOkMrspUUrZ0OfDEpj_Xqas';
    const response = await fetch(contentfulUrl);
    const rawData = await response.json();
    const imageUrLsData = rawData.fields.imageUrLs;
    const imageUrLs = {};
  
    imageUrLsData.rows.forEach((row) => {
      imageUrLs[row.Key] = row.URL;
    });
  
    return imageUrLs;
  };
  export default fetchSpeakerPhotos;
  