<template>
  <div class="main d-flex flex-column justify-content-center align-items-center vh-100">
    <h1 class="text-center mb-4">{{ $t("notFoundPage_h1") }}</h1>
    <p>{{ $t("notFoundPage_p") }} <br> {{ $t("notFoundPage_p2") }} <button class="btn btn-link p-0"
        @click="$router.push('/')">{{ $t("notFoundPage_p3") }}</button>?</p>

  </div>
</template>
<script>
export default {
  name: "ErrorPage",
};
</script>

<style lang="scss" scoped>
.main {
  background-color: var(--bg-grey);
}
</style>
