import store from "@/store";
import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";

async function getFields() {
  const ownerOrgId = store.state.userData.id; // user id
  // console.log(ownerOrgId);
  const url = `${process.env.VUE_APP_API_ENDPOINT}/active-campaign/users/${ownerOrgId}/fields`;
  return axiosInstance
    .get(url, {
      headers: {
        Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response);
      // console.log(response.data.fieldValues);
      // if(response.data.)
      store.dispatch("updateActiveCampaignData", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting Active compaign fields:", error);
      //throw error;
    });
}

async function putFields(fieldsData) {
  const ownerOrgId = store.state.userData.id; // user id
  // console.log(ownerOrgId);
  const url = `${process.env.VUE_APP_API_ENDPOINT}/active-campaign/users/${ownerOrgId}/fields/update`;
  return (
    axiosInstance
      .put(
        url,
        { fields: fieldsData },
        {
          headers: {
            Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      // .then((response) => {
      //   console.log(response);
      //   console.log(response.data.fieldValues);
      //   return response.data;
      // })
      .catch((error) => {
        console.error("Error updating Active compaign fields:", error);
      })
  );
}

async function getFieldValue(fieldId) {
  const response = await getFields();
  for (const field of response.fieldValues) {
    if (field.field == fieldId) {
      return field.value;
    }
  }
}

async function sendTag(tagData, contactData) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/active-campaign/contactTags`;
  const email = store.state.userData.email;

  const payload = {
    contactTag: {
      contact: contactData,
      tag: tagData,
    },
  };

  // console.log(contactTag);

  return axiosInstance
    .post(url, payload, {
      headers: {
        Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
        "Content-Type": "application/json",
        "X-Email": email,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error getting Active compaign fields:", error);
      //throw error;
    });
}

export { getFields, putFields, getFieldValue, sendTag };
