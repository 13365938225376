import KeyCloakService from "@/security/KeyCloakService.js";
import axiosInstance from "@/api/tokenRefresher";

function getHeaders() {
  return {
    Authorization: `Bearer ${KeyCloakService.GetAccesToken()}`,
    "Content-Type": "multipart/form-data",
  };
}

async function addReceiptToTheProduct(serialNumber, file) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${serialNumber}/attachments`;

  // Create a new FormData object
  let formData = new FormData();
  formData.append("file", file);
  console.log("FormData content:", formData.get("file"));
  try {
    await axiosInstance.post(url, formData, {
      headers: getHeaders(),
    });
  } catch (error) {
    console.error(
      "Error sending receipt for serial number " + serialNumber,
      error
    );
  }
}

async function getReceiptForTheProduct(serialNumber) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${serialNumber}/attachments`;
  return axiosInstance
    .get(url, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response.data.attachments;
    })
    .catch((error) => {
      console.error("Error getting user locations:", error);
      throw error;
    });
}

async function downloadReceipt(serialNumber, filename) {
  const url = `${process.env.VUE_APP_API_ENDPOINT}/product-registry/products/${serialNumber}/attachments/${filename}`;

  try {
    const response = await axiosInstance.get(url, {
      headers: getHeaders(),
      responseType: "blob", // Important for files like PDF, images, etc.
    });

    // Create a URL for the blob
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", filename); // Set the file name for download
    document.body.appendChild(fileLink);

    fileLink.click(); // Trigger the download

    // Clean up
    fileLink.parentNode.removeChild(fileLink);
    window.URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error("Error downloading the receipt:", error);
    throw error;
  }
}

export { addReceiptToTheProduct, getReceiptForTheProduct, downloadReceipt };
